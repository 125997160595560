import { Component } from "react";
import {
    checkNodeIsActive,
    nodeLastUpdateDuration,
    utcToMtyConvertionDate,
    utcToMtyConvertionDisplay,
} from "../../shared/DateTimeConvertion";
import { refetchApi } from '../../shared/utils';
import axiosInstance from "../../shared/api";

export class retrieveNode extends Component {
    static myInstance = null;

    static getInstance() {
        return new retrieveNode();
    }

    async getNodeV2() {
        const resp = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/dashboard/v2.2/load`, {
            headers: {
                'Authorization': localStorage.getItem('cloudatik-access')
            }
        })
        let categories = []
        let individuals = []
        if (resp.data && resp.status === 200) {
            if (resp.data.categories.length > 0) {
                categories = resp.data.categories
            }
            if (resp.data.individual.length > 0) {
                individuals = resp.data.individual
            }
        }

        return { categories, individuals }
    }

    async getNodeCategory() {
        try {
            let response = await fetch(`${process.env.REACT_APP_API_URL}/dashboard/v2.1/category/node/`,{
                headers: {
                    'Authorization': localStorage.getItem('cloudatik-access')
                }
            })
            let responseJson = await response.json();
            if (responseJson.code && responseJson.code === 'token_not_valid') {
                refetchApi();
            }
            console.log("responseJson", responseJson)

            let data_to_return = {};

            let nodeCategory = {};
            let nodeUncategory = [];
            let categoryList = [];
            let node_list = [];
            let cat_node_list = [];
            let un_cat_node_list = [];

            for (let index = 0; index < responseJson["group"].length; index++) {
                let currentCategory = responseJson["group"][index]["category"];
                let currentSerialId = `${responseJson["group"][index]["serial"]
                    }-${responseJson["group"][index]["sensor_set"] == null
                        ? "1"
                        : responseJson["group"][index]["sensor_set"]
                    }`;
                if (!categoryList.includes(currentCategory)) {
                    categoryList.push(currentCategory);
                }
                if (categoryList.includes(currentCategory)) {
                    cat_node_list.push(currentSerialId);
                }
            }

            // Retrieve Category
            let res = await fetch(
                `${process.env.REACT_APP_API_URL
                }/getUserCategory/${localStorage.getItem("cloudatik")}/`,
                {
                    headers: {
                        'Authorization': localStorage.getItem('cloudatik-access')
                    }
                }
            );
            let respon = await res.json();

            if (respon.code && respon.code === 'token_not_valid') {
                refetchApi();
            }

            for (let k = 0; k < respon.length; k++) {
                if (!categoryList.includes(respon[k]["category"])) {
                    categoryList.push(respon[k]["category"]);
                }
            }

            for (let index = 0; index < responseJson["node"].length; index++) {
                let currentSerialId = `${responseJson["node"][index]["serial"]}-${responseJson["node"][index]["sensor_set"] == null ? "1" : responseJson["node"][index]["sensor_set"]}`;
                if (!cat_node_list.includes(currentSerialId)) {
                    un_cat_node_list.push(currentSerialId);
                }
                node_list.push(currentSerialId);
            }

            for (let index = 0; index < categoryList.length; index++) {
                nodeCategory[categoryList[index]] = [];
            }
            for (let index = 0; index < responseJson["group"].length; index++) {
                for (let x = 0; x < categoryList.length; x++) {
                    let json = {
                        sensor_set: responseJson["group"][index]["sensor_set"] == null ? "1" : responseJson["group"][index]["sensor_set"],
                        serial: responseJson["group"][index]["serial"],
                    };
                    for (let m = 0; m < responseJson["node"].length; m++) {
                        if (responseJson["node"][m]["serial"] == json["serial"] && responseJson["node"][m]["sensor_set"] == json["sensor_set"]) {
                            let isActive, days, hours, minutes;
                            [isActive, days, hours, minutes] =
                                nodeLastUpdateDuration(
                                    responseJson["node"][m]["last_update"]
                                );
                            json["tag_name"] =
                                responseJson["node"][m]["tag_name"];
                            json[
                                "serial_display"
                            ] = `${responseJson["node"][m]["serial"]} [${responseJson["node"][m]["sensor_set"]}]`;
                            json["remote"] = responseJson["node"][m]["remote"];
                            json["device_id"] =
                                responseJson["node"][m]["device_id"];
                            json["remote_id"] =
                                responseJson["node"][m]["remote_id"];
                            json["telemetry"] =
                                responseJson["node"][m]["telemetry"];
                            json["last_update"] = utcToMtyConvertionDisplay(
                                responseJson["node"][m]["last_update"]
                            );
                            json["isActive"] = isActive;
                            json["device"] = responseJson["node"][m]["device"]
                            json["date_installation"] = responseJson["node"][m]["date_installation"]
                        }
                    }
                    if (responseJson["group"][index]["category"] === categoryList[x]) {
                        nodeCategory[categoryList[x]].push(json);
                    }
                }
            }

            for (let index = 0; index < node_list.length; index++) {
                if (!cat_node_list.includes(node_list[index])) {
                    let json = {
                        sensor_set:
                            responseJson["node"][index]["sensor_set"] == null
                                ? "1"
                                : responseJson["node"][index]["sensor_set"],
                        serial: responseJson["node"][index]["serial"],
                    };
                    json["tag_name"] = responseJson["node"][index]["tag_name"];
                    json[
                        "serial_display"
                    ] = `${responseJson["node"][index]["serial"]} [${responseJson["node"][index]["sensor_set"]}]`;
                    json["remote"] = responseJson["node"][index]["remote"];
                    json["device_id"] =
                        responseJson["node"][index]["device_id"];
                    json["remote_id"] =
                        responseJson["node"][index]["remote_id"];
                    json["telemetry"] =
                        responseJson["node"][index]["telemetry"];
                    json["last_update"] = utcToMtyConvertionDisplay(
                        responseJson["node"][index]["last_update"]
                    );
                    json["isActive"] = checkNodeIsActive(
                        responseJson["node"][index]["last_update"]
                    );
                    json["device"] = responseJson["node"][index]["device"];
                    json["date_installation"] = responseJson["node"][index]["date_installation"]
                    nodeUncategory.push(json);
                }
            }

            // Arrange Node in alphabetical order.
            // sort in temporary array first.
            let sorted_array = {}
            for (let key of Object.keys(nodeCategory)) {
                let serial_array = []
                nodeCategory[key].map(n => {
                    serial_array.push(n.tag_name) 
                })
                sorted_array[key] = serial_array.sort()
            }
            // rearrangearray object node with sorted temporary array
            for (let key of Object.keys(nodeCategory)) {
                let temp_arr = []
                nodeCategory[key].map(n => {
                    let index = sorted_array[key].indexOf(n.tag_name);
                    temp_arr[index] = n
                })
                nodeCategory[key] = temp_arr
            }


            data_to_return["category_list"] = categoryList;
            data_to_return["category_with_node"] = nodeCategory;
            data_to_return["uncategory_node"] = nodeUncategory;
            return data_to_return;
        } catch (error) {
            console.error(error);
        }
    }

    async getNodeData(device_id) {
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL}/getDashboardAllChartIntervalV2/${device_id}/`,
                {
                    headers: {
                        'Authorization': localStorage.getItem('cloudatik-access')
                    }
                }
            );
            let responseJson = await response.json();

            if (responseJson.code && responseJson.code === 'token_not_valid') {
                refetchApi();
            }

            if (responseJson["data"].length == 0) {
                let result = {};
                result["isActive"] = false;
                return result;
            }

            let data_to_return = {};
            let line_chart = [];
            let bar_chart = [];
            let multiLineChart = [];
            let lcdStatus = [];
            for (
                let index = 0;
                index < responseJson["chart_prop_single"].length;
                index++
            ) {
                // Line Chart
                if (
                    responseJson["chart_prop_single"][index][
                    "chart_category"
                    ] == "line"
                ) {
                    let temp = {};
                    temp["chart_title"] =
                        responseJson["chart_prop_single"][index]["chart_title"];
                    temp["control_max"] =
                        responseJson["chart_prop_single"][index]["control_max"];
                    temp["control_min"] =
                        responseJson["chart_prop_single"][index]["control_min"];
                    temp["label"] =
                        responseJson["chart_prop_single"][index]["label"];
                    temp["limit_high"] =
                        responseJson["chart_prop_single"][index]["limit_high"];
                    temp["limit_low"] =
                        responseJson["chart_prop_single"][index]["limit_low"];
                    temp["parameter"] =
                        responseJson["chart_prop_single"][index]["parameter"];
                    temp["plot_control"] =
                        responseJson["chart_prop_single"][index][
                        "plot_control"
                        ];
                    temp["plot_limit"] =
                        responseJson["chart_prop_single"][index]["plot_limit"];
                    temp["unit"] =
                        responseJson["chart_prop_single"][index]["unit"];
                    temp["device_id"] = device_id;
                    temp["limit_low_parameter"] = responseJson["chart_prop_single"][index]["limit_low_parameter"];
                    temp["limit_high_parameter"] = responseJson["chart_prop_single"][index]["limit_high_parameter"];
                    temp["limit_low_head"] = responseJson["chart_prop_single"][index]["limit_low_parameter"];
                    temp["limit_high_head"] = responseJson["chart_prop_single"][index]["limit_high_parameter"];
                    temp["sensor_set"] = responseJson["chart_prop_single"][index]["sensor_set"];

                    let tempData = [];
                    for (let x = 0; x < responseJson["data"].length; x++) {
                        if (responseJson["data"][x]["sensor_set"] == temp["sensor_set"]) {
                            let js = {
                                datetime: utcToMtyConvertionDate(
                                    responseJson["data"][x]["datetime"]
                                ),
                                value: parseFloat(responseJson["data"][x][temp["parameter"]]).toFixed(2),
                                limit_low: responseJson["chart_prop_single"][index]["limit_low"],
                                limit_high: responseJson["chart_prop_single"][index]["limit_high"],
                                limit_low_parameter: responseJson["data"][x][responseJson["chart_prop_single"][index]["limit_low_parameter"]],
                                limit_high_parameter: responseJson["data"][x][responseJson["chart_prop_single"][index]["limit_high_parameter"]],
                            };
                            tempData.push(js);
                        }
                    }
                    temp["data"] = tempData.reverse();
                    line_chart.push(temp);
                }

                // Bar Chart
                if (
                    responseJson["chart_prop_single"][index][
                    "chart_category"
                    ] == "bar"
                ) {
                    let temp = {};
                    temp["chart_title"] =
                        responseJson["chart_prop_single"][index]["chart_title"];
                    temp["label"] =
                        responseJson["chart_prop_single"][index]["label"];
                    temp["parameter"] =
                        responseJson["chart_prop_single"][index]["parameter"];
                    temp["unit"] =
                        responseJson["chart_prop_single"][index]["unit"];
                    temp["device_id"] = device_id;

                    let tempData = [];
                    for (let x = 0; x < responseJson["data"].length; x++) {
                        let js = {
                            datetime: utcToMtyConvertionDate(
                                responseJson["data"][x]["datetime"]
                            ),
                            value: responseJson["data"][x][temp["parameter"]],
                        };
                        tempData.push(js);
                    }
                    temp["data"] = tempData.reverse();
                    bar_chart.push(temp);
                }

                if (
                    responseJson["chart_prop_single"][index][
                    "chart_category"
                    ] == "stext"
                ) {
                    let temp = {};
                    temp["lcd"] = {};
                    temp["chart_title"] =
                        responseJson["chart_prop_single"][index]["chart_title"];
                    temp["control_max"] =
                        responseJson["chart_prop_single"][index]["control_max"];
                    temp["control_min"] =
                        responseJson["chart_prop_single"][index]["control_min"];
                    temp["limit_high"] =
                        responseJson["chart_prop_single"][index]["limit_high"];
                    temp["limit_low"] =
                        responseJson["chart_prop_single"][index]["limit_low"];
                    temp["parameter"] =
                        responseJson["chart_prop_single"][index]["parameter"];
                    temp["plot_control"] =
                        responseJson["chart_prop_single"][index][
                        "plot_control"
                        ];
                    temp["plot_limit"] =
                        responseJson["chart_prop_single"][index]["plot_limit"];
                    temp["unit"] =
                        responseJson["chart_prop_single"][index]["unit"];
                    temp["device_id"] = device_id;

                    // let selected_parameter = responseJson["chart_prop_single"][index]["parameter"]
                    // temp["lcd"]["label"] = responseJson["chart_prop_single"][index]["label"];
                    // temp["lcd"]["message"] = responseJson["data"][0][selected_parameter];
                    // if()
                }
            }

            // Combined Chart
            for (
                let index = 0;
                index < responseJson["chart_prop_combined"].length;
                index++
            ) {
                let multi_line_chart = {};
                multi_line_chart["data"] = [];
                let sensor_list = [];
                for (let x = 1; x <= 5; x++) {
                    let element =
                        responseJson["chart_prop_combined"][index][
                        `chart0${x}`
                        ];
                    if (element !== null) {
                        sensor_list.push(element);
                    }
                }

                let parameter =
                    responseJson["chart_prop_combined"][index]["parameter"];
                let limit_low_parameter =
                    responseJson["chart_prop_combined"][index]["limit_low_parameter"];
                let limit_high_parameter =
                    responseJson["chart_prop_combined"][index]["limit_high_parameter"];

                if (
                    responseJson["chart_prop_combined"][index][
                    "chart_category"
                    ] == "line"
                ) {
                    multi_line_chart["chart_title"] =
                        responseJson["chart_prop_combined"][index][
                        "chart_title"
                        ];
                    multi_line_chart["label"] =
                        responseJson["chart_prop_combined"][index]["label"];
                    multi_line_chart["limit_high"] =
                        responseJson["chart_prop_combined"][index][
                        "limit_high"
                        ];
                    multi_line_chart["limit_high_parameter"] =
                        responseJson["chart_prop_combined"][index][
                        "limit_high_parameter"
                        ];
                    multi_line_chart["limit_low"] =
                        responseJson["chart_prop_combined"][index]["limit_low"];
                    multi_line_chart["limit_low_parameter"] =
                        responseJson["chart_prop_combined"][index][
                        "limit_low_parameter"
                        ];
                    multi_line_chart["plot_limit"] =
                        responseJson["chart_prop_combined"][index][
                        "plot_limit"
                        ];
                    multi_line_chart["serial"] =
                        responseJson["chart_prop_combined"][index]["serial"];
                    multi_line_chart["unit"] =
                        responseJson["chart_prop_combined"][index]["unit"];
                    multi_line_chart["sensor_list"] = sensor_list;
                    multi_line_chart["parameter"] = parameter;
                    multi_line_chart["limit_low_parameter"] = limit_low_parameter;
                    multi_line_chart["limit_high_parameter"] = limit_high_parameter;
                    multi_line_chart["limit_low_head"] = responseJson["chart_prop_combined"][index]["limit_low_parameter"];
                    multi_line_chart["limit_high_head"] = responseJson["chart_prop_combined"][index]["limit_high_parameter"];
                    multi_line_chart["legend_title"] = responseJson["legend_title_combined"]

                    let datetimeList = [];
                    let multiChartTempData = [];
                    let tempDatetime = [];
                    for (let m = 0; m < responseJson["data"].length; m++) {
                        let selected_datetime = utcToMtyConvertionDate(responseJson["data"][m]["datetime"]);
                        if (!tempDatetime.includes(selected_datetime.valueOf())) {
                            tempDatetime.push(selected_datetime.valueOf());
                            datetimeList.push(selected_datetime);
                        }
                    }

                    for (let i = 0; i < datetimeList.length; i++) {
                        let js = {};
                        js["datetime"] = datetimeList[i];
                        js["data"] = [];
                        for (let m = 0; m < responseJson["data"].length; m++) {
                            let serverDatetime = utcToMtyConvertionDate(responseJson["data"][m]["datetime"]);
                            if (serverDatetime.valueOf() == datetimeList[i].valueOf()) {
                                js["limit_low"] = responseJson["chart_prop_combined"][index]["limit_low"];
                                js["limit_high"] = responseJson["chart_prop_combined"][index]["limit_high"];

                                let j = {};
                                let k = {};
                                let l = {};
                                j[`${parameter}-${responseJson["data"][m]["sensor_set"]}`] = responseJson["data"][m][parameter];
                                k[`limit_low_parameter-${responseJson["data"][m]["sensor_set"]}`] = responseJson["data"][m][limit_low_parameter];
                                l[`limit_high_parameter-${responseJson["data"][m]["sensor_set"]}`] = responseJson["data"][m][limit_high_parameter];
                                js["data"].push(j);
                                js["data"].push(k);
                                js["data"].push(l);
                            }
                        }
                        multiChartTempData.push(js);
                    }

                    for (let i = 0; i < multiChartTempData.length; i++) {
                        let js = {};
                        js["datetime"] = multiChartTempData[i]["datetime"];
                        js["limit_low"] = multiChartTempData[i]["limit_low"];
                        js["limit_high"] = multiChartTempData[i]["limit_high"];

                        for (let x = 0; x < multiChartTempData[i]["data"].length; x++) {
                            let keys = Object.keys(multiChartTempData[i]["data"][x]);
                            for (let y = 0; y < keys.length; y++) {
                                js[keys[y]] = multiChartTempData[i]["data"][x][keys[y]];
                            }
                        }
                        multi_line_chart["data"].push(js);
                    }
                }
                multiLineChart.push(multi_line_chart)
            }

            // LCD
            for (let i = 0; i < responseJson["chart_prop_lcd"].length; i++) {
                let js = {
                    "label": responseJson["chart_prop_lcd"][i]["label"],
                    "value": responseJson["data"][0][responseJson["chart_prop_lcd"][i]["parameter"]],
                    "parameter": responseJson["chart_prop_lcd"][i]["parameter"]
                };
                if (responseJson["chart_prop_lcd"][i]["chart_category"] == "msg") {
                    for (let x = 0; x < responseJson["lcd_msg"].length; x++) {
                        if (js["value"] == parseInt(responseJson["lcd_msg"][x]["id"])) {
                            js["value"] = responseJson["lcd_msg"][x]["message"];
                        }
                    }
                }
                if (responseJson["chart_prop_lcd"][i]["chart_category"] == "onoff") {
                    if (parseInt(js["value"]) == 0) {
                        js["value"] = "OFF";
                    }
                    else {
                        js["value"] = "ON";
                    }
                }
                if (responseJson["chart_prop_lcd"][i]["chart_category"] == "yesno") {
                    if (parseInt(js["value"]) == 0) {
                        js["value"] = "NO";
                    }
                    else {
                        js["value"] = "YES";
                    }
                }
                if (responseJson["chart_prop_lcd"][i]["chart_category"] == "raindry") {
                    if (parseInt(js["value"]) == 0) {
                        js["value"] = "DRY";
                    }
                    else {
                        js["value"] = "RAIN";
                    }
                }

                lcdStatus.push(js);
            }

            data_to_return["last_update"] = utcToMtyConvertionDisplay(
                responseJson["last_update"]
            );
            data_to_return["isActive"] = checkNodeIsActive(
                responseJson["last_update"]
            );
            data_to_return["serial"] =
                responseJson["chart_prop_single"][0]["serial"];
            data_to_return["tag_name"] = responseJson["tag_name"];
            data_to_return["sensor_set"] =
                responseJson["chart_prop_single"][0]["sensor_set"];
            data_to_return["line_chart"] = line_chart;
            data_to_return["bar_chart"] = bar_chart;
            data_to_return["multi_line_chart"] = multiLineChart;
            data_to_return["lcd_status"] = lcdStatus;
            data_to_return["datetime"] = utcToMtyConvertionDate(
                responseJson["data"][responseJson["data"].length - 1]["datetime"]
            );

            return data_to_return;
        } catch (error) {
            console.error(error);
        }
    }

    async getNodeLatestData(device_id) {
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL}/getDashboardDataAll/${device_id}/`,
                {
                    headers: {
                        'Authorization': localStorage.getItem('cloudatik-access')
                    }
                }
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            console.error(error);
        }
    }
}

export default retrieveNode;
