import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import nodeProfile from "../../../services/node/node-profile";
import { errorToast, successToast } from "../../../shared/notification";
import LoadingSpinner from "../../../shared/Spinner/LoadingSpinner";

function EditProfile({ device_id, setOpenProfileNode }) {
	const [serial, setSerial] = useState("")
	const [tagName, setTagName] = useState("")
	const [locationName, setLocationName] = useState("")
	const [base64, setBase64] = useState("")
	const [loading, setLoading] = useState(true)
	const [alarmLink, setAlarmLink] = useState("")

	function onSaveNodeProfile() {
		let dataToSubmit = {
			serial: serial,
			tag_name: tagName,
			location_name: locationName,
		}
        console.log("dataToSubmit", dataToSubmit)
		setLoading(true)
		nodeProfile.getInstance().updateNodeProfile(device_id, dataToSubmit).then((result) => {
            setLoading(false)
            successToast("Node Profile Updated")
        }).catch(err => {
            setLoading(false)
            errorToast("Server Error")
        })
	}

	useEffect(() => {
		setLoading(true)
		nodeProfile.getInstance().getNodeProfile(device_id).then((result) => {
            setSerial(result["serial"])
            setTagName(result["tag_name"])
            setLocationName(result["location"])
            setBase64(result["alarm_service_qr"])
            setAlarmLink(result["alarm_service_url"])
            setLoading(false)
        })
	}, [])

	return (
		<>
			{loading && <LoadingSpinner />}
			<div style={{marginTop:"10px"}}>
				<div className="form-group">
					<label>Serial</label>
					<input
						type="text"
						className="form-control"
						id="txt_serial"
						value={serial}
						disabled
					/>
				</div>
				<div className="form-group">
					<label>Tag Name</label>
					<input
						type="text"
						className="form-control"
						id="txt_tagName"
						value={tagName}
						onChange={(e) => setTagName(e.target.value)}
						required
					/>
				</div>
				<div className="form-group">
					<label>Location Name</label>
					<input
						type="text"
						className="form-control"
						id="txt_locationName"
						value={locationName}
						onChange={(e) => setLocationName(e.target.value)}
						required
					/>
				</div>
				<div className="text-center" style={{margin:"5px 0 0 0"}}>
                    <b>SCAN THE QR BELOW TO ACTIVATE ALARM ALERT SERVICE FOR THIS SERIAL ONLY</b>
                    <img style={{ maxWidth: "100%", height: "auto", display: "block", margin: "0 auto" }} src={base64}/>
                </div>
				<div className="text-center" style={{margin:"5px 0"}}>
                    <b>or CLICK THE LINK BELOW TO ACTIVATE ALARM ALERT SERVICE</b>
                    <div style={{margin:"15px 0 25px 0"}}><a href={`${alarmLink}`} target="_blank">{alarmLink}</a></div>
                </div>
                <div style={{display:"flex", justifyContent:"space-between", marginBottom:"10px"}}>
                    <Button type="button" color="info" onClick={()=>{navigator.clipboard.writeText(alarmLink)}}>
                        Copy Link
                    </Button>
                </div>
                <div style={{display:"flex", justifyContent:"space-between"}}>
                    <Button type="button" color="secondary" onClick={()=>{setOpenProfileNode(false)}}>
                        Close
                    </Button>
                    <Button type="button" color="primary" disabled={localStorage.getItem("cloudatik_demo")} onClick={()=>{onSaveNodeProfile()}}>
                        Save Changes
                    </Button>
                </div>
			</div>
		</>
	);
}

export default EditProfile;
