import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import NewAlarm from "../../Components/Card/single-node/NewAlarm";
import retrieveNode from "../../services/node/retrieve-node";
import { utcToMtyConvertionDisplay } from "../../shared/DateTimeConvertion";
import LoadingSpinner from "../../shared/Spinner/LoadingSpinner";
import Header from "./Header";
import NodeDigital from "./NodeDigital";
import NodeMonitoring from "./NodeMonitoring";

function NodeSummary() {
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState({});
  const [newData, setNewData] = useState([]);
  const [newDataSingleProp, setNewDataSingleProp] = useState([]);
  const [newDataMultiProp, setNewDataMultiProp] = useState([]);
  const [newLcdData, setNewLcdData] = useState([]);
  const [lcdMessage, setLcdMessage] = useState([]);
  const [date, setDate] = useState(new Date(new Date().setHours(new Date().getHours() - 12)));
  const [lastUpdateTime, setLastUpdateTime] = useState("");

  const [isEmpty, setIsEmpty] = useState(false);

  const { search } = useLocation();
  const values = queryString.parse(search);

  const mounted = useRef(false);

  chartData["data"] = [];

  function resetDateAxis(date) {
    setDate(date);
  }

  useEffect(() => {
    setDate(date);
  }, [date]);

  useEffect(() => {
    setNewDataSingleProp(newDataSingleProp);
  }, [newDataSingleProp]);

  useEffect(() => {
    setNewDataMultiProp(newDataMultiProp);
  }, [newDataMultiProp]);

  useEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  });

  useEffect(() => {
    setLoading(true);
    retrieveNode
      .getInstance()
      .getNodeData(values.device_id)
      .then((result) => {
        console.log("*** NodeSummary result", result);
        if (!result) {
          setIsEmpty(true);
        } else {
          setChartData(result);
          setLastUpdateTime(result["last_update"]);
          setIsEmpty(false);
        }
        setLoading(false);
      });

    setInterval(() => {
      if (mounted.current) {
        retrieveNode
          .getInstance()
          .getNodeLatestData(values.device_id)
          .then((result) => {
            setNewData(result["data"]);
            setNewLcdData(result["chart_prop_lcd"]);
            setLcdMessage(result["lcd_msg"]);
            setNewDataSingleProp(result["chart_prop_single"]);
            setNewDataMultiProp(result["chart_prop_combined"]);
            setLastUpdateTime(
              utcToMtyConvertionDisplay(result["data"][0]["datetime"])
            );
          });
      }
    }, 60000);
  }, []);

  useEffect(() => {
    setLastUpdateTime(lastUpdateTime);
  }, [lastUpdateTime]);

  return (
    <center>
      {loading && <LoadingSpinner />}
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="#/v2/dashboard">
          Dashboard
        </Link>
        <Typography color="textPrimary">Node Summary</Typography>
      </Breadcrumbs>
      {isEmpty && <h3>Node Not Active</h3>}
      {chartData["isActive"] && (
        <div style={{ maxWidth: 1200, width: "100%" }}>
          <Header
            tag_name={chartData["tag_name"]}
            serial={chartData["serial"]}
            sensor_set={chartData["sensor_set"]}
            last_update={lastUpdateTime}
            nodeIsActive={chartData["isActive"]}
            resetDateAxis={resetDateAxis}
          />
          <br />
          {values.device === "monitoring" ?
            <NodeMonitoring
              lineChart={chartData["line_chart"]}
              barChart={chartData["bar_chart"]}
              multilineChart={chartData["multi_line_chart"]}
              minDate={date}
              newData={newData}
              newSinglePropData={newDataSingleProp}
              newMultiPropData={newDataMultiProp}
              newLcdData={newLcdData}
              lcdMessage={lcdMessage}
            />
            :
            <NodeDigital
              lineChart={chartData["line_chart"]}
              barChart={chartData["bar_chart"]}
              multilineChart={chartData["multi_line_chart"]}
              lcdStatus={chartData["lcd_status"]}
              minDate={date}
              newData={newData}
              newSinglePropData={newDataSingleProp}
              newMultiPropData={newDataMultiProp}
              newLcdData={newLcdData}
              lcdMessage={lcdMessage}
            />
          }
          <hr />
          <h3>New Alarm</h3>
          <br />
          <NewAlarm device_id={values.device_id} />
        </div>
      )}
    </center>
  );
}

export default NodeSummary;
