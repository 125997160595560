import React, { useState } from "react";
import nodeProfile from "../../../services/node/node-profile";
import { successToast } from "../../../shared/notification";
import LoadingSpinner from "../../../shared/Spinner/LoadingSpinner";

function RemoveNode({ device_id, sensor_set }) {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);

	function onRemoveProfile(e) {
		e.preventDefault();

		let js = {
			username: username,
			password: password,
			device_id: device_id,
		};

		setLoading(true);
		nodeProfile
			.getInstance()
			.deleteNode(js)
			.then((result) => {
				successToast(result["message"]);
				setLoading(false);
				window.location.reload();
			});
	}

	return (
		<>
			{loading && <LoadingSpinner />}
			<form onSubmit={onRemoveProfile}>
				<div className="form-group">
					<label>Username</label>
					<input
						type="text"
						className="form-control"
						id="txt_username"
						value={username}
						onChange={(e) => setUsername(e.target.value)}
					/>
				</div>
				<div className="form-group">
					<label>Password</label>
					<input
						type="password"
						className="form-control"
						id="txt_password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						s
					/>
				</div>
				<button className="btn btn-danger btn-block" type="submit" disabled={localStorage.getItem("cloudatik_demo")}>
					Remove Nodes
				</button>
			</form>
		</>
	);
}

export default RemoveNode;
