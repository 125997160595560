import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import $ from "jquery";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import LoadingSpinner from "../shared/Spinner/LoadingSpinner";
import { IconButton, TextField, Grid } from '@material-ui/core';
import { errorToast, successToast } from "../shared/notification";
import { Button } from "@mui/material";
import axiosInstance from "../shared/api"

am4core.useTheme(am4themes_animated);

const moment = require("moment-timezone");

function RetrieveChart2() {
	const { search } = useLocation();
	const values = queryString.parse(search);
    const [loading, setLoading] = useState(false)
    const [deviceInfo, setDeviceInfo] = useState({})
    const [calculatedLabel, setCalculatedLabel] = useState([])

	const [buttonIsActive, setButtonIsActive] = useState(true)
	const [startDate, setStartDate] = useState("")
	const [endDate, setEndDate] = useState("")

	function datetimeHandleChange(event) {
        if (type === "chart") {
            if (isMoreThanOneMonth(new Date( document.getElementById("start-date").value + " " + document.getElementById("start-time").value ), new Date( document.getElementById("end-date").value + " " + document.getElementById("end-time").value ))) {
                errorToast("invalid date: date set in more than 1 month")
                setButtonIsActive(false)
            } else {
                setButtonIsActive(true)
            }
        } else if (type === "download") {
            if (isMoreThanSixMonth(new Date( document.getElementById("start-date").value + " " + document.getElementById("start-time").value ), new Date( document.getElementById("end-date").value + " " + document.getElementById("end-time").value ))) {
                errorToast("invalid date: date set in more than 6 month")
                setButtonIsActive(false)
            } else {
                setButtonIsActive(true)
            }
        }
	}

    const isMoreThanOneMonth = (startDate, endDate) => {
        const start = new Date(startDate)
        const end = new Date(endDate)

        // Check if end date is smaller than start date
        if (end < start) {
            return true
        }

        const monthsDiff = end.getMonth() - start.getMonth() + (12 * (end.getFullYear() - start.getFullYear()))
        return monthsDiff > 1 || (monthsDiff === 1 && end.getDate() >= start.getDate())
    }
    const isMoreThanSixMonth = (startDate, endDate) => {
        const start = new Date(startDate)
        const end = new Date(endDate)
        if (end < start) {
            return true
        }
        const monthsDiff = end.getMonth() - start.getMonth() + (12 * (end.getFullYear() - start.getFullYear()))
        return monthsDiff > 6 || (monthsDiff === 6 && end.getDate() >= start.getDate())
    }

	let default_start_time = String(
		moment(new Date(new Date().setHours(new Date().getHours() - 10)))
			.tz("Asia/Kuala_Lumpur")
			.format("HH:mm")
	);
	let default_end_time = String(
		moment().tz("Asia/Kuala_Lumpur").format("HH:mm")
	)
    
    function setDefaultDate() {
		// Set Default Date
		document.getElementById("start-time").value = default_start_time;
		document.getElementById("end-time").value = default_end_time;
		document.getElementById("end-date").valueAsDate = new Date();
		document.getElementById("start-date").valueAsDate = new Date(new Date().setHours(new Date().getHours() - 10))
        
        setStartDate(new Date(new Date().setHours(new Date().getHours() - 10))) // follow same as epochStartTime
        setEndDate(new Date()) // follow same as epochEndTime

        setButtonIsActive(true)
    }

	// Retrieve Data
	useEffect(() => {
        setDefaultDate()
        // getTagName()
        // callGetChartApi(epochStartTime, epochEndTime)
        
        // get chart parameter
        getParameter()
	}, [])

    const [parameterList, setParameterList] = useState([])
    const [jobId, setJobId] = useState(null)
	const [fullChartData2, setFullChartData2] = useState(null)

    function getParameter() {
        axiosInstance.get(`https://api.cloudatik.com/chart/v1.0/historical/parameter/${values.device_id}`, { headers : {
            "Authorization": localStorage.getItem('cloudatik-access'),
            "Content-Type": "application/json"
        }}).then(res =>{
            console.log(123, res)
            setParameterList(res.data.data)
            setDeviceInfo(res.data)
        }).catch(err => {
            console.log(234, err)
        })
    }

    function handleParameterClick(index) {
        const updatedList = parameterList.map((item, i) => {
            if (i === index) {
                return { ...item, click: !item.click }
            }
            return item
        })
        setParameterList(updatedList)
    }

    function sendPayload() {
        console.log("sendPayload")
        let new_start_epochtime = moment( new Date( document.getElementById("start-date").value + " " + document.getElementById("start-time").value )).tz("Asia/Kuala_Lumpur").unix();
        let new_end_epochtime = moment( new Date( document.getElementById("end-date").value + " " + document.getElementById("end-time").value )).tz("Asia/Kuala_Lumpur").unix()

        let selectedParameter = []
        for (let param of parameterList){
            if (param.click) selectedParameter.push(param.parameter)
        }
        if (selectedParameter.length < 1) return errorToast("please select parameter")

        // {"device_id": "ylCITw", "start": "1717664304", "end": "1718194310", "parameters": ["t","h","vpd"]}
        let payload = {
            "device_id": values.device_id,
            "start": new_start_epochtime,
            "end": new_end_epochtime,
            "parameters": selectedParameter
        }

        setLoading(true)
        axiosInstance.post(`https://api.cloudatik.com/chart/v1.0/historical/load/`, payload, { headers : {
            "Authorization": localStorage.getItem('cloudatik-access'),
            "Content-Type": "application/json"
        }}).then(res =>{
            // console.log("parameter", res)
            setJobId(res.data.jobid)
            setLoading(false)
        }).catch(err => {
            console.log(234, err)
            setLoading(false)
        })
    }

    useEffect(() => {
        if (jobId) {
            let count = 0
            const fetchData = async () => {
                const response = await axiosInstance.get(`https://api.cloudatik.com/chart/v1.0/historical/status/${jobId}/`, {
                    headers: { 
                        "Authorization": localStorage.getItem('cloudatik-access'),
                        "Content-Type": "application/json"
                    },
                })
                console.log(234, response)

                if (!response.data.return && count >= 20) {
                    setLoading(false)
                    clearAllInterval()
                    return errorToast("Server error")
                } else if (response.data.return === true && response.data.message == "Historical Chart Data Arrived!") {
                    clearAllInterval()
                    console.log("Historical Chart Data Arrived!", response.data.data.chart)
                    setFullChartData2(response.data.data.chart)
                }
            }
        
            // Initial call
            fetchData()
            // Set up interval for subsequent calls every 3 seconds
            const intervalId = setInterval(() => {
                count += 3
                fetchData()
                clearAllInterval()
            }, 3000)
            // Cleanup function
            return () => {
                clearInterval(intervalId)
            }
        }
    }, [jobId])

    useEffect(() => {
        if (fullChartData2) {
            setLoading(false)
            createChart2()
        }
    }, [fullChartData2])
    

    function clearAllInterval() {
        // Get a reference to the last interval + 1
        const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER)

        // Clear any timeout/interval up to that id
        for (let i = 1; i < interval_id; i++) {
            window.clearInterval(i)
        }
    }

    function createChart2() {
        let chart = am4core.create("chartdiv", am4charts.XYChart);

        // HERE
        let sortedChartData = {}
        let parameterList = []
        console.log("fullchartdata2", fullChartData2)
        for (let parameter of fullChartData2) {
            for (let data of parameter.data) {
                sortedChartData[data.datetime] = {
                    ...sortedChartData[data.datetime],
                    [parameter.parameter]: data.value
                }
            }
            parameterList.push({
                "label": parameter.ylabel,
                "series": parameter.parameter,
            })
        }
        // console.log("sortedChartData", sortedChartData)

        let sortedChartData2 = []
        for (let datetime in sortedChartData) {
            let temp = {
                "datetime": new Date(datetime),
            }
            for (let param in sortedChartData[datetime]) {
                temp = {
                    ...temp,
                    [param]: sortedChartData[datetime][param]
                }
            }
            sortedChartData2.push(temp)
        }
        // console.log("sortedChartData2", sortedChartData2)

        // Create axes
        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        // dateAxis.renderer.grid.template.location = 0
        dateAxis.renderer.minGridDistance = 50
        
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        
        let series
        // Create series
        function createSeries(field, name) {
            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
            if (chart.yAxes.indexOf(valueAxis) != 0) {
                valueAxis.syncWithAxis = chart.yAxes.getIndex(0)
            }

            series = chart.series.push(new am4charts.LineSeries())
            series.dataFields.valueY = field
            series.dataFields.dateX = "datetime"
            series.strokeWidth = 2
            series.yAxis = valueAxis
            series.name = name
            series.tooltipText = "{name}: [bold]{valueY}[/]"
            series.tensionX = 0.8
            series.showOnInit = true

            valueAxis.renderer.line.strokeOpacity = 1
            valueAxis.renderer.line.strokeWidth = 2
            valueAxis.renderer.line.stroke = series.stroke
            valueAxis.renderer.labels.template.fill = series.stroke
            // valueAxis.renderer.opposite = true

            valueAxis.renderer.labels.template.fontSize = 10
        }
        chart.data = sortedChartData2

        for (let param of parameterList) {
            createSeries(param.series, param.label)
        }

        chart.legend = new am4charts.Legend()
        chart.cursor = new am4charts.XYCursor()

        
        chart.numberFormatter.numberFormat = "#.00"
        chart.colors.step = 2

        // Export
        chart.exporting.menu = new am4core.ExportMenu()
        chart.exporting.menu.align = "right"
        chart.exporting.menu.verticalAlign = "top"

        // Scrollbar
        var scrollbar = new am4charts.XYChartScrollbar()
        scrollbar.series.push(series)
        chart.scrollbarX = scrollbar
    }

    const [type, setType] = useState("chart") // chart | download
    const [email, setEmail] = useState("")

    function sendEmail() {
        console.log("sendEmail")
        let new_start_epochtime = moment( new Date( document.getElementById("start-date").value + " " + document.getElementById("start-time").value )).tz("Asia/Kuala_Lumpur").unix();
        let new_end_epochtime = moment( new Date( document.getElementById("end-date").value + " " + document.getElementById("end-time").value )).tz("Asia/Kuala_Lumpur").unix()

        let selectedParameter = []
        for (let param of parameterList){
            if (param.click) selectedParameter.push(param.parameter)
        }
        if (selectedParameter.length < 1) return errorToast("please select parameter")

        // {"device_id": "ylCITw", "start": "1717664304", "end": "1718194310", "parameters": ["t","h","vpd"]}
        let payload = {
            "device_id": values.device_id,
            "start": new_start_epochtime,
            "end": new_end_epochtime,
            "parameters": selectedParameter,
            "email": email
        }

        setLoading(true)
        axiosInstance.post(`https://api.cloudatik.com/chart/v1.0/download/data/`, payload, { headers : {
            "Authorization": localStorage.getItem('cloudatik-access'),
            "Content-Type": "application/json"
        }}).then(res =>{
            console.log(123, res)
            successToast(res.data.msg)
            setLoading(false)
        }).catch(err => {
            console.log(234, err)
            setLoading(false)
        })
    }
	return (
		<div>
        {loading && <LoadingSpinner />}
            <Grid container  style={{ textAlign: 'center', maxWidth: '750px', margin: 'auto', justifyContent:"space-evenly", marginBottom: "10px" }}>
                <Button variant="contained" color="primary" onClick={()=>{
                    setType("chart")
                    setDefaultDate()
                }}>Plot Chart</Button>
                <Button variant="contained" color="secondary" onClick={()=>{
                    setDefaultDate()
                    setType("download")
                    setEmail("")
                }}>Download Raw Data</Button>
            </Grid>

			<center>
                <div style={{display: 'flex',  justifyContent: 'center'}}>
                    <h3>{deviceInfo.serial}</h3>
                </div>
				<div style={{ maxWidth: 1200 }}>
					<Row style={{ margin: '0' }}>
						<Col>
							<FormGroup>
								<Label>Start Date</Label>
								<Input
									type="date"
									id="start-date"
									onChange={(e) => {
										datetimeHandleChange(e);
									}}
								/>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label>Start Time</Label>
								<Input
									type="time"
									id="start-time"
									onChange={(e) => {
										datetimeHandleChange(e);
									}}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row style={{ margin: '0' }}>
						<Col>
							<FormGroup>
								<Label>End Date</Label>
								<Input
									type="date"
									id="end-date"
									onChange={(e) => {
										datetimeHandleChange(e);
									}}
								/>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label>End Time</Label>
								<Input
									type="time"
									id="end-time"
									onChange={(e) => {
										datetimeHandleChange(e);
									}}
								/>
							</FormGroup>
						</Col>
					</Row>

                    {type === "download" && (
                        <Grid style={{marginBottom: "10px"}}>
                            <TextField
                                value={email}
                                label={"Email"}
                                onChange={(e)=>{setEmail(e.target.value)}}
                            />
                        </Grid>
                    )}

					<div className="row pr-0 py-2 pl-2 mx-0">
                        {parameterList.map( (param, index) => {
                            return(
                                <div className="col-4 col-sm-2" key={index} style={{padding:"15px 5px"}}>
                                    <div className="form-check row" style={{marginRight: 8}}>
                                        <input className="form-check-input col-2" type="checkbox" value={param.unit || ""} onClick={()=>handleParameterClick(index)}/>
                                        <label className="form-check-label col-10">{param.description}</label>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
					<br />
                    <div className="row" style={{marginTop: "0.3rem", marginBottom:"1.4rem"}}>
                        {calculatedLabel.map(label => {
                            return (
                                <>
                                <span style={{fontSize: "1.5rem", width: "100%", marginBottom:"0.8rem"}}><b>{label.label} : {label.value} {label.unit}</b></span>    
                                </>
                            )
                        })}
                    </div>

                    {type === "chart" && (
                        <Button variant="contained" color="primary" disabled={!buttonIsActive ? true : false} onClick={() => sendPayload()}>
                            Generate
                        </Button>
                    )}
                    {type === "download" && (
                        <Button variant="outlined" color="primary" disabled={email === "" ? true : (!buttonIsActive ? true : false)} onClick={() => sendEmail()}>
                            Submit Request
                        </Button>
                    )}
                    
                    
                    {loading ? 
                    (<LoadingSpinner />) : 
					(<div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>)}
				</div>
			</center>
		</div>
	);
}

export default RetrieveChart2
