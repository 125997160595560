import React, { useEffect, useState } from 'react';
import "./scheduler.css"
import { defaultData } from "../data/defaultData"
import { defaultSchedulerBlockData } from "../data/DefaultSchedulerBlockData"
import axiosInstance from '../shared/api';
import { Button, TextField,  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material/';
import { useParams } from "react-router-dom";

export default function Schedueler() {
    const { device, device_id } = useParams()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [schedulerData, setSchedulerData] = useState(defaultData)
    const [blockStep, setBlockStep] = useState(0)
    const [stageStep, setStageStep] = useState(1)
    const [toggleAddModal, setToggleAddModal] = useState(false)
    const [newRecipeName, setNewRecipeName] = useState('')
    const [recipeList, setRecipeList] = useState([])
    const [selectedRecipe, setSelectedRecipe] = useState(-1)
    const [calculatedTotalDayOfGrowth, setCalculatedTotalDayOfGrowth] = useState([
        // block
        0,0,0,0,0
    ])
    const [recipeData, setRecipeData] = useState(schedulerData.recipe)
    const [totalCalculation, setTotalCalculationValue] = useState([
        // block
        [
            // stage
            {
                // session
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
        ],
        [
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
        ],
        [
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
        ],
        [
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
        ],
        [
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
            {
                "session1_total_duration": 0,
                "session2_total_duration": 0,
                "session3_total_duration": 0,
                "session4_total_duration": 0,
                "session5_total_duration": 0,
                "session6_total_duration": 0,
            },
        ]
    ])
    
    const [dateCalculation, setDateCalculation] = useState([
        // block
        [
            // stage
            {
                "start_date": "",
                "end_date": ""
            },
            {
                "start_date": "",
                "end_date": ""
            }, 
            {
                "start_date": "",
                "end_date": ""
            }, 
            {
                "start_date": "",
                "end_date": ""
            }, 
            {
                "start_date": "",
                "end_date": ""
            }, 
            {
                "start_date": "",
                "end_date": ""
            }, 
        ],
        [
            // stage
            {
                "start_date": "",
                "end_date": ""
            },
            {
                "start_date": "",
                "end_date": ""
            }, 
            {
                "start_date": "",
                "end_date": ""
            }, 
            {
                "start_date": "",
                "end_date": ""
            }, 
            {
                "start_date": "",
                "end_date": ""
            }, 
            {
                "start_date": "",
                "end_date": ""
            }, 
        ],
        [
            // stage
            {
                "start_date": "",
                "end_date": ""
            },
            {
                "start_date": "",
                "end_date": ""
            }, 
            {
                "start_date": "",
                "end_date": ""
            }, 
            {
                "start_date": "",
                "end_date": ""
            }, 
            {
                "start_date": "",
                "end_date": ""
            }, 
            {
                "start_date": "",
                "end_date": ""
            }, 
        ],
        [
            // stage
            {
                "start_date": "",
                "end_date": ""
            },
            {
                "start_date": "",
                "end_date": ""
            }, 
            {
                "start_date": "",
                "end_date": ""
            }, 
            {
                "start_date": "",
                "end_date": ""
            }, 
            {
                "start_date": "",
                "end_date": ""
            }, 
            {
                "start_date": "",
                "end_date": ""
            }, 
        ],
        [
            // stage
            {
                "start_date": "",
                "end_date": ""
            },
            {
                "start_date": "",
                "end_date": ""
            }, 
            {
                "start_date": "",
                "end_date": ""
            }, 
            {
                "start_date": "",
                "end_date": ""
            }, 
            {
                "start_date": "",
                "end_date": ""
            }, 
            {
                "start_date": "",
                "end_date": ""
            }, 
        ],
    ])

    useEffect(() => {
        getData()
        getRecipeList()

        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }
        // Attach the event listener
        window.addEventListener('resize', handleResize)
        // Detach the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    function getRecipeList() {
        axiosInstance.get(`https://api.cloudatik.com/remote/v1.0/agzui/scheduler/recipe/${device}`).then(res => {
            console.log("getRecipeList", res.data)
            setRecipeList(res.data)
        }).catch(err => {
            console.log("err", err)
        })
    }

    function getData() {
        axiosInstance.get(`https://api.cloudatik.com/remote/v1.0/agzui/scheduler/setting/${device_id}`).then(res => {
            console.log("getData", res)
            setSchedulerData(res.data)
        }).catch(err => {
            console.log("err", err)
        })
    }
    const isTablet = windowWidth <= 768 // Adjust the threshold based on your tablet breakpoint

    useEffect(() => {
        // console.log("schedulerData", schedulerData)
        
        const updatedData = { ...totalCalculation }
        for (let i = 0; i < 5; i ++) {
            // i = block , x = stage, y = session
            for (let x = 0; x < 4; x ++) {
                for (let y = 0; y < 4; y ++) {
                    updatedData[i][x][`session${y+1}_total_duration`] = (schedulerData.recipe[i].setting[x].parameter[`session${y+1}_cycle`] || 0) * ( schedulerData.recipe[i].setting[x].parameter[`session${y+1}_duration`] || 0)
                }
            }
        }
        // console.log("updatedData", updatedData)
        setTotalCalculationValue(updatedData)

        // Start Date and End Date
        const updateDateCalculation = { ...dateCalculation }

        updateDateCalculation[0][0]["start_date"] = schedulerData.recipe[0].setting[0]["start_date"]
        updateDateCalculation[0][0]["end_date"] = dateCalculate(schedulerData.recipe[0].setting[0]["start_date"], schedulerData.recipe[0].setting[0]["day_growth"])

        updateDateCalculation[0][1]["start_date"] = dateCalculate(updateDateCalculation[0][0]["end_date"], 1)
        updateDateCalculation[0][1]["end_date"] = dateCalculate(updateDateCalculation[0][1]["start_date"], schedulerData.recipe[0].setting[1]["day_growth"])
        updateDateCalculation[0][2]["start_date"] = dateCalculate(updateDateCalculation[0][1]["end_date"], 1)
        updateDateCalculation[0][2]["end_date"] = dateCalculate(updateDateCalculation[0][2]["start_date"], schedulerData.recipe[0].setting[2]["day_growth"])
        updateDateCalculation[0][3]["start_date"] = dateCalculate(updateDateCalculation[0][2]["end_date"], 1)
        updateDateCalculation[0][3]["end_date"] = dateCalculate(updateDateCalculation[0][3]["start_date"], schedulerData.recipe[0].setting[3]["day_growth"])
        updateDateCalculation[0][4]["start_date"] = dateCalculate(updateDateCalculation[0][3]["end_date"], 1)
        updateDateCalculation[0][4]["end_date"] = dateCalculate(updateDateCalculation[0][4]["start_date"], schedulerData.recipe[0].setting[4]["day_growth"])
        updateDateCalculation[0][5]["start_date"] = dateCalculate(updateDateCalculation[0][4]["end_date"], 1)
        updateDateCalculation[0][5]["end_date"] = dateCalculate(updateDateCalculation[0][5]["start_date"], schedulerData.recipe[0].setting[5]["day_growth"])

        updateDateCalculation[1][0]["start_date"] = schedulerData.recipe[1].setting[0]["start_date"]
        updateDateCalculation[1][0]["end_date"] = dateCalculate(schedulerData.recipe[1].setting[0]["start_date"], schedulerData.recipe[1].setting[0]["day_growth"])

        updateDateCalculation[1][1]["start_date"] = dateCalculate(updateDateCalculation[1][0]["end_date"], 1)
        updateDateCalculation[1][1]["end_date"] = dateCalculate(updateDateCalculation[1][1]["start_date"], schedulerData.recipe[1].setting[1]["day_growth"])
        updateDateCalculation[1][2]["start_date"] = dateCalculate(updateDateCalculation[1][1]["end_date"], 1)
        updateDateCalculation[1][2]["end_date"] = dateCalculate(updateDateCalculation[1][2]["start_date"], schedulerData.recipe[1].setting[2]["day_growth"])
        updateDateCalculation[1][3]["start_date"] = dateCalculate(updateDateCalculation[1][2]["end_date"], 1)
        updateDateCalculation[1][3]["end_date"] = dateCalculate(updateDateCalculation[1][3]["start_date"], schedulerData.recipe[1].setting[3]["day_growth"])
        updateDateCalculation[1][4]["start_date"] = dateCalculate(updateDateCalculation[1][3]["end_date"], 1)
        updateDateCalculation[1][4]["end_date"] = dateCalculate(updateDateCalculation[1][4]["start_date"], schedulerData.recipe[1].setting[4]["day_growth"])
        updateDateCalculation[1][5]["start_date"] = dateCalculate(updateDateCalculation[1][4]["end_date"], 1)
        updateDateCalculation[1][5]["end_date"] = dateCalculate(updateDateCalculation[1][5]["start_date"], schedulerData.recipe[1].setting[5]["day_growth"])

        updateDateCalculation[2][0]["start_date"] = schedulerData.recipe[2].setting[0]["start_date"]
        updateDateCalculation[2][0]["end_date"] = dateCalculate(schedulerData.recipe[2].setting[0]["start_date"], schedulerData.recipe[2].setting[0]["day_growth"])

        updateDateCalculation[2][1]["start_date"] = dateCalculate(updateDateCalculation[2][0]["end_date"], 1)
        updateDateCalculation[2][1]["end_date"] = dateCalculate(updateDateCalculation[2][1]["start_date"], schedulerData.recipe[2].setting[1]["day_growth"])
        updateDateCalculation[2][2]["start_date"] = dateCalculate(updateDateCalculation[2][1]["end_date"], 1)
        updateDateCalculation[2][2]["end_date"] = dateCalculate(updateDateCalculation[2][2]["start_date"], schedulerData.recipe[2].setting[2]["day_growth"])
        updateDateCalculation[2][3]["start_date"] = dateCalculate(updateDateCalculation[2][2]["end_date"], 1)
        updateDateCalculation[2][3]["end_date"] = dateCalculate(updateDateCalculation[2][3]["start_date"], schedulerData.recipe[2].setting[3]["day_growth"])
        updateDateCalculation[2][4]["start_date"] = dateCalculate(updateDateCalculation[2][3]["end_date"], 1)
        updateDateCalculation[2][4]["end_date"] = dateCalculate(updateDateCalculation[2][4]["start_date"], schedulerData.recipe[2].setting[4]["day_growth"])
        updateDateCalculation[2][5]["start_date"] = dateCalculate(updateDateCalculation[2][4]["end_date"], 1)
        updateDateCalculation[2][5]["end_date"] = dateCalculate(updateDateCalculation[2][5]["start_date"], schedulerData.recipe[2].setting[5]["day_growth"])

        updateDateCalculation[3][0]["start_date"] = schedulerData.recipe[3].setting[0]["start_date"]
        updateDateCalculation[3][0]["end_date"] = dateCalculate(schedulerData.recipe[3].setting[0]["start_date"], schedulerData.recipe[3].setting[0]["day_growth"])

        updateDateCalculation[3][1]["start_date"] = dateCalculate(updateDateCalculation[3][0]["end_date"], 1)
        updateDateCalculation[3][1]["end_date"] = dateCalculate(updateDateCalculation[3][1]["start_date"], schedulerData.recipe[3].setting[1]["day_growth"])
        updateDateCalculation[3][2]["start_date"] = dateCalculate(updateDateCalculation[3][1]["end_date"], 1)
        updateDateCalculation[3][2]["end_date"] = dateCalculate(updateDateCalculation[3][2]["start_date"], schedulerData.recipe[3].setting[2]["day_growth"])
        updateDateCalculation[3][3]["start_date"] = dateCalculate(updateDateCalculation[3][2]["end_date"], 1)
        updateDateCalculation[3][3]["end_date"] = dateCalculate(updateDateCalculation[3][3]["start_date"], schedulerData.recipe[3].setting[3]["day_growth"])
        updateDateCalculation[3][4]["start_date"] = dateCalculate(updateDateCalculation[3][3]["end_date"], 1)
        updateDateCalculation[3][4]["end_date"] = dateCalculate(updateDateCalculation[3][4]["start_date"], schedulerData.recipe[3].setting[4]["day_growth"])
        updateDateCalculation[3][5]["start_date"] = dateCalculate(updateDateCalculation[3][4]["end_date"], 1)
        updateDateCalculation[3][5]["end_date"] = dateCalculate(updateDateCalculation[3][5]["start_date"], schedulerData.recipe[3].setting[5]["day_growth"])

        updateDateCalculation[4][0]["start_date"] = schedulerData.recipe[4].setting[0]["start_date"]
        updateDateCalculation[4][0]["end_date"] = dateCalculate(schedulerData.recipe[4].setting[0]["start_date"], schedulerData.recipe[4].setting[0]["day_growth"])

        updateDateCalculation[4][1]["start_date"] = dateCalculate(updateDateCalculation[4][0]["end_date"], 1)
        updateDateCalculation[4][1]["end_date"] = dateCalculate(updateDateCalculation[4][1]["start_date"], schedulerData.recipe[4].setting[1]["day_growth"])
        updateDateCalculation[4][2]["start_date"] = dateCalculate(updateDateCalculation[4][1]["end_date"], 1)
        updateDateCalculation[4][2]["end_date"] = dateCalculate(updateDateCalculation[4][2]["start_date"], schedulerData.recipe[4].setting[2]["day_growth"])
        updateDateCalculation[4][3]["start_date"] = dateCalculate(updateDateCalculation[4][2]["end_date"], 1)
        updateDateCalculation[4][3]["end_date"] = dateCalculate(updateDateCalculation[4][3]["start_date"], schedulerData.recipe[4].setting[3]["day_growth"])
        updateDateCalculation[4][4]["start_date"] = dateCalculate(updateDateCalculation[4][3]["end_date"], 1)
        updateDateCalculation[4][4]["end_date"] = dateCalculate(updateDateCalculation[4][4]["start_date"], schedulerData.recipe[4].setting[4]["day_growth"])
        updateDateCalculation[4][5]["start_date"] = dateCalculate(updateDateCalculation[4][4]["end_date"], 1)
        updateDateCalculation[4][5]["end_date"] = dateCalculate(updateDateCalculation[4][5]["start_date"], schedulerData.recipe[4].setting[5]["day_growth"])

        // console.log(updateDateCalculation)
        setDateCalculation(updateDateCalculation)


        let block1DaysOfGrowth = (
            Number(schedulerData.recipe[0].setting[0]["status"] == 1 ? schedulerData.recipe[0].setting[0]["day_growth"] : 0) +
            Number(schedulerData.recipe[0].setting[1]["status"] == 1 ? schedulerData.recipe[0].setting[1]["day_growth"] : 0) +
            Number(schedulerData.recipe[0].setting[2]["status"] == 1 ? schedulerData.recipe[0].setting[2]["day_growth"] : 0) +
            Number(schedulerData.recipe[0].setting[3]["status"] == 1 ? schedulerData.recipe[0].setting[3]["day_growth"] : 0) +
            Number(schedulerData.recipe[0].setting[4]["status"] == 1 ? schedulerData.recipe[0].setting[4]["day_growth"] : 0) +
            Number(schedulerData.recipe[0].setting[5]["status"] == 1 ? schedulerData.recipe[0].setting[5]["day_growth"] : 0)
          )
        let block2DaysOfGrowth = (
            Number(schedulerData.recipe[1].setting[0]["status"] == 1 ? schedulerData.recipe[1].setting[0]["day_growth"] : 0) +
            Number(schedulerData.recipe[1].setting[1]["status"] == 1 ? schedulerData.recipe[1].setting[1]["day_growth"] : 0) +
            Number(schedulerData.recipe[1].setting[2]["status"] == 1 ? schedulerData.recipe[1].setting[2]["day_growth"] : 0) +
            Number(schedulerData.recipe[1].setting[3]["status"] == 1 ? schedulerData.recipe[1].setting[3]["day_growth"] : 0) +
            Number(schedulerData.recipe[1].setting[4]["status"] == 1 ? schedulerData.recipe[1].setting[4]["day_growth"] : 0) +
            Number(schedulerData.recipe[1].setting[5]["status"] == 1 ? schedulerData.recipe[1].setting[5]["day_growth"] : 0)
          )
        let block3DaysOfGrowth = (
            Number(schedulerData.recipe[2].setting[0]["status"] == 1 ? schedulerData.recipe[2].setting[0]["day_growth"] : 0) +
            Number(schedulerData.recipe[2].setting[1]["status"] == 1 ? schedulerData.recipe[2].setting[1]["day_growth"] : 0) +
            Number(schedulerData.recipe[2].setting[2]["status"] == 1 ? schedulerData.recipe[2].setting[2]["day_growth"] : 0) +
            Number(schedulerData.recipe[2].setting[3]["status"] == 1 ? schedulerData.recipe[2].setting[3]["day_growth"] : 0) +
            Number(schedulerData.recipe[2].setting[4]["status"] == 1 ? schedulerData.recipe[2].setting[4]["day_growth"] : 0) +
            Number(schedulerData.recipe[2].setting[5]["status"] == 1 ? schedulerData.recipe[2].setting[5]["day_growth"] : 0)
          )
        let block4DaysOfGrowth = (
            Number(schedulerData.recipe[3].setting[0]["status"] == 1 ? schedulerData.recipe[3].setting[0]["day_growth"] : 0) +
            Number(schedulerData.recipe[3].setting[1]["status"] == 1 ? schedulerData.recipe[3].setting[1]["day_growth"] : 0) +
            Number(schedulerData.recipe[3].setting[2]["status"] == 1 ? schedulerData.recipe[3].setting[2]["day_growth"] : 0) +
            Number(schedulerData.recipe[3].setting[3]["status"] == 1 ? schedulerData.recipe[3].setting[3]["day_growth"] : 0) +
            Number(schedulerData.recipe[3].setting[4]["status"] == 1 ? schedulerData.recipe[3].setting[4]["day_growth"] : 0) +
            Number(schedulerData.recipe[3].setting[5]["status"] == 1 ? schedulerData.recipe[3].setting[5]["day_growth"] : 0)
          )
        let block5DaysOfGrowth = (
            Number(schedulerData.recipe[4].setting[0]["status"] == 1 ? schedulerData.recipe[4].setting[0]["day_growth"] : 0) +
            Number(schedulerData.recipe[4].setting[1]["status"] == 1 ? schedulerData.recipe[4].setting[1]["day_growth"] : 0) +
            Number(schedulerData.recipe[4].setting[2]["status"] == 1 ? schedulerData.recipe[4].setting[2]["day_growth"] : 0) +
            Number(schedulerData.recipe[4].setting[3]["status"] == 1 ? schedulerData.recipe[4].setting[3]["day_growth"] : 0) +
            Number(schedulerData.recipe[4].setting[4]["status"] == 1 ? schedulerData.recipe[4].setting[4]["day_growth"] : 0) +
            Number(schedulerData.recipe[4].setting[5]["status"] == 1 ? schedulerData.recipe[4].setting[5]["day_growth"] : 0)
          )


        // calculatedTotalDayOfGrowth
        setCalculatedTotalDayOfGrowth([
            block1DaysOfGrowth,
            block2DaysOfGrowth,
            block3DaysOfGrowth,
            block4DaysOfGrowth,
            block5DaysOfGrowth,
        ])

    }, [schedulerData])

    function dateCalculate(date, day) {
        if (date == "") date = "2024-01-01" // set default date to 1st jan
        let givenDate = new Date(date)
        givenDate.setDate(givenDate.getDate() + (Number(day) == 0 ? Number(day) : Number(day) - 1))
        let resultDateString = givenDate.toISOString().split('T')[0]
        return resultDateString
    }


    function handleInputChange(e, field, stage) {
        // schedulerData.recipe[blockStep].setting[0].description

        // Create a copy of the state to avoid mutating the original state
        const updatedData = { ...schedulerData }
    
        if (field == "status") {
            // validate stage if more than selectedStage than will shut it off
            for (let i=stage; i < 6; i++) {
                updatedData.recipe[blockStep].setting[i].status = 0

                // when stage is off, then duration cycle days of growth all fill with 0
                updatedData.recipe[blockStep].setting[i]["day_growth"] = 0

                for (let parameterIndex in updatedData.recipe[blockStep].setting[i]["parameter"]) {
                    updatedData.recipe[blockStep].setting[i]["parameter"][parameterIndex] = 0
                }
            }

            if (stage == 0 || (stage > 0 && updatedData.recipe[blockStep].setting[stage - 1][field] != 0)) {
                // Update the description field
                updatedData.recipe[blockStep].setting[stage][field] = e.target.checked
            }

        } else if (field == "location" || field == "plant" || field == "season") {
            updatedData.recipe[blockStep][field] = e.target.value
        } else {
            // Update the description field
            updatedData.recipe[blockStep].setting[stage][field] = e.target.value
        }
    
        // Set the updated state
        setSchedulerData(updatedData)
    }

    function handleSessionInputChange(e, field, stage) {
        // schedulerData.recipe[blockStep].setting[0].parameter["session1_state"]

        // Create a copy of the state to avoid mutating the original state
        const updatedData = { ...schedulerData }

        if (
            field == "session1_state" || field == "session2_state" || field == "session3_state" || field == "session4_state" || field == "session5_state" || field == "session6_state" ||
            field == "session1_medium" || field == "session2_medium" || field == "session3_medium" || field == "session4_medium" || field == "session5_medium" || field == "session6_medium"
        ) {
            // Update the description field
            updatedData.recipe[blockStep].setting[stage].parameter[field] = e.target.checked
        } else {
            // Update the description field
            updatedData.recipe[blockStep].setting[stage].parameter[field] = e.target.value
        }

        // Set the updated state
        setSchedulerData(updatedData)
    }

    function handleSubmit() {
        let updatedData = {...schedulerData}
  
        updatedData["recipe"][0]["setting"][0]["end_date"] = dateCalculation[0][0]["end_date"]
        updatedData["recipe"][0]["setting"][0]["start_date"] = dateCalculation[0][0]["start_date"]
        updatedData["recipe"][0]["setting"][1]["end_date"] = dateCalculation[0][1]["end_date"]
        updatedData["recipe"][0]["setting"][1]["start_date"] = dateCalculation[0][1]["start_date"]
        updatedData["recipe"][0]["setting"][2]["end_date"] = dateCalculation[0][2]["end_date"]
        updatedData["recipe"][0]["setting"][2]["start_date"] = dateCalculation[0][2]["start_date"]
        updatedData["recipe"][0]["setting"][3]["end_date"] = dateCalculation[0][3]["end_date"]
        updatedData["recipe"][0]["setting"][3]["start_date"] = dateCalculation[0][3]["start_date"]
        updatedData["recipe"][0]["setting"][4]["end_date"] = dateCalculation[0][4]["end_date"]
        updatedData["recipe"][0]["setting"][4]["start_date"] = dateCalculation[0][4]["start_date"]
        
        updatedData["recipe"][1]["setting"][0]["end_date"] = dateCalculation[1][0]["end_date"]
        updatedData["recipe"][1]["setting"][0]["start_date"] = dateCalculation[1][0]["start_date"]
        updatedData["recipe"][1]["setting"][1]["end_date"] = dateCalculation[1][1]["end_date"]
        updatedData["recipe"][1]["setting"][1]["start_date"] = dateCalculation[1][1]["start_date"]
        updatedData["recipe"][1]["setting"][2]["end_date"] = dateCalculation[1][2]["end_date"]
        updatedData["recipe"][1]["setting"][2]["start_date"] = dateCalculation[1][2]["start_date"]
        updatedData["recipe"][1]["setting"][3]["end_date"] = dateCalculation[1][3]["end_date"]
        updatedData["recipe"][1]["setting"][3]["start_date"] = dateCalculation[1][3]["start_date"]
        updatedData["recipe"][1]["setting"][4]["end_date"] = dateCalculation[1][4]["end_date"]
        updatedData["recipe"][1]["setting"][4]["start_date"] = dateCalculation[1][4]["start_date"]
        
        
        updatedData["recipe"][2]["setting"][0]["end_date"] = dateCalculation[2][0]["end_date"]
        updatedData["recipe"][2]["setting"][0]["start_date"] = dateCalculation[2][0]["start_date"]
        updatedData["recipe"][2]["setting"][1]["end_date"] = dateCalculation[2][1]["end_date"]
        updatedData["recipe"][2]["setting"][1]["start_date"] = dateCalculation[2][1]["start_date"]
        updatedData["recipe"][2]["setting"][2]["end_date"] = dateCalculation[2][2]["end_date"]
        updatedData["recipe"][2]["setting"][2]["start_date"] = dateCalculation[2][2]["start_date"]
        updatedData["recipe"][2]["setting"][3]["end_date"] = dateCalculation[2][3]["end_date"]
        updatedData["recipe"][2]["setting"][3]["start_date"] = dateCalculation[2][3]["start_date"]
        updatedData["recipe"][2]["setting"][4]["end_date"] = dateCalculation[2][4]["end_date"]
        updatedData["recipe"][2]["setting"][4]["start_date"] = dateCalculation[2][4]["start_date"]
        
        updatedData["recipe"][3]["setting"][0]["end_date"] = dateCalculation[3][0]["end_date"]
        updatedData["recipe"][3]["setting"][0]["start_date"] = dateCalculation[3][0]["start_date"]
        updatedData["recipe"][3]["setting"][1]["end_date"] = dateCalculation[3][1]["end_date"]
        updatedData["recipe"][3]["setting"][1]["start_date"] = dateCalculation[3][1]["start_date"]
        updatedData["recipe"][3]["setting"][2]["end_date"] = dateCalculation[3][2]["end_date"]
        updatedData["recipe"][3]["setting"][2]["start_date"] = dateCalculation[3][2]["start_date"]
        updatedData["recipe"][3]["setting"][3]["end_date"] = dateCalculation[3][3]["end_date"]
        updatedData["recipe"][3]["setting"][3]["start_date"] = dateCalculation[3][3]["start_date"]
        updatedData["recipe"][3]["setting"][4]["end_date"] = dateCalculation[3][4]["end_date"]
        updatedData["recipe"][3]["setting"][4]["start_date"] = dateCalculation[3][4]["start_date"]
        
        updatedData["recipe"][4]["setting"][0]["end_date"] = dateCalculation[4][0]["end_date"]
        updatedData["recipe"][4]["setting"][0]["start_date"] = dateCalculation[4][0]["start_date"]
        updatedData["recipe"][4]["setting"][1]["end_date"] = dateCalculation[4][1]["end_date"]
        updatedData["recipe"][4]["setting"][1]["start_date"] = dateCalculation[4][1]["start_date"]
        updatedData["recipe"][4]["setting"][2]["end_date"] = dateCalculation[4][2]["end_date"]
        updatedData["recipe"][4]["setting"][2]["start_date"] = dateCalculation[4][2]["start_date"]
        updatedData["recipe"][4]["setting"][3]["end_date"] = dateCalculation[4][3]["end_date"]
        updatedData["recipe"][4]["setting"][3]["start_date"] = dateCalculation[4][3]["start_date"]
        updatedData["recipe"][4]["setting"][4]["end_date"] = dateCalculation[4][4]["end_date"]
        updatedData["recipe"][4]["setting"][4]["start_date"] = dateCalculation[4][4]["start_date"]
        
        console.log("submit", updatedData)
        setSchedulerData(updatedData)

        // input validation
        let message = inputValidation()
        if (message != null) return alert(message)

        axiosInstance.post(`https://api.cloudatik.com/remote/v1.0/agzui/scheduler/setting/${device_id}`, schedulerData).then(res => {
            console.log("Res submit", res)
            alert('submit successfully')
            getData()
        }).catch(err => {
            console.log("err", err)
        })
    }

    function inputValidation() {
        for (let recipeIndex in schedulerData.recipe) {
            if (schedulerData.recipe[recipeIndex]["location"] == "" || schedulerData.recipe[recipeIndex]["plant"] == "" || schedulerData.recipe[recipeIndex]["season"] == "" ) {
                return `Please fill in all {Location, Plant, Season} at  Block ${Number(recipeIndex)+1}`
            }
            for (let settingData of schedulerData.recipe[recipeIndex].setting) {
                if (settingData["description"] == "") {
                    console.log(settingData, recipeIndex)
                    return `Please fill in all description at Block ${Number(recipeIndex)+1} Stage ${settingData["stage"]}`
                }

                // console.log(settingData["day_growth"])
                if (settingData["day_growth"] === "") {
                    return `Please fill in all Days Growth at Block ${Number(recipeIndex)+1} Stage ${settingData["stage"]}`
                }

                for (let settingData of schedulerData.recipe[recipeIndex].setting) {
                    for (let parameterIndex in settingData["parameter"]) {
                        if (settingData["parameter"][parameterIndex] === "") {
                            // get field
                            let session = parameterIndex.split("_")[0]
                            let field = parameterIndex.split("_")[1]
                            return `Please fill in ${field} at Block ${Number(recipeIndex)+1} Stage ${settingData["stage"]} Session ${session}`
                        }
                    }
                }
            }
        }

        return null
    }

    function formatYYYYMMDDToDDMMYYYY(dateString) {
        // format YYYY-MM-DD to DD/MM/YYYY
        const parts = dateString.split("-")
        return `${parts[2]}/${parts[1]}/${parts[0]}`
    }

    function handleCreateNewRecipe() {
        console.log("handleCreateNewRecipe", blockStep, schedulerData)
        const updatedData = { ...schedulerData }
        updatedData["recipe"][blockStep]["location"] = ""
        updatedData["recipe"][blockStep]["plant"] = ""
        updatedData["recipe"][blockStep]["season"] = ""
        updatedData["recipe"][blockStep]["setting"] = defaultSchedulerBlockData.setting
        setSchedulerData(updatedData)
    }

    function handleSaveNewRecipe() {
        console.log("handleSaveNewRecipe", blockStep, schedulerData.recipe[blockStep].setting)
        let payload = {
            "name": newRecipeName,
            "device": device,
            "payload": {
                "plant": schedulerData.recipe[blockStep]["plant"],
                "location": schedulerData.recipe[blockStep]["location"],
                "season": schedulerData.recipe[blockStep]["season"],
                "setting": [
                    ...schedulerData.recipe[blockStep].setting
                ]
            }
        }
        // input validation
        let message = inputValidation()
        if (message != null) return alert(message)

        axiosInstance.post(`https://api.cloudatik.com/remote/v1.0/agzui/scheduler/recipe/${device}/`, payload).then(res => {
            console.log("Res submit", res)
            alert('submit successfully')
            getData()
            getRecipeList()
            setToggleAddModal(false)
            setNewRecipeName("")
        }).catch(err => {
            console.log("err", err)
            setToggleAddModal(false)
        })
    }

    function handleUpdate() {
        console.log("handleUpdate", selectedRecipe)
        if (selectedRecipe == -1) return alert("please select recipe")

        // get recipe details
        let recipe = recipeList.filter(rec => {return rec.id == selectedRecipe})
        console.log("recipe", recipe[0])

        let payload = {
            "name":  recipe[0].name,
            "device":  device,
            "payload": {
                "plant": schedulerData.recipe[blockStep]["plant"],
                "location": schedulerData.recipe[blockStep]["location"],
                "season": schedulerData.recipe[blockStep]["season"],
                "setting": [
                    ...schedulerData.recipe[blockStep].setting
                ]
            }
        }
        // input validation
        let message = inputValidation()
        if (message != null) return alert(message)
        
        axiosInstance.put(`https://api.cloudatik.com/remote/v1.0/agzui/scheduler/recipe/${device}/${selectedRecipe}`, payload).then(res => {
            console.log("Res submit", res)
            alert('updated successfully')
            getData()
            getRecipeList()
            setToggleAddModal(false)
            setNewRecipeName("")
        }).catch(err => {
            console.log("err", err)
            setToggleAddModal(false)
        })
    }

    function handleChangeRecipe(e) {
        if (e.target.value == -1) return null 
        setSelectedRecipe(e.target.value)
        
        // get recipe details
        let recipe = recipeList.filter(rec => {return rec.id == e.target.value})
        
        let updatedData = {...schedulerData}
        updatedData["recipe"][blockStep]["location"] = recipe[0].payload.location
        updatedData["recipe"][blockStep]["plant"] = recipe[0].payload.plant
        updatedData["recipe"][blockStep]["season"] = recipe[0].payload.season
        updatedData["recipe"][blockStep]["setting"] = recipe[0].payload.setting
        setSchedulerData(updatedData)
    }
    
    function handleDelete() {
        if (selectedRecipe == -1) return alert("please select recipe")
        console.log("handleDelete")

        axiosInstance.delete(`https://api.cloudatik.com/remote/v1.0/agzui/scheduler/recipe/${device}/${selectedRecipe}`).then(res => {
            console.log("Res submit", res)
            alert('deleted successfully')
            setSelectedRecipe(-1)
            handleCreateNewRecipe()
            getData()
            getRecipeList()
            setToggleAddModal(false)
            setNewRecipeName("")
        }).catch(err => {
            console.log("err", err)
            setToggleAddModal(false)
        })
    }
    

    return (
        <>
        <div>
            <div className="row px-4">
                <div className="col-12">
                    <div className="card card-primary">
                        <div className="card-header">
                            <div className="row">
                                <div className="col" style={{textAlign: "center"}} onClick={() => {
                                    if (blockStep < 1) {
                                        setBlockStep(4)
                                    } else {
                                        setBlockStep(blockStep-1)
                                    }
                                 }}>
                                    <div>
                                        <i className="fas fa-arrow-left"></i>
                                    </div>
                                </div>
                                <h3 className="card-title text-center">Block {blockStep+1}</h3>
                                <div className="col" style={{textAlign: "center"}} onClick={() => {
                                    if (blockStep > 3) {
                                        setBlockStep(0)
                                    } else {
                                        setBlockStep(blockStep+1)
                                    }
                                 }}>
                                    <div>
                                        <i className="fas fa-arrow-right"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                            <div className="card-body" style={{width: "100%" }}>
                                
                                <div className="row m-1" style={{justifyContent:"end"}}>
                                    <button type="submit" className="col-6 px-0 btn btn-outline-primary" onClick={()=>setToggleAddModal(true)}>Save as New Recipe</button>
                                    <button type="submit" className="col-6 px-0 btn btn-outline-success" onClick={()=>handleCreateNewRecipe()}>Create New Recipe</button>
                                </div>
                                
                                <div className="row m-1 my-2" style={{justifyContent:"space-between"}}>
                                    <div className="col-12 col-sm-6 mb-1 p-0">
                                        <select className="form-select w-100" style={{height:"100%"}} value={selectedRecipe} onChange={(e)=>{handleChangeRecipe(e)}}>
                                            <option value={-1} key={-1}>Please select Recipe</option>
                                            {recipeList.map(recipe => {
                                                return (
                                                    <option value={recipe.id} key={recipe.id}>{recipe.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-12 col-sm-6 mb-1">
                                        <div className="row">
                                        <button type="submit" className="col-6 btn btn-outline-primary" onClick={()=>handleUpdate()}>Save</button>
                                        <button type="submit" className="col-6 btn btn-outline-warning" onClick={()=>handleDelete()}>Delete</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="form-group col-12 col-md-3">
                                        <label>Location</label>
                                        <input type="text" className="form-control" value={schedulerData.recipe[blockStep].location} onChange={(e)=>{handleInputChange(e, "location", 0)}}/>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="form-group col-12 col-md-3">
                                        <label>Season</label>
                                        <input type="text" className="form-control" value={schedulerData.recipe[blockStep].season} onChange={(e)=>{handleInputChange(e, "season", 0)}}/>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="form-group col-12 col-md-3">
                                        <label>Type of fruit/vege</label>
                                        <input type="text" className="form-control" value={schedulerData.recipe[blockStep].plant} onChange={(e)=>{handleInputChange(e, "plant", 0)}}/>
                                    </div>
                                </div>


                                {isTablet ? (
                                <>
                                <div className="row">
                                    <div className="col" style={{textAlign: "center"}} onClick={() => {
                                        if (stageStep < 1) {
                                            setStageStep(2)
                                        } else {
                                            setStageStep(stageStep-1)
                                        }
                                    }}>
                                        <div>
                                            <i className="fas fa-arrow-left"></i>
                                        </div>
                                    </div>
                                    
                                    <div className="col" style={{textAlign: "center"}}>
                                        <div onClick={() => { 
                                            if (stageStep > 1) {
                                                setStageStep(0)
                                            } else {
                                                setStageStep(stageStep+1)
                                            }
                                        }}>
                                            <i className="fas fa-arrow-right"></i>
                                        </div>
                                    </div>
                                </div>



                                <div className="mb-2">
                                    <div className="text-center mb-2">Growth Stage</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="form-group col" style={{textAlign:"center"}}>
                                                <label>Stage 1</label>
                                            </div>
                                            <div className="form-group col" style={{textAlign:"center"}}>
                                                <label>Stage 2</label>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="form-group col" style={{textAlign:"center"}}>
                                                <label>Stage 3</label>
                                            </div>
                                            <div className="form-group col" style={{textAlign:"center"}}>
                                                <label>Stage 4</label>
                                            </div>
                                            </>

                                        ) : (
                                            <>
                                            <div className="form-group col" style={{textAlign:"center"}}>
                                                <label>Stage 5</label>
                                            </div>
                                            <div className="form-group col" style={{textAlign:"center"}}>
                                                <label>Stage 6</label>
                                            </div>
                                            </>
                                        )
                                        )}
                                    </div>
                                </div>


                                <div className="mb-2">
                                    <div className="text-center mb-3">Stage (Enable/Disable)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[0].status} onChange={(e)=>{handleInputChange(e, "status", 0)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[1].status} onChange={(e)=>{handleInputChange(e, "status", 1)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                            ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[2].status} onChange={(e)=>{handleInputChange(e, "status", 2)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[3].status} onChange={(e)=>{handleInputChange(e, "status", 3)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                            ) : (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[4].status} onChange={(e)=>{handleInputChange(e, "status", 4)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[5].status} onChange={(e)=>{handleInputChange(e, "status", 5)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                            ))}
                                    </div>
                                </div>


                                
                                <div className="mb-2">
                                    <div className="text-center mb-3">Description</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[0].description} onChange={(e)=>{handleInputChange(e, "description", 0)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[1].description} onChange={(e)=>{handleInputChange(e, "description", 1)}}/>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[2].description} onChange={(e)=>{handleInputChange(e, "description", 2)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[3].description} onChange={(e)=>{handleInputChange(e, "description", 3)}}/>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[4].description} onChange={(e)=>{handleInputChange(e, "description", 4)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[5].description} onChange={(e)=>{handleInputChange(e, "description", 5)}}/>
                                            </div>
                                            </>
                                        ))}
                                    </div>
                                </div>


                                
                                <div className="mb-4">
                                    <div className="text-center mb-1">Total Day of Growth</div>
                                    <div className="text-center">
                                        {calculatedTotalDayOfGrowth[blockStep]}
                                    </div>
                                </div>

                                
                                <div className="mb-2">
                                    <div className="text-center mb-3">Start Date</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="date" style={{width: "100%"}} value={schedulerData.recipe[blockStep].setting[0].start_date} onChange={(e)=>{handleInputChange(e, "start_date", 0)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={formatYYYYMMDDToDDMMYYYY(dateCalculation[blockStep][1]["start_date"])}/>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={formatYYYYMMDDToDDMMYYYY(dateCalculation[blockStep][2]["start_date"])}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={formatYYYYMMDDToDDMMYYYY(dateCalculation[blockStep][3]["start_date"])}/>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={formatYYYYMMDDToDDMMYYYY(dateCalculation[blockStep][4]["start_date"])}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={formatYYYYMMDDToDDMMYYYY(dateCalculation[blockStep][5]["start_date"])}/>
                                            </div>
                                            </>

                                        ))}
                                    </div>
                                </div>


                                
                                <div className="mb-2">
                                    <div className="text-center mb-3">End of each growth Stage</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={formatYYYYMMDDToDDMMYYYY(dateCalculation[blockStep][0]["end_date"])}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={formatYYYYMMDDToDDMMYYYY(dateCalculation[blockStep][1]["end_date"])}/>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={formatYYYYMMDDToDDMMYYYY(dateCalculation[blockStep][2]["end_date"])}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={formatYYYYMMDDToDDMMYYYY(dateCalculation[blockStep][3]["end_date"])}/>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={formatYYYYMMDDToDDMMYYYY(dateCalculation[blockStep][4]["end_date"])}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={formatYYYYMMDDToDDMMYYYY(dateCalculation[blockStep][5]["end_date"])}/>
                                            </div>
                                            </>

                                        ))}
                                    </div>
                                </div>

                                
                                <div className="mb-2">
                                    <div className="text-center mb-3">Days of growth</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[0].day_growth} onChange={(e)=>{handleInputChange(e, "day_growth", 0)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[1].day_growth} onChange={(e)=>{handleInputChange(e, "day_growth", 1)}}/>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[2].day_growth} onChange={(e)=>{handleInputChange(e, "day_growth", 2)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[3].day_growth} onChange={(e)=>{handleInputChange(e, "day_growth", 3)}}/>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[4].day_growth} onChange={(e)=>{handleInputChange(e, "day_growth", 4)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[5].day_growth} onChange={(e)=>{handleInputChange(e, "day_growth", 5)}}/>
                                            </div>
                                            </>

                                        ))}
                                    </div>
                                </div>

                                <hr/>



                                <div className="mb-2">
                                    <div className="text-center mb-3">Block Setting</div>
                                </div>


                                <div className="mb-2">
                                    <div className="text-center mb-3">Session 1 (Enable/Disable)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[0].parameter["session1_state"]} onChange={(e)=>{handleSessionInputChange(e, "session1_state", 0)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[1].parameter["session1_state"]} onChange={(e)=>{handleSessionInputChange(e, "session1_state", 1)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[2].parameter["session1_state"]} onChange={(e)=>{handleSessionInputChange(e, "session1_state", 2)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[3].parameter["session1_state"]} onChange={(e)=>{handleSessionInputChange(e, "session1_state", 3)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[4].parameter["session1_state"]} onChange={(e)=>{handleSessionInputChange(e, "session1_state", 4)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[5].parameter["session1_state"]} onChange={(e)=>{handleSessionInputChange(e, "session1_state", 5)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>

                                        ))}
                                    </div>
                                </div>


                                
                                <div className="mb-2">
                                    <div className="text-center mb-3">Supply (Water/Fertilizer)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[0].parameter["session1_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session1_medium", 0)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[1].parameter["session1_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session1_medium", 1)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[2].parameter["session1_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session1_medium", 2)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[3].parameter["session1_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session1_medium", 3)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[4].parameter["session1_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session1_medium", 4)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[5].parameter["session1_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session1_medium", 5)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ))}
                                    </div>
                                </div>

                                
                                <div className="mb-2">
                                    <div className="text-center mb-3">Duration (min)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[0].parameter["session1_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session1_duration", 0)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[1].parameter["session1_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session1_duration", 1)}}/>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[2].parameter["session1_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session1_duration", 2)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[3].parameter["session1_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session1_duration", 3)}}/>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[4].parameter["session1_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session1_duration", 4)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[5].parameter["session1_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session1_duration", 5)}}/>
                                            </div>
                                            </>

                                        ))}
                                    </div>
                                </div>


                                <div className="mb-2">
                                    <div className="text-center mb-3">Cycle</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[0].parameter["session1_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session1_cycle", 0)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[1].parameter["session1_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session1_cycle", 1)}}/>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[2].parameter["session1_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session1_cycle", 2)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[3].parameter["session1_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session1_cycle", 3)}}/>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[4].parameter["session1_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session1_cycle", 4)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[5].parameter["session1_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session1_cycle", 5)}}/>
                                            </div>
                                            </>

                                        ))}
                                    </div>
                                </div>


                                <div className="mb-2">
                                    <div className="text-center mb-3">Total duration (min)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={totalCalculation[blockStep][0]["session1_total_duration"]}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={totalCalculation[blockStep][1]["session1_total_duration"]}/>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={totalCalculation[blockStep][2]["session1_total_duration"]}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={totalCalculation[blockStep][3]["session1_total_duration"]}/>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={totalCalculation[blockStep][4]["session1_total_duration"]}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={totalCalculation[blockStep][5]["session1_total_duration"]}/>
                                            </div>
                                            </>

                                        ))}
                                    </div>
                                </div>

                                <hr/>

                                
                                <>
                                <div className="mb-2">
                                    <div className="text-center mb-3">Session 2 (Enable/Disable)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[0].parameter["session2_state"]} onChange={(e)=>{handleSessionInputChange(e, "session2_state", 0)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[1].parameter["session2_state"]} onChange={(e)=>{handleSessionInputChange(e, "session2_state", 1)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[2].parameter["session2_state"]} onChange={(e)=>{handleSessionInputChange(e, "session2_state", 2)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[3].parameter["session2_state"]} onChange={(e)=>{handleSessionInputChange(e, "session2_state", 3)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[4].parameter["session2_state"]} onChange={(e)=>{handleSessionInputChange(e, "session2_state", 4)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[5].parameter["session2_state"]} onChange={(e)=>{handleSessionInputChange(e, "session2_state", 5)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>

                                        ))}
                                    </div>
                                </div>


                                
                                <div className="mb-2">
                                    <div className="text-center mb-3">Supply (Water/Fertilizer)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[0].parameter["session2_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session2_medium", 0)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[1].parameter["session2_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session2_medium", 1)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[2].parameter["session2_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session2_medium", 2)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[3].parameter["session2_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session2_medium", 3)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[4].parameter["session2_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session2_medium", 4)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[5].parameter["session2_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session2_medium", 5)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ))}
                                    </div>
                                </div>

                                
                                <div className="mb-2">
                                    <div className="text-center mb-3">Duration (min)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[0].parameter["session2_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session2_duration", 0)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[1].parameter["session2_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session2_duration", 1)}}/>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[2].parameter["session2_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session2_duration", 2)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[3].parameter["session2_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session2_duration", 3)}}/>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[4].parameter["session2_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session2_duration", 4)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[5].parameter["session2_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session2_duration", 5)}}/>
                                            </div>
                                            </>

                                        ))}
                                    </div>
                                </div>


                                <div className="mb-2">
                                    <div className="text-center mb-3">Cycle</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[0].parameter["session2_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session2_cycle", 0)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[1].parameter["session2_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session2_cycle", 1)}}/>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[2].parameter["session2_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session2_cycle", 2)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[3].parameter["session2_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session2_cycle", 3)}}/>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[4].parameter["session2_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session2_cycle", 4)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[5].parameter["session2_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session2_cycle", 5)}}/>
                                            </div>
                                            </>

                                        ))}
                                    </div>
                                </div>


                                <div className="mb-2">
                                    <div className="text-center mb-3">Total duration (min)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={totalCalculation[blockStep][0]["session2_total_duration"]}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={totalCalculation[blockStep][1]["session2_total_duration"]}/>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={totalCalculation[blockStep][2]["session2_total_duration"]}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={totalCalculation[blockStep][3]["session2_total_duration"]}/>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={totalCalculation[blockStep][4]["session2_total_duration"]}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={totalCalculation[blockStep][5]["session2_total_duration"]}/>
                                            </div>
                                            </>

                                        ))}
                                    </div>
                                </div>
                                </>

                                <hr/>

                                <>
                                <div className="mb-2">
                                    <div className="text-center mb-3">Session 3 (Enable/Disable)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[0].parameter["session3_state"]} onChange={(e)=>{handleSessionInputChange(e, "session3_state", 0)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[1].parameter["session3_state"]} onChange={(e)=>{handleSessionInputChange(e, "session3_state", 1)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[2].parameter["session3_state"]} onChange={(e)=>{handleSessionInputChange(e, "session3_state", 2)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[3].parameter["session3_state"]} onChange={(e)=>{handleSessionInputChange(e, "session3_state", 3)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[4].parameter["session3_state"]} onChange={(e)=>{handleSessionInputChange(e, "session3_state", 4)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[5].parameter["session3_state"]} onChange={(e)=>{handleSessionInputChange(e, "session3_state", 5)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ))}
                                    </div>
                                </div>


                                
                                <div className="mb-2">
                                    <div className="text-center mb-3">Supply (Water/Fertilizer)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[0].parameter["session3_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session3_medium", 0)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[1].parameter["session3_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session3_medium", 1)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[2].parameter["session3_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session3_medium", 2)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[3].parameter["session3_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session3_medium", 3)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[4].parameter["session3_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session3_medium", 4)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[5].parameter["session3_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session3_medium", 5)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ))}
                                    </div>
                                </div>

                                
                                <div className="mb-2">
                                    <div className="text-center mb-3">Duration (min)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[0].parameter["session3_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session3_duration", 0)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[1].parameter["session3_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session3_duration", 1)}}/>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[2].parameter["session3_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session3_duration", 2)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[3].parameter["session3_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session3_duration", 3)}}/>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[4].parameter["session3_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session3_duration", 4)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[5].parameter["session3_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session3_duration", 5)}}/>
                                            </div>
                                            </>
                                        ))}
                                    </div>
                                </div>


                                <div className="mb-2">
                                    <div className="text-center mb-3">Cycle</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[0].parameter["session3_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session3_cycle", 0)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[1].parameter["session3_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session3_cycle", 1)}}/>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[2].parameter["session3_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session3_cycle", 2)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[3].parameter["session3_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session3_cycle", 3)}}/>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[4].parameter["session3_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session3_cycle", 4)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[5].parameter["session3_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session3_cycle", 5)}}/>
                                            </div>
                                            </>
                                        ))}
                                    </div>
                                </div>


                                <div className="mb-2">
                                    <div className="text-center mb-3">Total duration (min)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={totalCalculation[blockStep][0]["session3_total_duration"]}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={totalCalculation[blockStep][1]["session3_total_duration"]}/>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={totalCalculation[blockStep][2]["session3_total_duration"]}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={totalCalculation[blockStep][3]["session3_total_duration"]}/>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={totalCalculation[blockStep][4]["session3_total_duration"]}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" disabled value={totalCalculation[blockStep][5]["session3_total_duration"]}/>
                                            </div>
                                            </>
                                        ))}
                                    </div>
                                </div>
                                </>

                                <hr/>

                                <>
                                <div className="mb-2">
                                    <div className="text-center mb-3">Session 4 (Enable/Disable)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[0].parameter["session4_state"]} onChange={(e)=>{handleSessionInputChange(e, "session4_state", 0)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[1].parameter["session4_state"]} onChange={(e)=>{handleSessionInputChange(e, "session4_state", 1)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[2].parameter["session4_state"]} onChange={(e)=>{handleSessionInputChange(e, "session4_state", 2)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[3].parameter["session4_state"]} onChange={(e)=>{handleSessionInputChange(e, "session4_state", 3)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[4].parameter["session4_state"]} onChange={(e)=>{handleSessionInputChange(e, "session4_state", 4)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[5].parameter["session4_state"]} onChange={(e)=>{handleSessionInputChange(e, "session4_state", 5)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ))}
                                    </div>
                                </div>


                                
                                <div className="mb-2">
                                    <div className="text-center mb-3">Supply (Water/Fertilizer)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[0].parameter["session4_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session4_medium", 0)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[1].parameter["session4_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session4_medium", 1)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[2].parameter["session4_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session4_medium", 2)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[3].parameter["session4_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session4_medium", 3)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[4].parameter["session4_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session4_medium", 4)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[5].parameter["session4_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session4_medium", 5)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ))}
                                    </div>
                                </div>

                                
                                <div className="mb-2">
                                    <div className="text-center mb-3">Duration (min)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[0].parameter["session4_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session4_duration", 0)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[1].parameter["session4_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session4_duration", 1)}}/>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[2].parameter["session4_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session4_duration", 2)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[3].parameter["session4_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session4_duration", 3)}}/>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[4].parameter["session4_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session4_duration", 4)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[5].parameter["session4_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session4_duration", 5)}}/>
                                            </div>
                                            </>
                                        ))}
                                    </div>
                                </div>


                                <div className="mb-2">
                                    <div className="text-center mb-3">Radiation (Joule)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[0].parameter["session4_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session4_radiation", 0)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[1].parameter["session4_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session4_radiation", 1)}}/>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[2].parameter["session4_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session4_radiation", 2)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[3].parameter["session4_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session4_radiation", 3)}}/>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[4].parameter["session4_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session4_radiation", 4)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[5].parameter["session4_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session4_radiation", 5)}}/>
                                            </div>
                                            </>
                                        ))}
                                    </div>
                                </div>

                                </>


                                <hr/>

                                <>
                                <div className="mb-2">
                                    <div className="text-center mb-3">Session 5 (Enable/Disable)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[0].parameter["session5_state"]} onChange={(e)=>{handleSessionInputChange(e, "session5_state", 0)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[1].parameter["session5_state"]} onChange={(e)=>{handleSessionInputChange(e, "session5_state", 1)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[2].parameter["session5_state"]} onChange={(e)=>{handleSessionInputChange(e, "session5_state", 2)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[3].parameter["session5_state"]} onChange={(e)=>{handleSessionInputChange(e, "session5_state", 3)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[4].parameter["session5_state"]} onChange={(e)=>{handleSessionInputChange(e, "session5_state", 4)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[5].parameter["session5_state"]} onChange={(e)=>{handleSessionInputChange(e, "session5_state", 5)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ))}
                                    </div>
                                </div>


                                
                                <div className="mb-2">
                                    <div className="text-center mb-3">Supply (Water/Fertilizer)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[0].parameter["session5_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session5_medium", 0)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[1].parameter["session5_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session5_medium", 1)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[2].parameter["session5_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session5_medium", 2)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[3].parameter["session5_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session5_medium", 3)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[4].parameter["session5_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session5_medium", 4)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[5].parameter["session5_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session5_medium", 5)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ))}
                                    </div>
                                </div>

                                
                                <div className="mb-2">
                                    <div className="text-center mb-3">Duration (min)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[0].parameter["session5_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session5_duration", 0)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[1].parameter["session5_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session5_duration", 1)}}/>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[2].parameter["session5_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session5_duration", 2)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[3].parameter["session5_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session5_duration", 3)}}/>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[4].parameter["session5_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session5_duration", 4)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[5].parameter["session5_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session5_duration", 5)}}/>
                                            </div>
                                            </>
                                        ))}
                                    </div>
                                </div>


                                <div className="mb-2">
                                    <div className="text-center mb-3">Radiation (Joule)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[0].parameter["session5_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session5_radiation", 0)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[1].parameter["session5_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session5_radiation", 1)}}/>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[2].parameter["session5_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session5_radiation", 2)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[3].parameter["session5_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session5_radiation", 3)}}/>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[4].parameter["session5_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session5_radiation", 4)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[5].parameter["session5_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session5_radiation", 5)}}/>
                                            </div>
                                            </>
                                        ))}
                                    </div>
                                </div>

                                </>


                                <hr/>

                                <>
                                <div className="mb-2">
                                    <div className="text-center mb-3">Session 6 (Enable/Disable)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" value={schedulerData.recipe[blockStep].setting[0].parameter["session6_state"]} onChange={(e)=>{handleSessionInputChange(e, "session6_state", 0)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" value={schedulerData.recipe[blockStep].setting[1].parameter["session6_state"]} onChange={(e)=>{handleSessionInputChange(e, "session6_state", 1)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" value={schedulerData.recipe[blockStep].setting[2].parameter["session6_state"]} onChange={(e)=>{handleSessionInputChange(e, "session6_state", 2)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" value={schedulerData.recipe[blockStep].setting[3].parameter["session6_state"]} onChange={(e)=>{handleSessionInputChange(e, "session6_state", 3)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" value={schedulerData.recipe[blockStep].setting[4].parameter["session6_state"]} onChange={(e)=>{handleSessionInputChange(e, "session6_state", 4)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" value={schedulerData.recipe[blockStep].setting[5].parameter["session6_state"]} onChange={(e)=>{handleSessionInputChange(e, "session6_state", 5)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ))}
                                    </div>
                                </div>


                                
                                <div className="mb-2">
                                    <div className="text-center mb-3">Supply (Water/Fertilizer)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" value={schedulerData.recipe[blockStep].setting[0].parameter["session6_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session6_medium", 0)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" value={schedulerData.recipe[blockStep].setting[1].parameter["session6_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session6_medium", 1)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" value={schedulerData.recipe[blockStep].setting[2].parameter["session6_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session6_medium", 2)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" value={schedulerData.recipe[blockStep].setting[3].parameter["session6_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session6_medium", 3)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" value={schedulerData.recipe[blockStep].setting[4].parameter["session6_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session6_medium", 4)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                <label className="switch">
                                                    <input type="checkbox" value={schedulerData.recipe[blockStep].setting[5].parameter["session6_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session6_medium", 5)}}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            </>
                                        ))}
                                    </div>
                                </div>

                                
                                <div className="mb-2">
                                    <div className="text-center mb-3">Duration (min)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[0].parameter["session6_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session6_duration", 0)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[1].parameter["session6_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session6_duration", 1)}}/>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[2].parameter["session6_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session6_duration", 2)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[3].parameter["session6_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session6_duration", 3)}}/>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[4].parameter["session6_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session6_duration", 4)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[5].parameter["session6_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session6_duration", 5)}}/>
                                            </div>
                                            </>
                                        ))}
                                    </div>
                                </div>


                                <div className="mb-2">
                                    <div className="text-center mb-3">Radiation (Joule)</div>
                                    <div className="row px-2">
                                        {stageStep == 1 ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[0].parameter["session6_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session6_radiation", 0)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[1].parameter["session6_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session6_radiation", 1)}}/>
                                            </div>
                                            </>
                                        ) : ( (stageStep == 2) ? (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[2].parameter["session6_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session6_radiation", 2)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[3].parameter["session6_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session6_radiation", 3)}}/>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[4].parameter["session6_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session6_radiation", 4)}}/>
                                            </div>
                                            <div className="form-group col">
                                                <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[5].parameter["session6_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session6_radiation", 5)}}/>
                                            </div>
                                            </>
                                        ))}
                                    </div>
                                </div>

                                </>
                                </>
                                ) : (
                                    <>
                                    <div className="mb-2">
                                        <div className="text-center mb-2">Growth Stage</div>
                                        <div className="row px-2">
                                            
                                                <>
                                                <div className="form-group col" style={{textAlign:"center"}}>
                                                    <label>Stage 1</label>
                                                </div>
                                                <div className="form-group col" style={{textAlign:"center"}}>
                                                    <label>Stage 2</label>
                                                </div>
                                                </>
                                            
                                                <>
                                                <div className="form-group col" style={{textAlign:"center"}}>
                                                    <label>Stage 3</label>
                                                </div>
                                                <div className="form-group col" style={{textAlign:"center"}}>
                                                    <label>Stage 4</label>
                                                </div>
                                                </>
                                            
                                                <>
                                                <div className="form-group col" style={{textAlign:"center"}}>
                                                    <label>Stage 5</label>
                                                </div>
                                                <div className="form-group col" style={{textAlign:"center"}}>
                                                    <label>Stage 6</label>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Stage (Enable/Disable)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[0].status} onChange={(e)=>{handleInputChange(e, "status", 0)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[1].status} onChange={(e)=>{handleInputChange(e, "status", 1)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[2].status} onChange={(e)=>{handleInputChange(e, "status", 2)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[3].status} onChange={(e)=>{handleInputChange(e, "status", 3)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[4].status} onChange={(e)=>{handleInputChange(e, "status", 4)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[5].status} onChange={(e)=>{handleInputChange(e, "status", 5)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
    
                                    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Description</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[0].description} onChange={(e)=>{handleInputChange(e, "description", 0)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[1].description} onChange={(e)=>{handleInputChange(e, "description", 1)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[2].description} onChange={(e)=>{handleInputChange(e, "description", 2)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[3].description} onChange={(e)=>{handleInputChange(e, "description", 3)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[4].description} onChange={(e)=>{handleInputChange(e, "description", 4)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[5].description} onChange={(e)=>{handleInputChange(e, "description", 5)}}/>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
    
                                    
                                    <div className="mb-4">
                                        <div className="text-center mb-1">Total Day of Growth</div>
                                        <div className="text-center">
                                            {calculatedTotalDayOfGrowth[blockStep]}
                                        </div>
                                    </div>
    
                                    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Start Date</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="form-group col">
                                                    <input type="date" style={{width: "100%"}}   value={schedulerData.recipe[blockStep].setting[0].start_date} onChange={(e)=>{handleInputChange(e, "start_date", 0)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={formatYYYYMMDDToDDMMYYYY(dateCalculation[blockStep][1]["start_date"])}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={formatYYYYMMDDToDDMMYYYY(dateCalculation[blockStep][2]["start_date"])}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={formatYYYYMMDDToDDMMYYYY(dateCalculation[blockStep][3]["start_date"])}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={formatYYYYMMDDToDDMMYYYY(dateCalculation[blockStep][4]["start_date"])}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={formatYYYYMMDDToDDMMYYYY(dateCalculation[blockStep][5]["start_date"])}/>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
    
                                    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">End of each growth Stage</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={formatYYYYMMDDToDDMMYYYY(dateCalculation[blockStep][0]["end_date"])}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={formatYYYYMMDDToDDMMYYYY(dateCalculation[blockStep][1]["end_date"])}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={formatYYYYMMDDToDDMMYYYY(dateCalculation[blockStep][2]["end_date"])}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={formatYYYYMMDDToDDMMYYYY(dateCalculation[blockStep][3]["end_date"])}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={formatYYYYMMDDToDDMMYYYY(dateCalculation[blockStep][4]["end_date"])}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={formatYYYYMMDDToDDMMYYYY(dateCalculation[blockStep][5]["end_date"])}/>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
                                    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Days of growth</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[0].day_growth} onChange={(e)=>{handleInputChange(e, "day_growth", 0)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[1].day_growth} onChange={(e)=>{handleInputChange(e, "day_growth", 1)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[2].day_growth} onChange={(e)=>{handleInputChange(e, "day_growth", 2)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[3].day_growth} onChange={(e)=>{handleInputChange(e, "day_growth", 3)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[4].day_growth} onChange={(e)=>{handleInputChange(e, "day_growth", 4)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[5].day_growth} onChange={(e)=>{handleInputChange(e, "day_growth", 5)}}/>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
                                    <hr/>
    
    
    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Block Setting</div>
                                    </div>
    
    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Session 1 (Enable/Disable)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[0].parameter["session1_state"]} onChange={(e)=>{handleSessionInputChange(e, "session1_state", 0)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[1].parameter["session1_state"]} onChange={(e)=>{handleSessionInputChange(e, "session1_state", 1)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[2].parameter["session1_state"]} onChange={(e)=>{handleSessionInputChange(e, "session1_state", 2)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[3].parameter["session1_state"]} onChange={(e)=>{handleSessionInputChange(e, "session1_state", 3)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[4].parameter["session1_state"]} onChange={(e)=>{handleSessionInputChange(e, "session1_state", 4)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[5].parameter["session1_state"]} onChange={(e)=>{handleSessionInputChange(e, "session1_state", 5)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
    
                                    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Supply (Water/Fertilizer)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[0].parameter["session1_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session1_medium", 0)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[1].parameter["session1_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session1_medium", 1)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[2].parameter["session1_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session1_medium", 2)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[3].parameter["session1_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session1_medium", 3)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[4].parameter["session1_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session1_medium", 4)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[5].parameter["session1_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session1_medium", 5)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
                                    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Duration (min)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[0].parameter["session1_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session1_duration", 0)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[1].parameter["session1_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session1_duration", 1)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[2].parameter["session1_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session1_duration", 2)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[3].parameter["session1_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session1_duration", 3)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[4].parameter["session1_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session1_duration", 4)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[5].parameter["session1_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session1_duration", 5)}}/>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Cycle</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[0].parameter["session1_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session1_cycle", 0)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[1].parameter["session1_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session1_cycle", 1)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[2].parameter["session1_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session1_cycle", 2)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[3].parameter["session1_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session1_cycle", 3)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[4].parameter["session1_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session1_cycle", 4)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[5].parameter["session1_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session1_cycle", 5)}}/>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Total duration (min)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={totalCalculation[blockStep][0]["session1_total_duration"]}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={totalCalculation[blockStep][1]["session1_total_duration"]}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={totalCalculation[blockStep][2]["session1_total_duration"]}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={totalCalculation[blockStep][3]["session1_total_duration"]}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={totalCalculation[blockStep][4]["session1_total_duration"]}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={totalCalculation[blockStep][5]["session1_total_duration"]}/>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
                                    <hr/>
    
                                    
                                    <>
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Session 2 (Enable/Disable)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[0].parameter["session2_state"]} onChange={(e)=>{handleSessionInputChange(e, "session2_state", 0)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[1].parameter["session2_state"]} onChange={(e)=>{handleSessionInputChange(e, "session2_state", 1)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[2].parameter["session2_state"]} onChange={(e)=>{handleSessionInputChange(e, "session2_state", 2)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[3].parameter["session2_state"]} onChange={(e)=>{handleSessionInputChange(e, "session2_state", 3)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[4].parameter["session2_state"]} onChange={(e)=>{handleSessionInputChange(e, "session2_state", 4)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[5].parameter["session2_state"]} onChange={(e)=>{handleSessionInputChange(e, "session2_state", 5)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
    
                                    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Supply (Water/Fertilizer)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[0].parameter["session2_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session2_medium", 0)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[1].parameter["session2_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session2_medium", 1)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[2].parameter["session2_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session2_medium", 2)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[3].parameter["session2_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session2_medium", 3)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[4].parameter["session2_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session2_medium", 4)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[5].parameter["session2_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session2_medium", 5)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
                                    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Duration (min)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[0].parameter["session2_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session2_duration", 0)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[1].parameter["session2_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session2_duration", 1)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[2].parameter["session2_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session2_duration", 2)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[3].parameter["session2_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session2_duration", 3)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[4].parameter["session2_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session2_duration", 4)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[5].parameter["session2_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session2_duration", 5)}}/>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Cycle</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[0].parameter["session2_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session2_cycle", 0)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[1].parameter["session2_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session2_cycle", 1)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[2].parameter["session2_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session2_cycle", 2)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[3].parameter["session2_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session2_cycle", 3)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[4].parameter["session2_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session2_cycle", 4)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control"  value={schedulerData.recipe[blockStep].setting[5].parameter["session2_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session2_cycle", 5)}}/>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Total duration (min)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={totalCalculation[blockStep][0]["session2_total_duration"]}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={totalCalculation[blockStep][1]["session2_total_duration"]}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={totalCalculation[blockStep][2]["session2_total_duration"]}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={totalCalculation[blockStep][3]["session2_total_duration"]}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={totalCalculation[blockStep][4]["session2_total_duration"]}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={totalCalculation[blockStep][5]["session2_total_duration"]}/>
                                                </div>
                                                </>
                                        </div>
                                    </div>
                                    </>
    
                                    <hr/>
    
                                    <>
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Session 3 (Enable/Disable)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[0].parameter["session3_state"]} onChange={(e)=>{handleSessionInputChange(e, "session3_state", 0)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[1].parameter["session3_state"]} onChange={(e)=>{handleSessionInputChange(e, "session3_state", 1)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[2].parameter["session3_state"]} onChange={(e)=>{handleSessionInputChange(e, "session3_state", 2)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[3].parameter["session3_state"]} onChange={(e)=>{handleSessionInputChange(e, "session3_state", 3)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[4].parameter["session3_state"]} onChange={(e)=>{handleSessionInputChange(e, "session3_state", 4)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[5].parameter["session3_state"]} onChange={(e)=>{handleSessionInputChange(e, "session3_state", 5)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
    
                                    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Supply (Water/Fertilizer)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[0].parameter["session3_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session3_medium", 0)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[1].parameter["session3_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session3_medium", 1)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[2].parameter["session3_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session3_medium", 2)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[3].parameter["session3_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session3_medium", 3)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[4].parameter["session3_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session3_medium", 4)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[5].parameter["session3_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session3_medium", 5)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
                                    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Duration (min)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[0].parameter["session3_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session3_duration", 0)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[1].parameter["session3_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session3_duration", 1)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[2].parameter["session3_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session3_duration", 2)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[3].parameter["session3_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session3_duration", 3)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[4].parameter["session3_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session3_duration", 4)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[5].parameter["session3_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session3_duration", 5)}}/>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Cycle</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[0].parameter["session3_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session3_cycle", 0)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[1].parameter["session3_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session3_cycle", 1)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[2].parameter["session3_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session3_cycle", 2)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[3].parameter["session3_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session3_cycle", 3)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[4].parameter["session3_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session3_cycle", 4)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[5].parameter["session3_cycle"]} onChange={(e)=>{handleSessionInputChange(e, "session3_cycle", 5)}}/>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Total duration (min)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={totalCalculation[blockStep][0]["session3_total_duration"]}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={totalCalculation[blockStep][1]["session3_total_duration"]}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={totalCalculation[blockStep][2]["session3_total_duration"]}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={totalCalculation[blockStep][3]["session3_total_duration"]}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={totalCalculation[blockStep][4]["session3_total_duration"]}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" disabled value={totalCalculation[blockStep][5]["session3_total_duration"]}/>
                                                </div>
                                                </>
                                        </div>
                                    </div>
                                    </>
    
                                    <hr/>
    
                                    <>
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Session 4 (Enable/Disable)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[0].parameter["session4_state"]} onChange={(e)=>{handleSessionInputChange(e, "session4_state", 0)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[1].parameter["session4_state"]} onChange={(e)=>{handleSessionInputChange(e, "session4_state", 1)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[2].parameter["session4_state"]} onChange={(e)=>{handleSessionInputChange(e, "session4_state", 2)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[3].parameter["session4_state"]} onChange={(e)=>{handleSessionInputChange(e, "session4_state", 3)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[4].parameter["session4_state"]} onChange={(e)=>{handleSessionInputChange(e, "session4_state", 4)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[5].parameter["session4_state"]} onChange={(e)=>{handleSessionInputChange(e, "session4_state", 5)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
    
                                    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Supply (Water/Fertilizer)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[0].parameter["session4_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session4_medium", 0)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[1].parameter["session4_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session4_medium", 1)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[2].parameter["session4_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session4_medium", 2)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[3].parameter["session4_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session4_medium", 3)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[4].parameter["session4_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session4_medium", 4)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[5].parameter["session4_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session4_medium", 5)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
                                    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Duration (min)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[0].parameter["session4_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session4_duration", 0)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[1].parameter["session4_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session4_duration", 1)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[2].parameter["session4_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session4_duration", 2)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[3].parameter["session4_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session4_duration", 3)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[4].parameter["session4_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session4_duration", 4)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[5].parameter["session4_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session4_duration", 5)}}/>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Radiation (Joule)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[0].parameter["session4_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session4_radiation", 0)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[1].parameter["session4_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session4_radiation", 1)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[2].parameter["session4_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session4_radiation", 2)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[3].parameter["session4_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session4_radiation", 3)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[4].parameter["session4_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session4_radiation", 4)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[5].parameter["session4_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session4_radiation", 5)}}/>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
                                    </>
    
    
                                    <hr/>
    
                                    <>
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Session 5 (Enable/Disable)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[0].parameter["session5_state"]} onChange={(e)=>{handleSessionInputChange(e, "session5_state", 0)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[1].parameter["session5_state"]} onChange={(e)=>{handleSessionInputChange(e, "session5_state", 1)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[2].parameter["session5_state"]} onChange={(e)=>{handleSessionInputChange(e, "session5_state", 2)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[3].parameter["session5_state"]} onChange={(e)=>{handleSessionInputChange(e, "session5_state", 3)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[4].parameter["session5_state"]} onChange={(e)=>{handleSessionInputChange(e, "session5_state", 4)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[5].parameter["session5_state"]} onChange={(e)=>{handleSessionInputChange(e, "session5_state", 5)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
    
                                    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Supply (Water/Fertilizer)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[0].parameter["session5_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session5_medium", 0)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[1].parameter["session5_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session5_medium", 1)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[2].parameter["session5_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session5_medium", 2)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[3].parameter["session5_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session5_medium", 3)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[4].parameter["session5_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session5_medium", 4)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[5].parameter["session5_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session5_medium", 5)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
                                    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Duration (min)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[0].parameter["session5_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session5_duration", 0)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[1].parameter["session5_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session5_duration", 1)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[2].parameter["session5_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session5_duration", 2)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[3].parameter["session5_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session5_duration", 3)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[4].parameter["session5_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session5_duration", 4)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[5].parameter["session5_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session5_duration", 5)}}/>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Radiation (Joule)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[0].parameter["session5_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session5_radiation", 0)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[1].parameter["session5_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session5_radiation", 1)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[2].parameter["session5_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session5_radiation", 2)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[3].parameter["session5_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session5_radiation", 3)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[4].parameter["session5_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session5_radiation", 4)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[5].parameter["session5_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session5_radiation", 5)}}/>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
                                    </>
    
    
                                    <hr/>
    
                                    <>
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Session 6 (Enable/Disable)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[0].parameter["session6_state"]} onChange={(e)=>{handleSessionInputChange(e, "session6_state", 0)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[1].parameter["session6_state"]} onChange={(e)=>{handleSessionInputChange(e, "session6_state", 1)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[2].parameter["session6_state"]} onChange={(e)=>{handleSessionInputChange(e, "session6_state", 2)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[3].parameter["session6_state"]} onChange={(e)=>{handleSessionInputChange(e, "session6_state", 3)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[4].parameter["session6_state"]} onChange={(e)=>{handleSessionInputChange(e, "session6_state", 4)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[5].parameter["session6_state"]} onChange={(e)=>{handleSessionInputChange(e, "session6_state", 5)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
    
                                    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Supply (Water/Fertilizer)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[0].parameter["session6_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session6_medium", 0)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[1].parameter["session6_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session6_medium", 1)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[2].parameter["session6_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session6_medium", 2)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[3].parameter["session6_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session6_medium", 3)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                                <>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[4].parameter["session6_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session6_medium", 4)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col" style={{alignSelf: "center", textAlign:"center"}}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={schedulerData.recipe[blockStep].setting[5].parameter["session6_medium"]} onChange={(e)=>{handleSessionInputChange(e, "session6_medium", 5)}}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
                                    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Duration (min)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[0].parameter["session6_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session6_duration", 0)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[1].parameter["session6_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session6_duration", 1)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[2].parameter["session6_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session6_duration", 2)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[3].parameter["session6_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session6_duration", 3)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[4].parameter["session6_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session6_duration", 4)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[5].parameter["session6_duration"]} onChange={(e)=>{handleSessionInputChange(e, "session6_duration", 5)}}/>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
    
                                    <div className="mb-2">
                                        <div className="text-center mb-3">Radiation (Joule)</div>
                                        <div className="row px-2">
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[0].parameter["session6_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session6_radiation", 0)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[1].parameter["session6_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session6_radiation", 1)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[2].parameter["session6_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session6_radiation", 2)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[3].parameter["session6_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session6_radiation", 3)}}/>
                                                </div>
                                                </>
                                                <>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[4].parameter["session6_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session6_radiation", 4)}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <input type="text" className="form-control" value={schedulerData.recipe[blockStep].setting[5].parameter["session6_radiation"]} onChange={(e)=>{handleSessionInputChange(e, "session6_radiation", 5)}}/>
                                                </div>
                                                </>
                                        </div>
                                    </div>
    
                                    </>
                                    </>
                                )}

                            </div>
                            

                            <div className="card-footer">
                                <button type="submit" className="btn btn-primary float-right" onClick={()=>{handleSubmit()}}>Submit</button>
                            </div>
                    </div>
                    

                </div>
            </div>

            <Dialog open={toggleAddModal} maxWidth="md" fullWidth={true}>
                <DialogTitle>Create New Recipe</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Enter new Recipe Name
                </DialogContentText>
                <TextField type='text' autoFocus id="outlined-basic" fullWidth label="Recipe Name" variant="standard" value={newRecipeName} onChange={(e)=>{ setNewRecipeName(e.target.value) }}/>
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>setToggleAddModal(false)}>Cancel</Button>
                <Button onClick={()=>handleSaveNewRecipe()}>Create</Button>
                </DialogActions>
            </Dialog>
        </div>
        </>
    )
}
