import React, { useState, useEffect, Fragment } from "react";

import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import profile from "../services/profile";
import LoadingSpinner from "../shared/Spinner/LoadingSpinner";
import { errorToast, successToast } from "../shared/notification";

const moment = require("moment-timezone");

function Navbar({ logo }) {
	const [dropdownOpenUser, setDropdownOpenUser] = useState(false);
	const toggleUser = () => setDropdownOpenUser((prevState) => !prevState);

	const [modalUser, setModalUser] = useState(false);
	const toggleModalUser = () => setModalUser(!modalUser);

	const [modalPassword, setModalPassword] = useState(false);
	const toggleModalPassword = () => setModalPassword(!modalPassword);

	const [profileUsername, setProfileUsername] = useState("");
	const [profileCompany, setProfileCompany] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const [loading, setLoading] = useState(false);

	function updateProfile() {
		let dataToSubmit = {
			username: profileUsername,
			password: password,
			new_password: confirmPassword,
		};

		if (password === confirmPassword) {
			setLoading(true);
			profile
				.getInstance()
				.updateProfileDetails(dataToSubmit)
				.then((result) => {
					successToast(result["message"]);
					setModalPassword(false);
				});
			// fetch(`${process.env.REACT_APP_API_URL}/changePassword/`, {
			//     method: "POST",
			//     headers: {
			//         Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
			//         "Content-Type": "application/json",
			//     },
			// body: JSON.stringify({
			//     username: profileUsername,
			//     password: password,
			//     new_password: confirmPassword,
			// }),
			// })
			//     .then((response) => response.json())
			//     .then((data) => {
			//         alert(data["message"]);
			//         setModalPassword(false);
			//     });
		} else {
			errorToast("Password Not Match");
		}
	}
    const [customerId, setCustomerId] = useState("")
    const [location, setLocation] = useState("")
    const [appFees, setAppFees] = useState("")
    const [appExpiry, setAppExpiry] = useState("")
    const [base64, setBase64] = useState("")
    const [alarmLink, setAlarmLink] = useState("")

    function toggleProfile() {
        setModalUser(!modalUser)
        setLoading(true)
        profile.getInstance().getProfileDetails().then((result) => {
            setProfileUsername(result["username"])
            setCustomerId(result["customer_id"])
            setProfileCompany(result["company"])
            setLocation(result["location"])
            setAppFees(result["app_fees"])
            setAppExpiry(result["app_expiry"])
            setBase64(result["alarm_service_qr"])
            setAlarmLink(result["alarm_service_url"])
            setLoading(false)
        })
    }

	return (
		<>
			{loading && <LoadingSpinner />}
			<div>
				<Modal
					isOpen={modalPassword}
					toggle={toggleModalPassword}
					className={"sm"}
				>
					<ModalHeader toggle={toggleModalPassword}>
						Change Password
					</ModalHeader>
					<ModalBody>
						<form>
							<div className="form-group">
								<label >Username</label>
								<input
									type="text"
									className="form-control"
									id="modal_username"
									value={profileUsername}
									disabled
								/>
							</div>
							<div className="form-group">
								<label>Password</label>
								<input
									type="password"
									className="form-control"
									id="modal_password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							<div className="form-group">
								<label>Confirm Password</label>
								<input
									type="password"
									className="form-control"
									value={confirmPassword}
									onChange={(e) => setConfirmPassword(e.target.value)}
									id="modal_confirmPassword"
								/>
							</div>
						</form>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={updateProfile} disabled={localStorage.getItem("cloudatik_demo")}>
							Update Password
						</Button>{" "}
						<Button color="secondary" onClick={toggleModalPassword}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>

				<Modal isOpen={modalUser} toggle={toggleModalUser} className={"sm"}>
                    <ModalHeader toggle={toggleModalUser}>My Profile</ModalHeader>
                    <ModalBody>
						<div>
							<div className="form-group">
								<label>Username</label>
								<input type="text" className="form-control" value={profileUsername} disabled/>
							</div>
							<div className="form-group">
								<label>Customer ID</label>
                                <input type="text" className="form-control" value={customerId} disabled/>
							</div>
							<div className="form-group">
								<label>Company</label>
                                <input type="text" className="form-control" value={profileCompany} disabled/>
							</div>
							<div className="form-group">
								<label>Location</label>
                                <input type="text" className="form-control" value={location} disabled/>
							</div>
							<div className="form-group">
								<label>App Fees</label>
                                <input type="text" className="form-control" value={appFees} disabled/>
							</div>
							<div className="form-group">
								<label>App Expiry</label>
                                <input type="text" className="form-control" value={moment(appExpiry).tz("Asia/Kuala_Lumpur").format("YYYY-MM-DD")} disabled/>
							</div>
                            <div className="text-center" style={{margin:"5px 0 0 0"}}>
                                <b>SCAN THE QR BELOW TO ACTIVATE ALARM ALERT SERVICE</b>
                                <img style={{ maxWidth: "100%", height: "auto", display: "block", margin: "0 auto" }} src={base64}/>
                            </div>
                            <div className="text-center" style={{margin:"5px 0"}}>
                                <b>or CLICK THE LINK BELOW TO ACTIVATE ALARM ALERT SERVICE</b>
                                <div style={{margin:"15px 0 25px 0"}}><a href={`${alarmLink}`} target="_blank">{alarmLink}</a></div>
                            </div>
						</div>
					</ModalBody>
					<ModalFooter style={{display:"flex", justifyContent:"space-between"}}>
						<Button color="info" onClick={()=>{navigator.clipboard.writeText(alarmLink)}}>
							Copy Link
						</Button>
						<Button color="secondary" onClick={toggleModalUser}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</div>

			<nav id="navbar" className="navbar md navbar-light bg-light">
                <div></div>
				{/* <Dropdown isOpen={dropdownOpen} toggle={toggleDropDown}>
					<>
						<DropdownToggle
							style={{
								backgroundColor: "transparent",
								border: 0,
							}}
						>
							<Tooltip title="Notification" TransitionComponent={Zoom} arrow>
								<Badge badgeContent={notificationData.length} color="primary">
									<NotificationsNoneSharpIcon
										style={{ fontSize: 40, color: "black" }}
									/>
								</Badge>
							</Tooltip>
						</DropdownToggle>
						<DropdownMenu
							className="overflow-auto"
							style={{ height: "70vh", width: "50vw", maxWidth: "400px" }}
						>
							{notificationData.map((item, index) => (
								<Fragment key={index}>
									<DropdownItem>
										<div>
											<div className="row" style={{ width: "100%" }}>
												<>
													<div className="col-md-4">
														<p
															style={{
																color: "white",
																backgroundColor: "orange",
															}}
														>
															{item.device_id}
														</p>
													</div>
													<div className="col-md-8">
														<div className="row">
															<b>{item.tag_name}</b>
														</div>
														<div className="row">{item.parameter_label}</div>
														<div className="row">
															<div className="col-6">{item.date}</div>
															<div className="col-6">{item.time}</div>
														</div>
													</div>
												</>
											</div>
										</div>
									</DropdownItem>
									<div className="dropdown-divider"></div>
								</Fragment>
							))}
						</DropdownMenu>
					</>
				</Dropdown> */}
				<a href="#/v2/dashboard">
					<img
						src={logo}
						alt=""
						className="d-flex justify-content-center mx-auto"
						id="icon"
						width="120px"
					/>
				</a>
				<Dropdown isOpen={dropdownOpenUser} toggle={toggleUser}>
					<>
						<DropdownToggle
							caret
							style={{
								backgroundColor: "transparent",
								color: "black",
							}}
						>
							Hello, <b>{localStorage.getItem("cloudatik")}</b>
						</DropdownToggle>
						<DropdownMenu right>
							<DropdownItem onClick={() => {toggleProfile()}}>My Profile</DropdownItem>
							<DropdownItem onClick={toggleModalPassword}>
								Change Password
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem
								onClick={() => {
									sessionStorage.clear();
									localStorage.removeItem("cloudatik");
									localStorage.removeItem("cloudatik_demo");
									window.location.href = "/";
								}}
							>
								LogOut
							</DropdownItem>
						</DropdownMenu>
					</>
				</Dropdown>
			</nav>
		</>
	);
}

export default Navbar;
