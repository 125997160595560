export const defaultData = {
    "serial": "AR0009",
    "recipe": [
        {   
            "block": "1",
            "plant": "tomato",
            "location": "lojing",
            "season": "Nov-Mar",
            "setting":[
                {
                    "stage": "1",
                    "description": "Establishment",
                    "start_date": "2024-02-08",
                    "end_date": "2024-01-19",
                    "day_growth": "14",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "1",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "1",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "1",
                        "session4_duration": "15",
                        "session4_radiation": "100",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "50",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "2",
                    "description": "Development & Veg Growth",
                    "start_date": "2024-01-20",
                    "end_date": "2024-02-24",
                    "day_growth": "14",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "3",
                    "description": "First Flowering & Fruit Set",
                    "start_date": "2024-02-04",
                    "end_date": "2024-02-18",
                    "day_growth": "14",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "4",
                    "description": "First phase Fruit Development",
                    "start_date": "2024-02-19",
                    "end_date": "2024-03-10",
                    "day_growth": "20",
                    "status": "1",
                    "parameter":
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "5",
                    "description": "Harvest Initiation",
                    "start_date": "2024-03-11",
                    "end_date": "2024-03-31",
                    "day_growth": "20",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "6",
                    "description": "Full Harvest",
                    "start_date": "2024-04-01",
                    "end_date": "2024-05-06",
                    "day_growth": "35",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                }
            ] 
        },
        {   
            "block": "2",
            "plant": "tomato",
            "location": "lojing",
            "season": "Nov-Mar",
            "setting":[
                {
                    "stage": "1",
                    "description": "Establishment",
                    "start_date": "2024-01-05",
                    "end_date": "2024-01-19",
                    "day_growth": "14",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "2",
                    "description": "Development & Veg Growth",
                    "start_date": "2024-01-20",
                    "end_date": "2024-02-24",
                    "day_growth": "14",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "3",
                    "description": "First Flowering & Fruit Set",
                    "start_date": "2024-02-04",
                    "end_date": "2024-02-18",
                    "day_growth": "14",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "4",
                    "description": "First phase Fruit Development",
                    "start_date": "2024-02-19",
                    "end_date": "2024-03-10",
                    "day_growth": "20",
                    "status": "1",
                    "parameter":
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "5",
                    "description": "Harvest Initiation",
                    "start_date": "2024-03-11",
                    "end_date": "2024-03-31",
                    "day_growth": "20",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "6",
                    "description": "Full Harvest",
                    "start_date": "2024-04-01",
                    "end_date": "2024-05-06",
                    "day_growth": "35",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                }
            ] 
        },
        {   
            "block": "3",
            "plant": "tomato",
            "location": "lojing",
            "season": "Nov-Mar",
            "setting":[
                {
                    "stage": "1",
                    "description": "Establishment",
                    "start_date": "2024-01-05",
                    "end_date": "2024-01-19",
                    "day_growth": "14",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "2",
                    "description": "Development & Veg Growth",
                    "start_date": "2024-01-20",
                    "end_date": "2024-02-24",
                    "day_growth": "14",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "3",
                    "description": "First Flowering & Fruit Set",
                    "start_date": "2024-02-04",
                    "end_date": "2024-02-18",
                    "day_growth": "14",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "4",
                    "description": "First phase Fruit Development",
                    "start_date": "2024-02-19",
                    "end_date": "2024-03-10",
                    "day_growth": "20",
                    "status": "1",
                    "parameter":
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "5",
                    "description": "Harvest Initiation",
                    "start_date": "2024-03-11",
                    "end_date": "2024-03-31",
                    "day_growth": "20",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "6",
                    "description": "Full Harvest",
                    "start_date": "2024-04-01",
                    "end_date": "2024-05-06",
                    "day_growth": "35",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                }
            ] 
        },
        {   
            "block": "4",
            "plant": "tomato",
            "location": "lojing",
            "season": "Nov-Mar",
            "setting":[
                {
                    "stage": "1",
                    "description": "Establishment",
                    "start_date": "2024-01-05",
                    "end_date": "2024-01-19",
                    "day_growth": "14",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "2",
                    "description": "Development & Veg Growth",
                    "start_date": "2024-02-08",
                    "end_date": "2024-02-24",
                    "day_growth": "14",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "3",
                    "description": "First Flowering & Fruit Set",
                    "start_date": "2024-02-04",
                    "end_date": "2024-02-18",
                    "day_growth": "14",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "4",
                    "description": "First phase Fruit Development",
                    "start_date": "2024-02-19",
                    "end_date": "2024-03-10",
                    "day_growth": "20",
                    "status": "1",
                    "parameter":
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "5",
                    "description": "Harvest Initiation",
                    "start_date": "2024-03-11",
                    "end_date": "2024-03-31",
                    "day_growth": "20",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "6",
                    "description": "Full Harvest",
                    "start_date": "2024-04-01",
                    "end_date": "2024-05-06",
                    "day_growth": "35",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                }
            ] 
        },
        {   
            "block": "5",
            "plant": "tomato",
            "location": "lojing",
            "season": "Nov-Mar",
            "setting":[
                {
                    "stage": "1",
                    "description": "Establishment",
                    "start_date": "2024-01-05",
                    "end_date": "2024-01-19",
                    "day_growth": "14",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "2",
                    "description": "Development & Veg Growth",
                    "start_date": "2024-01-20",
                    "end_date": "2024-02-24",
                    "day_growth": "14",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "3",
                    "description": "First Flowering & Fruit Set",
                    "start_date": "2024-02-04",
                    "end_date": "2024-02-18",
                    "day_growth": "14",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "4",
                    "description": "First phase Fruit Development",
                    "start_date": "2024-02-19",
                    "end_date": "2024-03-10",
                    "day_growth": "20",
                    "status": "1",
                    "parameter":
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "5",
                    "description": "Harvest Initiation",
                    "start_date": "2024-03-11",
                    "end_date": "2024-03-31",
                    "day_growth": "20",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                },
                {
                    "stage": "6",
                    "description": "Full Harvest",
                    "start_date": "2024-04-01",
                    "end_date": "2024-05-06",
                    "day_growth": "35",
                    "status": "1",
                    "parameter": 
                    {
                        "session1_state": "0",
                        "session1_duration": "5",
                        "session1_cycle": "2",
                        "session1_medium": "1",
                        "session2_state": "0",
                        "session2_duration": "5",
                        "session2_cycle": "2",
                        "session2_medium": "1",
                        "session3_state": "0",
                        "session3_duration": "5",
                        "session3_cycle": "2",
                        "session3_medium": "1",              
                        "session4_state": "0",
                        "session4_duration": "5",
                        "session4_radiation": "200",
                        "session4_medium": "1",    
                        "session5_state": "0",
                        "session5_duration": "5",
                        "session5_radiation": "200",
                        "session5_medium": "1",  
                        "session6_state": "0",
                        "session6_duration": "5",
                        "session6_radiation": "200",
                        "session6_medium": "1"
                    }
                }
            ] 
        }
    ]

}