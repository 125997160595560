import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import $ from "jquery";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { utcToMtyConvertionDate } from "../shared/DateTimeConvertion";
import { errorToast } from "../shared/notification";
import LoadingSpinner from "../shared/Spinner/LoadingSpinner";
import { refetchApi } from '../shared/utils';
am4core.useTheme(am4themes_animated);

const moment = require("moment-timezone");

var total_properties = [];

function formatDate(date) {
	var d = new Date(date),
		month = "" + (d.getMonth() + 1),
		day = "" + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = "0" + month;
	if (day.length < 2) day = "0" + day;
	return [year, month, day].join("-");
}

function RetrieveChart() {
	const { search } = useLocation();
	const values = queryString.parse(search);
    const [loading, setLoading] = useState(false)
    const [deviceInfo, setDeviceInfo] = useState({})
    const [calculatedLabel, setCalculatedLabel] = useState([])


	let epochStartTime = moment(
		formatDate(
			new Date(new Date().setHours(new Date().getHours() - 10)).getTime()
		)
	)
		.tz("Asia/Kuala_Lumpur")
		.unix();

	let epochEndTime = moment(formatDate(new Date()))
		.tz("Asia/Kuala_Lumpur")
		.unix();

	const [fullChartData, setFullChartData] = useState();
	const [datetimeChange, setDatetimeChange] = useState(false)
	const [buttonIsActive, setButtonIsActive] = useState(false)
	const [startDate, setStartDate] = useState("")
	const [endDate, setEndDate] = useState("")

	function datetimeHandleChange(event) {
        if (isMoreThanOneMonth(new Date( document.getElementById("start-date").value + " " + document.getElementById("start-time").value ), new Date( document.getElementById("end-date").value + " " + document.getElementById("end-time").value ))) {
            alert("invalid date: date set in more than 1 month")
            setButtonIsActive(false)
        } else {
            setButtonIsActive(true)
        }
		setDatetimeChange(true)
	}

    const isMoreThanOneMonth = (startDate, endDate) => {
        const start = new Date(startDate)
        const end = new Date(endDate)

        // Check if end date is smaller than start date
        if (end < start) {
            return true
        }

        const monthsDiff = end.getMonth() - start.getMonth() + (12 * (end.getFullYear() - start.getFullYear()))
        return monthsDiff > 1 || (monthsDiff === 1 && end.getDate() >= start.getDate())
    }

	let default_start_time = String(
		moment(new Date(new Date().setHours(new Date().getHours() - 10)))
			.tz("Asia/Kuala_Lumpur")
			.format("HH:mm")
	);
	let default_end_time = String(
		moment().tz("Asia/Kuala_Lumpur").format("HH:mm")
	);

	// Retrieve Data
	useEffect(() => {
		// Set Default Date
		document.getElementById("start-time").value = default_start_time;
		document.getElementById("end-time").value = default_end_time;
		document.getElementById("end-date").valueAsDate = new Date();
		document.getElementById("start-date").valueAsDate = new Date(
			new Date().setHours(new Date().getHours() - 10)
		);
        
        setStartDate(new Date(new Date().setHours(new Date().getHours() - 10))) // follow same as epochStartTime
        setEndDate(new Date()) // follow same as epochEndTime
        
        getTagName()
        callGetChartApi(epochStartTime, epochEndTime)
	}, []);

    function getTagName() {
        setLoading(true)
        fetch(`https://api.cl-ds.com/getDashboardAllChartIntervalV2/${values.device_id}`, {
            headers: {
                'Authorization': 'Token 59ee204b9bbf777b0cd544bcc73b3bc2fcd10f9e',
                "Content-Type": "application/json"
            }
        }).then((res) => res.json())
        .then((result) => {
            setLoading(false)
            setDeviceInfo(prev=> ({
                ...prev,
                tag_name: result.tag_name
            }))
        }, (error) => {
            errorToast('Something went wrong. Please contact administrator.')
            setLoading(false)
            console.log(error);
        })
    }

    function callGetChartApi(start_time, end_time) {
        setLoading(true)
        fetch(
			`https://api.cl-ds.com/retrieveAllChart/${values.device_id}/${start_time}/${end_time}/`,
			{
				headers: { 
                    'Authorization': 'Token 59ee204b9bbf777b0cd544bcc73b3bc2fcd10f9e',
                    "Content-Type": "application/json"
                },
			}
		)
			.then((res) => res.json())
			.then(
				(result) => {
                    console.log(12322, result)
                    if (result["chart_prop"].length <= 0) return errorToast('No Data recorded')

                    if (result.calculated) {
                        setCalculatedLabel(result.calculated)
                    }

                    for (let i of result["chart_prop"]) {
                        if (i.serial) {
                            setDeviceInfo(prev => ({
                                ...prev,
                                serial: i.serial,
                                device_id: values.device_id
                            }))
                            break
                        }
                    }
                    setLoading(false)
					// if (result.code && result.code === 'token_not_valid') {
                    //     console.log("token not valid")
					// 	refetchApi();
					// }

					setFullChartData(result)
				},
				(error) => {
                    errorToast('Something went wrong. Please contact administrator.')
                    setLoading(false)
					console.log(error);
				}
			);
    }

    // this is to enable when datetime changes, not showing the correct data unless click generate chart twice.
    useEffect(() => {
        if (!fullChartData) return null // initial load

        if (datetimeChange) {
            // if theres a change in datetime, meaning user change datetime, so don't need to reset the checkbox.
            setDatetimeChange(false)
            createChart()
        } else {
            // if theres NO change in datetime, meaning its a fresh reload.
            $("#category").empty();
            total_properties = []
            for (let index = 0; index < fullChartData["chart_prop"].length; index++) {
                let label = fullChartData["chart_prop"][index]["label"];
                let parameter = fullChartData["chart_prop"][index]["parameter"];
                let sensor_set = fullChartData["chart_prop"][index]["sensor_set"]

                let d = {
                    label: label,
                    parameter: parameter,
                    sensor_set: sensor_set
                };

                total_properties.push(d)

                //Append Html
                $("#category").append(
                    `<div className="col-6"><div className="form-check" style="margin-right: 8px">` +
                    `<input className="form-check-input" type="checkbox" id="check${index}" value="${parameter}" data-sensor-set="${sensor_set}">` +
                    `<label className="form-check-label">${label}</label>` +
                    `</div></div>`
                );
            }
            setButtonIsActive(true)
        }
    }, [fullChartData])
    

	function generateChart() {
		let checkbox_status = []
		for (let index = 0; index < total_properties.length; index++) {
			checkbox_status.push(document.getElementById("check" + index).checked)
		}
		// Only allow to generate if a category is selected.
		if (!checkbox_status.includes(true)) return errorToast("Please select a category.")
		if (!fullChartData) return errorToast("Something went wrong. Please refresh.")

        if (datetimeChange) {
            //Retrieve data from input box
			let new_start_epochtime = moment(
				new Date(
					document.getElementById("start-date").value +
					" " +
					document.getElementById("start-time").value
				)
			).tz("Asia/Kuala_Lumpur").unix();
			let new_end_epochtime = moment(
				new Date(
					document.getElementById("end-date").value +
					" " +
					document.getElementById("end-time").value
				)
			).tz("Asia/Kuala_Lumpur").unix();

            if (new_start_epochtime > new_end_epochtime) return errorToast('Invalid Start Date and End Date')
            
            setStartDate(new Date( document.getElementById("start-date").value + " " + document.getElementById("start-time").value ))
            setEndDate(new Date( document.getElementById("end-date").value + " " + document.getElementById("end-time").value ))

            callGetChartApi(new_start_epochtime, new_end_epochtime)
        } else {
            createChart()
        }
        // chart.scrollbarX.parent = chart.bottomAxesContainer;
        // } else {
        // 	// Create chart instance
        // 	let chart = am4core.create("chartdiv", am4charts.XYChart);
        // 	chart.numberFormatter.numberFormat = "#.00";

        // 	// Increase contrast by taking evey second color
        // 	chart.colors.step = 2;

        // 	let series;

        // 	// Create axes
        // 	let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        // 	dateAxis.renderer.minGridDistance = 50;

        // 	// Create series
        // 	function createAxisAndSeries(field, name, opposite, bullet) {
        // 		let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        // 		if (chart.yAxes.indexOf(valueAxis) != 0) {
        // 			valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
        // 		}

        // 		series = chart.series.push(new am4charts.LineSeries());
        // 		series.dataFields.valueY = field;
        // 		series.dataFields.dateX = "date";
        // 		series.strokeWidth = 2;
        // 		series.yAxis = valueAxis;
        // 		series.name = name;
        // 		series.tooltipText = "{name}: [bold]{valueY}[/]";
        // 		series.tensionX = 0.8;
        // 		series.showOnInit = true;

        // 		valueAxis.renderer.line.strokeOpacity = 1;
        // 		valueAxis.renderer.line.strokeWidth = 2;
        // 		valueAxis.renderer.line.stroke = series.stroke;
        // 		valueAxis.renderer.labels.template.fill = series.stroke;
        // 		valueAxis.renderer.opposite = opposite;

        // 		valueAxis.renderer.labels.template.fontSize = 10;
        // 	}
        // 	let field = [];
        // 	let chartData = [];

        // 	// Generate Axis
        // 	for (let index = 0; index < total_properties.length; index++) {
        // 		if (document.getElementById("check" + index).checked) {
        // 			field.push(document.getElementById("check" + index).value);
        // 			createAxisAndSeries(
        // 				total_properties[index]["parameter"],
        // 				total_properties[index]["label"],
        // 				index % 2 == 0 ? false : true,
        // 				"circle"
        // 			);
        // 		}
        // 	}

        // 	for (let index = 0; index < fullChartData["data"].length; index++) {
        // 		let d = {
        // 			date: utcToMtyConvertionDate(fullChartData["data"][index]["datetime"]),
        // 		};

        // 		for (let m = 0; m < field.length; m++) {
        // 			d[field[m]] = parseFloat(fullChartData["data"][index][field[m]]);
        // 		}
        // 		chartData.push(d);
        // 	}

        // 	//Add Data
        // 	chart.data = chartData;

        // 	// Add legend
        // 	chart.legend = new am4charts.Legend();

        // 	// Add cursor
        // 	chart.cursor = new am4charts.XYCursor();

        // 	// // Scrollbar
        // 	// var scrollbar = new am4charts.XYChartScrollbar();
        // 	// scrollbar.series.push(series);
        // 	// chart.scrollbarX = scrollbar;
        // 	// chart.scrollbarX.parent = chart.bottomAxesContainer;

        // 	// Export
        // 	chart.exporting.menu = new am4core.ExportMenu();
        // 	chart.exporting.menu.align = "right";
        // 	chart.exporting.menu.verticalAlign = "top";

        // 	// Title
        // 	let title = chart.titles.create();
        // 	title.text = "Chart Summary";
        // 	title.fontSize = 25;
        // 	title.marginBottom = 30;
        // }
	}

    function createChart() {
        let chart = am4core.create("chartdiv", am4charts.XYChart);
        // Create chart instance
        chart.numberFormatter.numberFormat = "#.00";

        // Increase contrast by taking evey second color
        chart.colors.step = 2;

        let series;

        // Create axes
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.minGridDistance = 50;

        // Create series
        function createAxisAndSeries(field, name, opposite) {
            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            if (chart.yAxes.indexOf(valueAxis) != 0) {
                valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
            }

            series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.valueY = field;
            series.dataFields.dateX = "date";
            series.strokeWidth = 2;
            series.yAxis = valueAxis;
            series.name = name;
            series.tooltipText = "{name}: [bold]{valueY}[/]";
            series.tensionX = 0.8;
            series.showOnInit = true;

            valueAxis.renderer.line.strokeOpacity = 1;
            valueAxis.renderer.line.strokeWidth = 2;
            valueAxis.renderer.line.stroke = series.stroke;
            valueAxis.renderer.labels.template.fill = series.stroke;
            valueAxis.renderer.opposite = opposite;

            valueAxis.renderer.labels.template.fontSize = 10;
        }

        let field = [];
        let chartData = [];

        // Generate Axis
        for (let index = 0; index < total_properties.length; index++) {
            if (document.getElementById("check" + index).checked) {
                field.push({
                    parameter: document.getElementById("check" + index).value,
                    sensor_set: document.getElementById("check" + index).getAttribute('data-sensor-set')
                });
                createAxisAndSeries(
                    total_properties[index]["parameter"] + '-' + total_properties[index]["sensor_set"],
                    total_properties[index]["label"],
                    index % 2 == 0 ? false : true,
                );
            }
        }
        let d = {}
        for (let index = 0; index < fullChartData.data.length; index++) {
            // let datetime = new Date(String(moment(new Date(fullChartData.data[index].datetime)).tz("Asia/Kuala_Lumpur").format("YYYY-MM-DD[T]HH:mm:ss[Z]")))
            // let datetime = new Date(moment(fullChartData.data[index].datetime).tz("Asia/Kuala_Lumpur").format("YYYY-MM-DD[T]HH:mm:ss[Z]"))
            // let datetime = moment(fullChartData.data[index].datetime).tz("Asia/Kuala_Lumpur").format("YYYY-MM-DD[T]HH:mm:ss[Z]")

            let datetime = new Date(fullChartData.data[index].datetime) // Tue Mar 14 2023 08:00:53 GMT+0800 (Malaysia Time)

            // console.log(fullChartData.data[index].datetime)
            // // console.log(moment(fullChartData.data[index].datetime.replace("T", " ").replace("Z", "")).format("YYYY-MM-DD HH:mm:ss"))
            // // console.log(new Date(fullChartData.data[index].datetime.replace("T", " ").replace("Z", "")))
            // // console.log(new Date(String(moment(new Date(fullChartData.data[index].datetime)).tz("UTC").format("YYYY-MM-DD[T]HH:mm:ss[Z]"))))
            // let datetime = new Date(fullChartData.data[index].datetime.replace("T", " ").replace("Z", ""))
            
            if (datetime >= startDate && datetime <= endDate) {
                d[datetime] = {
                    date: datetime
                }
            }
        }
        // https://api.cloudatik.com/retrieveAllChart/4qt3KA/1676346060/1676554860/

        for (let index = 0; index < fullChartData.data.length; index++) {
            // let datetime = new Date(String(moment(new Date(fullChartData.data[index].datetime)).tz("Asia/Kuala_Lumpur").format("YYYY-MM-DD[T]HH:mm:ss[Z]")))
            // let datetime = new Date(fullChartData.data[index].datetime.replace("T", " ").replace("Z", ""))
            let datetime = new Date(fullChartData.data[index].datetime)
            for (let m = 0; m < field.length; m++) {
                if (field[m].sensor_set == fullChartData.data[index].sensor_set) {
                    
                    if (datetime >= startDate && datetime <= endDate) {
                        d[datetime] = {
                            ...d[datetime],
                            [field[m].parameter + '-' + field[m].sensor_set] : parseFloat(fullChartData.data[index][field[m].parameter])
                        }
                    }
                }
            }
        }
        for (let i in d) {
            chartData.push(d[i])
        }

        //Add Data
        let sortedArray = chartData.sort((a,b) => Date.parse(new Date(a.date)) - Date.parse(new Date(b.date)));
        chart.data = sortedArray;

        // Add legend
        chart.legend = new am4charts.Legend();

        // Add cursor
        chart.cursor = new am4charts.XYCursor();

        // Export
        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.align = "right";
        chart.exporting.menu.verticalAlign = "top";

        // Scrollbar
        var scrollbar = new am4charts.XYChartScrollbar();
        scrollbar.series.push(series);
        chart.scrollbarX = scrollbar;
    }

	return (
		<div>
        {loading && <LoadingSpinner />}
			<center>
                <div style={{display: 'flex',  justifyContent: 'center'}}>
                    <h3>{deviceInfo.serial} - {deviceInfo.tag_name}</h3>
                </div>
				<div style={{ maxWidth: 1200 }}>
					<Row style={{ margin: '0' }}>
						<Col>
							<FormGroup>
								<Label>Start Date</Label>
								<Input
									type="date"
									id="start-date"
									onChange={(e) => {
										datetimeHandleChange(e);
									}}
								/>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label>Start Time</Label>
								<Input
									type="time"
									id="start-time"
									onChange={(e) => {
										datetimeHandleChange(e);
									}}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row style={{ margin: '0' }}>
						<Col>
							<FormGroup>
								<Label>End Date</Label>
								<Input
									type="date"
									id="end-date"
									onChange={(e) => {
										datetimeHandleChange(e);
									}}
								/>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label>End Time</Label>
								<Input
									type="time"
									id="end-time"
									onChange={(e) => {
										datetimeHandleChange(e);
									}}
								/>
							</FormGroup>
						</Col>
					</Row>
					<div className="row pr-0 py-2 pl-2 mx-0" id="category"></div>
					<br />
                    <div className="row" style={{marginTop: "0.3rem", marginBottom:"1.4rem"}}>
                        {calculatedLabel.map(label => {
                            return (
                                <>
                                <span style={{fontSize: "1.5rem", width: "100%", marginBottom:"0.8rem"}}><b>{label.label} : {label.value} {label.unit}</b></span>    
                                </>
                            )
                        })}
                    </div>
					<Button color="primary" disabled={!buttonIsActive ? true : false} onClick={() => generateChart()}>
						Generate
					</Button>
					<div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
				</div>
			</center>
		</div>
	);
}

export default RetrieveChart;
