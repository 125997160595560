import React, {useEffect, useState} from 'react'
import { Button, IconButton, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useParams } from "react-router-dom";
import LoadingSpinner from '../../shared/Spinner/LoadingSpinner';
import { errorToast, successToast } from '../../shared/notification';

var mqtt = require('mqtt');
const MQTT_USERNAME = process.env.REACT_APP_MQTT_USERNAME;
const MQTT_PASSWORD = process.env.REACT_APP_MQTT_PASSWORD;
const MQTT_SERVER = process.env.REACT_APP_MQTT_SERVER;
const MQTT_PORT = process.env.REACT_APP_MQTT_PORT;

const RemoteLed = () => {
    let { device, device_id } = useParams();
	const [loading, setLoading] = useState(false);
    const [inputs, setInputs] = useState({
        blue: 0,
        infrared: 0,
        red: 0,
        state: 0,
        white: 0,
    })
    
    useEffect(() => {
        connectToDevice()
    }, [])
    
    const switchChange = (e, type) => {
        setInputs(prevState => ({
            ...prevState,
            [type]: e.target.checked ? 1 : 0
        }));
    }
    
    const textChange = (e, type) => {
        if (!/^\d*\.?\d*$/i.test(e.target.value)) return null

        setInputs(prevState => ({
            ...prevState,
            [type]: e.target.value
        }));
    }

    function submit() {
        if (inputs.blue > 250 || inputs.infrared > 120 || inputs.red > 290 || inputs.white > 200) return errorToast('Please check your inputs.')
        let aswrPayload = `{"setting":{"state":${inputs.state},"blue":${inputs.blue},"red":${inputs.red},"infrared":${inputs.infrared},"white":${inputs.white}}}`

        let server = `wss://${MQTT_SERVER}:${MQTT_PORT}`
        var client_1 = mqtt.connect(server, {
            username: MQTT_USERNAME,
            password: MQTT_PASSWORD
        })

        client_1.on('connect', function () {
            console.log(`*** subscribing to 'farm/aswa/${device_id}'...`);
            client_1.subscribe('farm/aswa/' + device_id, function (err) {
                if (!err) {
                    console.log(`*** subscribed to farm/aswa/${device_id}.`);
                    console.log(`*** publishing to farm/aswr/${device_id}:`, aswrPayload);
                    client_1.publish('farm/aswr/' + device_id, aswrPayload);
                }
            });
        });

        client_1.on('message', function (topic, message) {
            console.log("*** received from setInput topic:", topic);
            console.log("*** received setInput message:", message.toString());
            // message is Buffer
            client_1.end();

            const message_json = JSON.parse(message.toString());
            if (message_json.result && message_json.result === "error") {
                errorToast(`[ERROR] Please resubmit.`);
            } else {
                successToast("[SUCCESS] Value updated.");
            }

            connectToDevice();
        })
    }
    
    const connectToDevice = () => {
        let server = `wss://${MQTT_SERVER}:${MQTT_PORT}`
        console.log(`*** connecting to ${server}`);
        var client = mqtt.connect(server, {
            username: MQTT_USERNAME,
            password: MQTT_PASSWORD
        })
        // wss://servertwo.cloudatik.com:9001
        // wss://imre.cloudatik.com:8192
        let srrdPayload = `{
            "request":"get_setting",
        }`


        client.on('connect', function () {
            console.log("*** connected.");
            console.log(`*** subscribing to farm/srrp/${device_id}...`);
            setLoading(true)
            client.subscribe('farm/srrp/' + device_id, function (err) {
                setLoading(false)
                if (!err) {
                    console.log(`*** subscribed to farm/srrp/SP0004.`);
                    console.log(`*** publishing to farm/srrd/SP0004...`);
                    client.publish('farm/srrd/' + device_id, srrdPayload);
                }
            });
        });

        client.on('message', function (topic, message) {
            const responseMessage = JSON.parse(message);
            console.log(`*** message received from topic: ${topic}.`);
            console.log(`*** responseMessage:`, responseMessage);
            client.end();

            setInputs(responseMessage.setting)
        })
    }

    return (
        <>
            {loading && <LoadingSpinner />}
            <Grid container spacing={2} style={{
                textAlign: 'center', maxWidth: '750px',
                margin: 'auto',
                border: ' 1px solid #808080',
                borderRadius: '10px',
                padding: '10px',
            }}>

                <Grid item xs={12} center>
                    LED ON/OFF
                    <Switch
                        checked={inputs.state ? true : false}
                        onChange={(e) => { switchChange(e, 'state') }}
                        color="primary"
                    />
                </Grid>
                
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            error={false}
                            helperText=""
                            value={inputs.blue}
                            style={{ width: '70%' }}
                            label="Blue Intensity (max 250)"
                            InputProps={{
                                endAdornment: <>umol/m2</>,
                            }}
                            onChange={(e) => { textChange(e, 'blue') }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            error={false}
                            helperText=""
                            value={inputs.red}
                            style={{ width: '70%' }}
                            label="Red Intensity (max 290)"
                            InputProps={{
                                endAdornment: <>umol/m2</>,
                            }}
                            onChange={(e) => { textChange(e, 'red') }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            error={false}
                            helperText=""
                            value={inputs.infrared}
                            style={{ width: '70%' }}
                            label="Infrared Intensity (max 120)"
                            InputProps={{
                                endAdornment: <>umol/m2</>,
                            }}
                            onChange={(e) => { textChange(e, 'infrared') }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            error={false}
                            helperText=""
                            value={inputs.white}
                            style={{ width: '70%' }}
                            label="White Intensity (max 200)"
                            InputProps={{
                                endAdornment: <>umol/m2</>,
                            }}
                            onChange={(e) => { textChange(e, 'white') }} />
                    </Grid>

                    <div style={{margin:'auto', width: '100%', marginTop: 20, marginBottom:20}}>
                        <Button variant="contained" style={{width: '50%', }} color="primary" onClick={()=>submit()}>SUBMIT</Button>
                    </div>
                </Grid>


            </Grid>
        </>
    )
}

export default RemoteLed