import React, { useState, useEffect } from "react";
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Table,
} from "reactstrap";
import { successToast } from "../shared/notification";
import LoadingSpinner from "../shared/Spinner/LoadingSpinner";
import Slider from "./Slider";
import Switch from "./Switch";
import Threshold from "./Threshold";

function NodeAlarm({ device_id }) {
	const [mainElement, setMainElement] = useState([]);
	const [mainData, setMainData] = useState([]);

	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	const [loading, setLoading] = useState(false);

	function saveDetails() {
		let data = {};
		data["data"] = [];
		for (let index = 0; index < mainData.length; index++) {
			data["data"].push(mainData[index]);
		}

		//Update to Cloud
		fetch(`${process.env.REACT_APP_API_URL}/saveAlarmSettingV2/?format=json`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((response) => response.json())
			.then((data) => {
				successToast(data["message"]);
				setModal(false);
			});
	}

	function updateValue(data) {
		if (mainData.length == 0) {
			mainData[0] = {};
			mainData[0]["enabled"] = data["enabled"];
			mainData[0]["limit_low"] = data["limit_low"];
			mainData[0]["limit_high"] = data["limit_high"];
			mainData[0]["alarm_min"] = data["alarm_min"];
			mainData[0]["alarm_max"] = data["alarm_max"];
			mainData[0]["plot_limit"] = data["plot_limit"];
			mainData[0]["category"] = data["category"];
			mainData[0]["device_id"] = data["device_id"];
			mainData[0]["username"] = data["username"];
			mainData[0]["parameter"] = data["parameter"];
			mainData[0]["label"] = data["label"];
		} else {
			for (let index = 0; index < mainData.length; index++) {
				if (mainData[index]["parameter"] == data["parameter"]) {
					mainData[index]["enabled"] = data["enabled"];
					mainData[index]["limit_low"] = data["limit_low"];
					mainData[index]["limit_high"] = data["limit_high"];
					mainData[index]["alarm_min"] = data["alarm_min"];
					mainData[index]["alarm_max"] = data["alarm_max"];
					mainData[index]["plot_limit"] = data["plot_limit"];
					mainData[index]["label"] = data["label"];
					return;
				}
			}
			setMainData((mainData) => [...mainData, data]);
		}
	}

	function modalConfirmation() {
		setModal(true);
	}

	var config_list = [];

	useEffect(() => {
		setLoading(true);
		fetch(
			`${process.env.REACT_APP_API_URL
			}/getAlarmPropV4/${device_id}/${localStorage.getItem("cloudatik")}/`, {
			headers: { 'Authorization': localStorage.get('cloudatik-access') },
		}
		)
			.then((res) => res.json())
			.then(
				(result) => {
					for (let index = 0; index < result["alarm_prop"].length; index++) {
						if (result["alarm_prop"][index]["slider_category"] == "slider") {
							// GENERATE SLIDER
							let newContent = (
								<div style={{ minWidth: "30vw" }}>
									<Slider
										index={index}
										limit_low={result["alarm_prop"][index]["limit_low"]}
										limit_high={result["alarm_prop"][index]["limit_high"]}
										slider_max={result["alarm_prop"][index]["slider_max"]}
										slider_min={result["alarm_prop"][index]["slider_min"]}
										alarm_max={result["alarm_prop"][index]["alarm_max"]}
										alarm_min={result["alarm_prop"][index]["alarm_min"]}
										alarm_enabled={result["alarm_prop"][index]["alarm_enabled"]}
										label={result["alarm_prop"][index]["label"]}
										plot_limit={result["alarm_prop"][index]["plot_limit"]}
										parameter={result["alarm_prop"][index]["parameter"]}
										label={result["alarm_prop"][index]["label"]}
										updateValue={updateValue}
									/>
									<br />
								</div>
							);
							setMainElement((mainElement) => [...mainElement, newContent]);
						}

						if (result["alarm_prop"][index]["slider_category"] == "threshold") {
							// GENERATE THRESHOLD
							let newContent = (
								<div style={{ minWidth: "30vw" }}>
									<Threshold
										index={index}
										limit_low={result["alarm_prop"][index]["limit_low"]}
										limit_high={result["alarm_prop"][index]["limit_high"]}
										slider_max={result["alarm_prop"][index]["slider_max"]}
										slider_min={result["alarm_prop"][index]["slider_min"]}
										alarm_max={result["alarm_prop"][index]["alarm_max"]}
										alarm_min={result["alarm_prop"][index]["alarm_min"]}
										alarm_enabled={result["alarm_prop"][index]["alarm_enabled"]}
										label={result["alarm_prop"][index]["label"]}
										plot_limit={result["alarm_prop"][index]["plot_limit"]}
										parameter={result["alarm_prop"][index]["parameter"]}
										label={result["alarm_prop"][index]["label"]}
										updateValue={updateValue}
									/>
									<br />
								</div>
							);
							setMainElement((mainElement) => [...mainElement, newContent]);
						}

						if (result["alarm_prop"][index]["slider_category"] == "switch") {
							// GENERATE SWITCH
							let newContent = (
								<div style={{ minWidth: "30vw" }}>
									<Switch
										index={index}
										limit_low={result["alarm_prop"][index]["limit_low"]}
										limit_high={result["alarm_prop"][index]["limit_high"]}
										slider_max={result["alarm_prop"][index]["slider_max"]}
										slider_min={result["alarm_prop"][index]["slider_min"]}
										alarm_max={result["alarm_prop"][index]["alarm_max"]}
										alarm_min={result["alarm_prop"][index]["alarm_min"]}
										alarm_enabled={result["alarm_prop"][index]["alarm_enabled"]}
										label={result["alarm_prop"][index]["label"]}
										plot_limit={result["alarm_prop"][index]["plot_limit"]}
										parameter={result["alarm_prop"][index]["parameter"]}
										label={result["alarm_prop"][index]["label"]}
										updateValue={updateValue}
									/>
									<br />
								</div>
							);
							setMainElement((mainElement) => [...mainElement, newContent]);
						}

						config_list.push(result["alarm_prop"][index]);
					}
					setLoading(false);
				},
				(error) => {
					console.log(error);
				}
			);
	}, []);

	return (
		<div>
			{loading && <LoadingSpinner />}
			<div>
				<Modal isOpen={modal} toggle={toggle} className="sm">
					<ModalHeader toggle={toggle}>Modal title</ModalHeader>
					<ModalBody>
						<Table responsive>
							<thead>
								<tr>
									<th>Label</th>
									<th>Alarm</th>
									<th>Alarm Low</th>
									<th>Alarm High</th>
									<th>Plot Limit</th>
									<th>Limit Low</th>
									<th>Limit High</th>
								</tr>
							</thead>
							<tbody>
								{mainData.map((item) => (
									<tr>
										<td>{item.label}</td>
										<td>{parseInt(item.enabled) == 1 ? "Yes" : "No"}</td>
										<td>{item.alarm_min}</td>
										<td>{item.alarm_max}</td>
										<td>{parseInt(item.plot_limit) == 1 ? "Yes" : "No"}</td>
										<td>{item.limit_low}</td>
										<td>{item.limit_high}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={saveDetails}>
							Confirm
						</Button>{" "}
						<Button color="secondary" onClick={toggle}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</div>
			{mainElement}
			<button
				className="btn btn-primary btn-block"
				onClick={() => modalConfirmation()}
			>
				SAVE
			</button>
		</div>
	);
}

export default NodeAlarm;
