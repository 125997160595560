import React, { useEffect, useState } from 'react'
import { refetchApi } from '../../shared/utils'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Multiselect } from "multiselect-react-dropdown";
import { successToast } from '../../shared/notification';
import category from '../../services/node-category/category';

const AddEditNodeModal = ({
    modalAddEditNode,
    setAddEditNode,
    categoryName,
    setLoading
}) => {
    
	// const [modalAddEditNode, setAddEditNode] = useState(false);
    const [nodeList, setNodeList] = useState([])
    const [selectedCategory, setSelectedCategory] = useState([])
    // const [loading, setLoading] = useState(false)
	const [mainSelectedNodeList, setMainSelectedNodeList] = useState([]);
	const [mainDeselectedNodeList, setMainDeselectedNodeList] = useState([]);
	const [selectedNodeList, setSelectedNodeList] = useState([]);
	const [categoryList, setCategoryList] = useState([]);

	let newEntry = false;
    
    
    useEffect(() => {
        if (modalAddEditNode) {
            onOpenAddNodeModal({ category: categoryName})
        }
    }, [modalAddEditNode])

	function onOpenAddNodeModal(item) {
		setLoading(true);
		setAddEditNode(true);
		setSelectedCategory(item);
		fetch(`${process.env.REACT_APP_API_URL}/getUserNodeCategoryV2/`, {
			headers: {
				'Authorization': localStorage.getItem('cloudatik-access')
			}
		}).then((res) => res.json())
		.then(
            (result) => {
                if (result.code && result.code === 'token_not_valid') {
                    refetchApi();
                }

                let nodeWithCategory = [];
                let selectedNode = [];
                let allNode = [];
                let allNodeWithCategory = [];
                setNodeList([]);
                setSelectedNodeList([]);
                for (let index = 0; index < result["group"].length; index++) {
                    nodeWithCategory.push({
                        node: `${result["group"][index]["serial"]}-${result["group"][index]["sensor_set"] == null
                            ? 1
                            : result["group"][index]["sensor_set"]
                            }`,
                        category: result["group"][index]["category"],
                    });

                    if (result["group"][index]["category"] == item.category) {
                        selectedNode.push(
                            `${result["group"][index]["serial"]}-${result["group"][index]["sensor_set"] == null
                                ? 1
                                : result["group"][index]["sensor_set"]
                            }`
                        );
                    }
                }

                for (let index = 0; index < result["node"].length; index++) {
                    let node_id = `${result["node"][index]["serial"]}-${result["node"][index]["sensor_set"] == null
                        ? 1
                        : result["node"][index]["sensor_set"]
                        }`;
                    allNode.push({
                        node: node_id,
                        device_id: result["node"][index]["device_id"],
                        display_name: result["node"][index]["tag_name"] + ' [' + node_id + ']',
                    });
                }

                for (let x = 0; x < allNode.length; x++) {
                    let a = 0;
                    for (let index = 0; index < nodeWithCategory.length; index++) {
                        if (nodeWithCategory[index]["node"] == allNode[x]["node"]) {
                            allNodeWithCategory.push({
                                id: x,
                                node: nodeWithCategory[index]["node"],
                                category: nodeWithCategory[index]["category"],
                                device_id: allNode[x]["device_id"],
                                display_name: allNode[x]["display_name"]
                            });
                            a = 1;
                        }
                    }
                    if (a == 0) {
                        allNodeWithCategory.push({
                            id: x,
                            node: allNode[x]["node"],
                            category: null,
                            device_id: allNode[x]["device_id"],
                            display_name: allNode[x]["display_name"]
                        });
                    }
                }

                for (let index = 0; index < allNodeWithCategory.length; index++) {
                    setNodeList((nodeList) => [
                        ...nodeList,
                        allNodeWithCategory[index],
                    ]);

                    if (allNodeWithCategory[index]["category"] == item.category) {
                        newEntry = true;
                        setSelectedNodeList((selectedNodeList) => [
                            ...selectedNodeList,
                            allNodeWithCategory[index],
                        ]);
                    }
                }
                setLoading(false);
            },
            (error) => {
                console.log(error);
            }
        );
	}

	const toggleAddNode = () => setAddEditNode(!modalAddEditNode);

	function onUpdateNode() {
		setLoading(true);

		for (let index = 0; index < mainSelectedNodeList.length; index++) {
			mainSelectedNodeList[index]["username"] = localStorage.getItem("cloudatik");
			mainSelectedNodeList[index]["sensor_set"] = mainSelectedNodeList[index]["node"].split("-")[1];
			mainSelectedNodeList[index]["category"] = selectedCategory.category;
		}
		for (let index = 0; index < mainDeselectedNodeList.length; index++) {
			mainDeselectedNodeList[index]["username"] =
				localStorage.getItem("cloudatik");
			mainDeselectedNodeList[index]["sensor_set"] =
				mainDeselectedNodeList[index]["node"].split("-")[1];
		}

		let dataToSubmit = {
			selected: mainSelectedNodeList,
			deselected: mainDeselectedNodeList,
		};

		category.getInstance()
			.updateCategoryNode(dataToSubmit)
			.then((result) => {
				successToast(result["message"])
				setLoading(false)
                setTimeout(()=> {
                    window.location.reload()
                }, 2000)
			});
	}

    
	function onSelect(selectedList, selectedItem) {
		setMainSelectedNodeList(selectedList);
	}

	function onRemove(selectedList, removedItem) {
		setMainDeselectedNodeList((mainDeselectedNodeList) => [
			...mainDeselectedNodeList,
			removedItem,
		]);
	}

    return (
        <Modal isOpen={modalAddEditNode} toggle={toggleAddNode} className={"sm"} size="lg">
            <form>
                <ModalHeader toggle={toggleAddNode}>Edit Node</ModalHeader>
                <ModalBody>
                    <div className="container">
                        <Multiselect
                            options={nodeList} // Options to display in the dropdown
                            selectedValues={selectedNodeList} // Preselected value to persist in dropdown
                            onSelect={onSelect} // Function will trigger on select event
                            onRemove={onRemove} // Function will trigger on remove event
                            displayValue="display_name" // Property name to display in the dropdown options
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={onUpdateNode}>
                        Save Node
                    </Button>
                    <Button color="secondary" onClick={toggleAddNode}>
                        Cancel
                    </Button>
                </ModalFooter>
            </form>
        </Modal>
    )
}

export default AddEditNodeModal