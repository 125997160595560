export const defaultSchedulerBlockData = {
    "setting":[
        {
            "stage": "1",
            "description": "",
            "start_date": "",
            "end_date": "",
            "day_growth": "",
            "status": 0,
            "parameter": 
            {
                "session1_state": 0,
                "session1_duration": "0",
                "session1_cycle": "0",
                "session1_medium": 0,
                "session2_state": 0,
                "session2_duration": "0",
                "session2_cycle": "0",
                "session2_medium": 0,
                "session3_state": 0,
                "session3_duration": "0",
                "session3_cycle": "0",
                "session3_medium": 0,              
                "session4_state": 0,
                "session4_duration": "0",
                "session4_radiation": "0",
                "session4_medium": 0,    
                "session5_state": 0,
                "session5_duration": "0",
                "session5_radiation": "0",
                "session5_medium": 0,  
                "session6_state": 0,
                "session6_duration": "0",
                "session6_radiation": "0",
                "session6_medium": 0
            }
        },
        {
            "stage": "2",
            "description": "",
            "start_date": "",
            "end_date": "",
            "day_growth": "",
            "status": 0,
            "parameter": 
            {
                "session1_state": 0,
                "session1_duration": "0",
                "session1_cycle": "0",
                "session1_medium": 0,
                "session2_state": 0,
                "session2_duration": "0",
                "session2_cycle": "0",
                "session2_medium": 0,
                "session3_state": 0,
                "session3_duration": "0",
                "session3_cycle": "0",
                "session3_medium": 0,              
                "session4_state": 0,
                "session4_duration": "0",
                "session4_radiation": "0",
                "session4_medium": 0,    
                "session5_state": 0,
                "session5_duration": "0",
                "session5_radiation": "0",
                "session5_medium": 0,  
                "session6_state": 0,
                "session6_duration": "0",
                "session6_radiation": "0",
                "session6_medium": 0
            }
        },
        {
            "stage": "3",
            "description": "",
            "start_date": "",
            "end_date": "",
            "day_growth": "",
            "status": 0,
            "parameter": 
            {
                "session1_state": 0,
                "session1_duration": "0",
                "session1_cycle": "0",
                "session1_medium": 0,
                "session2_state": 0,
                "session2_duration": "0",
                "session2_cycle": "0",
                "session2_medium": 0,
                "session3_state": 0,
                "session3_duration": "0",
                "session3_cycle": "0",
                "session3_medium": 0,              
                "session4_state": 0,
                "session4_duration": "0",
                "session4_radiation": "0",
                "session4_medium": 0,    
                "session5_state": 0,
                "session5_duration": "0",
                "session5_radiation": "0",
                "session5_medium": 0,  
                "session6_state": 0,
                "session6_duration": "0",
                "session6_radiation": "0",
                "session6_medium": 0
            }
        },
        {
            "stage": "4",
            "description": "",
            "start_date": "",
            "end_date": "",
            "day_growth": "",
            "status": 0,
            "parameter": 
            {
                "session1_state": 0,
                "session1_duration": "0",
                "session1_cycle": "0",
                "session1_medium": 0,
                "session2_state": 0,
                "session2_duration": "0",
                "session2_cycle": "0",
                "session2_medium": 0,
                "session3_state": 0,
                "session3_duration": "0",
                "session3_cycle": "0",
                "session3_medium": 0,              
                "session4_state": 0,
                "session4_duration": "0",
                "session4_radiation": "0",
                "session4_medium": 0,    
                "session5_state": 0,
                "session5_duration": "0",
                "session5_radiation": "0",
                "session5_medium": 0,  
                "session6_state": 0,
                "session6_duration": "0",
                "session6_radiation": "0",
                "session6_medium": 0
            }
        },
        {
            "stage": "5",
            "description": "",
            "start_date": "",
            "end_date": "",
            "day_growth": "",
            "status": 0,
            "parameter": 
            {
                "session1_state": 0,
                "session1_duration": "0",
                "session1_cycle": "0",
                "session1_medium": 0,
                "session2_state": 0,
                "session2_duration": "0",
                "session2_cycle": "0",
                "session2_medium": 0,
                "session3_state": 0,
                "session3_duration": "0",
                "session3_cycle": "0",
                "session3_medium": 0,              
                "session4_state": 0,
                "session4_duration": "0",
                "session4_radiation": "0",
                "session4_medium": 0,    
                "session5_state": 0,
                "session5_duration": "0",
                "session5_radiation": "0",
                "session5_medium": 0,  
                "session6_state": 0,
                "session6_duration": "0",
                "session6_radiation": "0",
                "session6_medium": 0
            }
        },
        {
            "stage": "6",
            "description": "",
            "start_date": "",
            "end_date": "",
            "day_growth": "",
            "status": 0,
            "parameter": 
            {
                "session1_state": 0,
                "session1_duration": "0",
                "session1_cycle": "0",
                "session1_medium": 0,
                "session2_state": 0,
                "session2_duration": "0",
                "session2_cycle": "0",
                "session2_medium": 0,
                "session3_state": 0,
                "session3_duration": "0",
                "session3_cycle": "0",
                "session3_medium": 0,              
                "session4_state": 0,
                "session4_duration": "0",
                "session4_radiation": "0",
                "session4_medium": 0,    
                "session5_state": 0,
                "session5_duration": "0",
                "session5_radiation": "0",
                "session5_medium": 0,  
                "session6_state": 0,
                "session6_duration": "0",
                "session6_radiation": "0",
                "session6_medium": 0
            }
        }
    ]
}