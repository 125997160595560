import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import { successToast, errorToast } from '../../shared/notification'
import LoadingSpinner from '../../shared/Spinner/LoadingSpinner'
import { Grid, Button, TextField, Switch } from '@material-ui/core'
import axiosInstance from "../../shared/api";

const Alarm = () => {
    const { device_id } = useParams()
    const [loading, setLoading] = useState(false)

    const [tab, setTab] = useState("setting")
    const [settings, setSettings] = useState([])
    const [serialName, setSerialName] = useState("")
    const [historyList, setHistoryList] = useState([])

    useEffect(() => {
        console.log(device_id, tab)
        if (tab === "setting") {
            setLoading(true)
            axiosInstance.get(`https://api.cloudatik.com/alarm/v1.0/list/setting/${device_id}`).then( res => {
                setLoading(false)
                console.log("res", res.data)
                setSettings(res.data.setting)
                setSerialName(res.data.serial)
            })
        } else if (tab === "history") {
            setLoading(true)
            axiosInstance.get(`https://api.cloudatik.com/alarm/v1.0/list/${device_id}`).then( res => {
                setLoading(false)
                console.log("res", res.data)
                setSerialName(res.data.serial)
                setHistoryList(res.data.alarm)
            })

        }
    }, [tab])

    function getDate(datetime) {
        const date = new Date(datetime)
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        })
    }
    function getTime(datetime) {
        const date = new Date(datetime)
        return date.toLocaleTimeString('en-US',  {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        })
    }

    async function onSave() {
        // console.log("onSave", settings)
        
        // validate input
        for (let setting of settings) {
            if (setting.category == "slider" &&
                (setting.consecutive_day === "" ||
                setting.limit_high === "" ||
                setting.limit_low === "")
            ) {
                return errorToast("Invalid input. Please fill in all input")
            } else if (setting.category == "threshold" &&
                (setting.consecutive_day === "" ||
                setting.limit_high === "")
            ) {
                return errorToast("Invalid input. Please fill in all input")
            }
        }

        // craft payload
        let payload = []
        for (let setting of settings) {
            let tempObj = {}
            tempObj["consecutive_day"] = Number(setting.consecutive_day)
            tempObj["limit_high"] = parseFloat(setting.limit_high)
            tempObj["limit_low"] = parseFloat(setting.limit_low)
            tempObj["enabled"] = setting.enabled
            tempObj["category"] = setting.category
            tempObj["parameter"] = setting.parameter
            tempObj["unit"] = setting.unit
            tempObj["label"] = setting.label
            payload.push(tempObj)
        }

        setLoading(true)
        const resp = await axiosInstance.post(`https://api.cloudatik.com/alarm/v1.0/save/`, {
            "device_id": device_id,
            "setting": payload
        })

        console.log("resp", resp)
        if (resp.status === 200) {
            setLoading(false)
            successToast(resp.data.message)
        } else {
            setLoading(false)
            errorToast("server error")
        }
    }
    const [saveDisabled, setSaveDisabled] = useState(false)
    const [change, setChange] = useState(false)

    function handleChange(e, field, parameter) {
        // console.log("handleChange", e.target.value, field, parameter)
        setChange(!change)
        if (field === "enabled") {
            // switch
            setSettings(prevList =>  prevList.map(setting => setting.parameter == parameter  ? { ...setting, [field]: e.target.checked } : setting ))
        } else {
            setSettings(prevList => prevList.map(setting => {
                if (setting.parameter == parameter) {
                    if (setting.category === "threshold" && field == "limit_high") {
                        // set limit_low as limit_high else backend will have error
                        return { 
                            ...setting, 
                            limit_high: e.target.value, 
                            limit_low: e.target.value
                        }
                    } else {
                        return { 
                            ...setting, 
                            [field]: e.target.value, 
                        }
                    }
                }
                return setting
            }))
        }
    }

    // Function to validate settings
    const validateSettings = () => {
        let hasError = false
        const updatedSettings = settings.map(setting => {
            let errors = { daysError: false, daysHelperText: "", limitLowError: false, limitLowHelperText: "", limitHighError: false, limitHighHelperText: "" }

            const hasLeadingZero = (input) => {
                return input.startsWith('0') && input.length > 1;
            }

            // Check for leading zeros
            if (hasLeadingZero(setting.limit_high)) {
                hasError = true;
                errors.limitHighError = true;
                errors.limitHighHelperText = "Leading zeros are not allowed";
            } else if (hasLeadingZero(setting.limit_low)) {
                hasError = true;
                errors.limitLowError = true;
                errors.limitLowHelperText = "Leading zeros are not allowed";
            } else if (hasLeadingZero(setting.consecutive_day)) {
                hasError = true;
                errors.daysError = true;
                errors.daysHelperText = "Leading zeros are not allowed";
            }
    
            if (isNaN(Number(setting.limit_high))) {
                hasError = true
                errors.limitHighError = true
                errors.limitHighHelperText = "Invalid input"
            } else if (isNaN(Number(setting.limit_low))) {
                hasError = true
                errors.limitLowError = true
                errors.limitLowHelperText = "Invalid input"
            } else if (isNaN(Number(setting.consecutive_day))) {
                hasError = true
                errors.daysError = true
                errors.daysHelperText = "Invalid input"
            } else if (setting.category == "slider" && Number(setting.limit_high) > 30) {
                hasError = true
                errors.limitHighError = true
                errors.limitHighHelperText = "Limit High max 30"
            } else if (Number(setting.limit_low) < 0) {
                hasError = true
                errors.limitLowError = true
                errors.limitLowHelperText = "Limit Low min 0"
            } else if (Number(setting.limit_high) < Number(setting.limit_low)) {
                hasError = true
                errors.limitHighError = true
                errors.limitHighHelperText = "Limit High must be larger than Limit Low"
            } else if (Number(setting.consecutive_day) > 30 || Number(setting.consecutive_day) < 0) {
                hasError = true
                errors.daysError = true
                errors.daysHelperText = "Consecutive Days min 0 days max 30 days"
            }
    
            return { ...setting, ...errors }
        });
    
        setSettings(updatedSettings)
        setSaveDisabled(hasError)
    }

    useEffect(() => {
        validateSettings()
    }, [change])

    return (
        <>
        {loading && <LoadingSpinner />}
        <Grid container style={{ marginBottom: 20, display: 'flex', justifyContent: "space-evenly",}}>
            <Grid item style={{ textAlign: 'center', height: '50px', width: '30%', fontSize: 15, fontWeight: 'bold' }}>
                <Button variant="contained" color="primary" style={{ width: '100%', height: '100%', fontSize: '15px' }} onClick={()=>{setTab("setting")}}>Setting</Button>
            </Grid>
            <Grid item style={{ textAlign: 'center', height: '50px', width: '30%', marginLeft: '20px', fontSize: 15, fontWeight: 'bold' }}>
                <Button variant="contained" color="primary" style={{ width: '100%', height: '100%', fontSize: '15px' }} onClick={()=>{setTab("history")}}>History</Button>
            </Grid>
        </Grid>

        <Grid style={{ marginBottom: 20, display: 'flex', justifyContent: "space-evenly", fontSize: 30}}>
            {serialName}
        </Grid>

        {tab == "setting" && (
        <Grid container style={{ display: "flex", justifyContent: "center", padding: "0 2.5rem", paddingBottom: "3rem"}}>
            {settings.map( (setting, index) => (
            <Grid className="card" item xs={10} s={8} style={{marginBottom: "2rem"}} key={index}>
                <div className="card-header">
                    <h5 className="card-title">{setting.label}</h5>
                </div>
                <div className="card-body" style={{textAlign:"center"}}>
                    <div className="row">
                        <div className="col-12">
                            <span>Alarm</span>
                            <Switch checked={setting.enabled} color="primary" onChange={(e)=>{handleChange(e, "enabled", setting.parameter)}}/>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-12">
                            <TextField error={setting.daysError} helperText={setting.daysHelperText} style={{ width: '100%' }} type="number" value={setting.consecutive_day || ""} label={"Consecutive Days (Days)"} onChange={(e)=>{handleChange(e, "consecutive_day", setting.parameter)}}/>
                        </div>
                        {setting.category === "slider" && (
                        <div className="col-12">
                            <TextField error={setting.limitLowError} helperText={setting.limitLowHelperText} type="number" style={{ width: '100%' }} value={setting.limit_low} label={`Low Limit (${setting.unit})`} onChange={(e)=>{handleChange(e, "limit_low", setting.parameter)}}/>
                        </div>
                        )}
                        <div className="col-12">
                            <TextField error={setting.limitHighError} helperText={setting.limitHighHelperText} type="number" style={{ width: '100%' }} value={setting.limit_high} label={`High Limit (${setting.unit})`} onChange={(e)=>{handleChange(e, "limit_high", setting.parameter)}}/>
                        </div>
                    </div>
                </div>
            </Grid>
            ))}
            {settings.length > 0 && (
                <Button variant="contained" color="primary" style={{width: "50%",  height: '100%', fontSize: '15px', marginTop: "1.5rem" }} disabled={saveDisabled} onClick={() => onSave()}>Save</Button>
            )}
        </Grid>
        )}

        {(tab == "history" && historyList.length > 0) && (
        <Grid container style={{ marginTop: 20, marginBottom: 20, display: 'flex', padding: "0 1rem", overflowX: "auto"}}>
            <table className="table" style={{ width: '100%'}}>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Parameter</th>
                        <th>Value</th>
                        <th>Lower Limit</th>
                        <th>Higher Limit</th>
                    </tr>
                </thead>
                <tbody>
                    {historyList.map((item, index) => (
                        <tr key={index}>
                            <td>{getDate(item.datetime)}</td>
                            <td>{getTime(item.datetime)}</td>
                            <td>{item.parameter}</td>
                            <td>{item.value}</td>
                            <td>{item.limit_lower}</td>
                            <td>{item.limit_upper}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Grid>
        )}
        {(tab == "history" && historyList.length == 0) && (
            <Grid container style={{ marginBottom: 20, marginTop: 20, display: 'flex', justifyContent: "space-evenly",}}>
                <h3>No Alarm Data</h3>
            </Grid>
        )}
        </>
    )
}

export default Alarm