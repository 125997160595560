import { Card, CardContent, Grid } from '@material-ui/core'
import { Button, TextField, InputLabel, Modal, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, Select, MenuItem, FormControl } from '@mui/material/';
import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { errorToast, successToast } from "../../../shared/notification";
import LoadingSpinner from '../../../shared/Spinner/LoadingSpinner';
import { Link, useParams } from "react-router-dom";

const BlockSetting = ({
    blockSettings,
    currentSessionIndex, setCurrentSessionIndex,
    currentBlockIndex, setCurrentBlockIndex,
    setBlockSettings,
    uploadController
}) => {
    
    const [showAllSession, setShowAllSession] = useState(false)

    function handleInputChange(e, field, key=null) {
        if (key) {
            // Show All session input change
            setBlockSettings(prev=> ({
                ...prev,
                [currentBlockIndex] : {
                    ...prev[currentBlockIndex],
                    [key]: {
                        ...prev[currentBlockIndex][key],
                        [field]:  parseFloat(e.target.value)
                    }
                }
            }))
        } else {
            setBlockSettings(prev=> ({
                ...prev,
                [currentBlockIndex] : {
                    ...prev[currentBlockIndex],
                    [currentSessionIndex]: {
                        ...prev[currentBlockIndex][currentSessionIndex],
                        [field]:  parseFloat(e.target.value)
                    }
                }
            }))
        }
        // setBlockSettings(prev=> ({
        //     ...prev,
        //     [blockSettings[currentBlockIndex][currentSessionIndex][field]]: e.target.value
        // }))
    }

    function handleInputChange(e, field, key=null) {
        console.log(e.target.value, blockSettings, blockSettings[currentBlockIndex][currentSessionIndex][field])

        if (key) {
            // Show All session input change
            setBlockSettings(prev=> ({
                ...prev,
                [currentBlockIndex] : {
                    ...prev[currentBlockIndex],
                    [key]: {
                        ...prev[currentBlockIndex][key],
                        [field]:  parseFloat(e.target.value)
                    }
                }
            }))
        } else {
            setBlockSettings(prev=> ({
                ...prev,
                [currentBlockIndex] : {
                    ...prev[currentBlockIndex],
                    [currentSessionIndex]: {
                        ...prev[currentBlockIndex][currentSessionIndex],
                        [field]:  parseFloat(e.target.value)
                    }
                }
            }))
        }
        // setBlockSettings(prev=> ({
        //     ...prev,
        //     [blockSettings[currentBlockIndex][currentSessionIndex][field]]: e.target.value
        // }))
    }

    function handleSwitchChange(e, field, key) {
        // console.log(e.target.value , blockSettings[currentBlockIndex][currentSessionIndex]["medium"], blockSettings[currentBlockIndex][currentSessionIndex]["io_sc"])

        if (key) {
            if (isNaN(blockSettings[currentBlockIndex][key]["duration"])) return alert("Invalid input")
            let val = 0
            if (blockSettings[currentBlockIndex][key][field] == 0) {
                val = 1
            }
            // Show All session input change
            setBlockSettings(prev=> ({
                ...prev,
                [currentBlockIndex] : {
                    ...prev[currentBlockIndex],
                    [key]: {
                        ...prev[currentBlockIndex][key],
                        [field]:  val
                    }
                }
            }))
        } else {
            if (isNaN(blockSettings[currentBlockIndex][currentSessionIndex]["duration"])) return alert("Invalid input")
            let val = 0
            if (blockSettings[currentBlockIndex][currentSessionIndex][field] == 0) {
                val = 1
            }

            setBlockSettings(prev=> ({
                ...prev,
                [currentBlockIndex] : {
                    ...prev[currentBlockIndex],
                    [currentSessionIndex]: {
                        ...prev[currentBlockIndex][currentSessionIndex],
                        [field]: val
                    }
                }
            }))
        }
    }

    function handleBlkDisable(value) {
        setBlockSettings(prev=> ({
            ...prev,
            [currentBlockIndex] : {
                ...prev[currentBlockIndex],
                "blkdisable" : value == 1 ? 0 : 1
            }
        }))
    }
    
    function validateNan() {
        for (let ses in blockSettings[currentBlockIndex]) {
            if (ses != "blkdisable") {
                if (isNaN(blockSettings[currentBlockIndex][ses]["duration"])) {
                    return false
                }
            }
        }
        return true
    }

    return (
        <>
        <div className='d-flex justify-content-between w-100 m-3'>
            <Button variant="outlined" onClick={()=> {
                if (currentBlockIndex == 1) {
                    setCurrentBlockIndex(5)
                } else {
                    setCurrentBlockIndex(currentBlockIndex - 1)
                }
            }}><ArrowBackIos/></Button>
            <h4>Block {currentBlockIndex}</h4>
            <Button variant="outlined" onClick={()=> {
                if (currentBlockIndex == 5) {
                    setCurrentBlockIndex(1)
                } else {
                    setCurrentBlockIndex(currentBlockIndex + 1)
                }
            }}><ArrowForwardIos/></Button>
        </div>
        <div className='d-flex justify-content-center w-100 mt-3'>
        <h6>Block Setting</h6>
        </div>
        <div className='d-flex justify-content-center w-100 mb-3'>
            <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'center'}}><p>Disable</p></div>
            <Switch
            checked={blockSettings[currentBlockIndex]["blkdisable"] == 0} 
            onChange={(e)=>{
                if(!validateNan()) return alert("Invalid Input")
                setBlockSettings(prev=> ({
                    ...prev,
                    [currentBlockIndex] : {
                        ...prev[currentBlockIndex],
                        blkdisable : e.target.checked ? 0 : 1
                    }
                }))
             }}/>
             <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'center'}}><p>Enable</p></div>
        </div>

        {showAllSession ? (
            Object.keys(blockSettings[currentBlockIndex]).map((key)=> {
                return (
                    <Grid key={key} container spacing={2} style={{
                        border: '1px solid gray',
                        borderRadius: '10px',
                        margin: '2%'
                    }}>
                        <div style={{margin: '1% auto'}}>
                            <h4>Session {getSessionNum(key)}</h4>
                        </div>
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <Grid item xs={12} md={6} style={{display:'flex', justifyContent:'space-evenly'}}>
                                    <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'center'}}><p>Disable</p></div>
                                    <Switch disabled={blockSettings[currentBlockIndex]["blkdisable"] == 1 ? true : false} checked={getCheckedBoolValue(blockSettings[currentBlockIndex][key]["io_sc"])} onChange={(e)=>{ handleSwitchChange(e, "io_sc", key) }}/>
                                    <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'center'}}><p>Enable</p></div>
                                </Grid>
                                <Grid item xs={12} md={6} style={{display:'flex', justifyContent:'space-evenly'}}>
                                    <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'center'}}><p>Water</p></div>
                                    <Switch disabled={blockSettings[currentBlockIndex]["blkdisable"] == 1 ? true : false} checked={getCheckedBoolValue(blockSettings[currentBlockIndex][key]["medium"])} onChange={(e)=>{ handleSwitchChange(e, "medium", key) }}/>
                                    <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'center'}}><p>Fertilizer</p></div>
                                </Grid>
                            </Grid>
                        <Grid item xs={12}>
                            <TextField disabled={blockSettings[currentBlockIndex]["blkdisable"] == 1 ? true : false} fullWidth label="Duration (min)" variant="outlined" value={blockSettings[currentBlockIndex][key]["duration"]} onChange={(e)=>{ handleInputChange(e, "duration", key) }}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField disabled={blockSettings[currentBlockIndex]["blkdisable"] == 1 ? true : false} id="outlined-basic" fullWidth label="Radiation Setting (J/cm2)" variant="outlined" value={blockSettings[currentBlockIndex][key]["sppar"]} onChange={(e)=>{ handleInputChange(e, "sppar", key) }}/>
                        </Grid>
                    </Grid>
                )
            })
        ) : (
            <Grid container spacing={2} style={{
                border: '1px solid gray',
                borderRadius: '10px',
                margin: '2%'
            }}>
                <div className='d-flex justify-content-between w-100 m-3'>
                    <Button variant="outlined" onClick={()=> {
                        if (getSessionNum(currentSessionIndex) == 1) {
                            setCurrentSessionIndex("f")
                        } else {
                            let desc = getSessionNum(currentSessionIndex) - 1
                            setCurrentSessionIndex(getSessionLetter(desc))
                        }
                    }}><ArrowBackIos/></Button>
                    <h4>Session {getSessionNum(currentSessionIndex)}</h4>
                    <Button variant="outlined" onClick={()=> {
                        if (getSessionNum(currentSessionIndex) == 6) {
                            setCurrentSessionIndex("a")
                        } else {
                            let inc = getSessionNum(currentSessionIndex) + 1
                            setCurrentSessionIndex(getSessionLetter(inc))
                        }
                    }}><ArrowForwardIos/></Button>
                </div>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={6} style={{display:'flex', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'center'}}><p>Disable</p></div>
                        <Switch disabled={blockSettings[currentBlockIndex]["blkdisable"] == 1 ? true : false} checked={getCheckedBoolValue(blockSettings[currentBlockIndex][currentSessionIndex]["io_sc"])} onChange={(e)=>{ handleSwitchChange(e, "io_sc") }}/>
                        <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'center'}}><p>Enable</p></div>
                    </Grid>
                    <Grid item xs={12} md={6} style={{display:'flex', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'center'}}><p>Water</p></div>
                        <Switch disabled={blockSettings[currentBlockIndex]["blkdisable"] == 1 ? true : false} checked={getCheckedBoolValue(blockSettings[currentBlockIndex][currentSessionIndex]["medium"])} onChange={(e)=>{ handleSwitchChange(e, "medium") }}/>
                        <div style={{display: 'flex', flexFlow: 'column', justifyContent: 'center'}}><p>Fertilizer</p></div>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextField disabled={blockSettings[currentBlockIndex]["blkdisable"] == 1 ? true : false} type='number' fullWidth label="Duration (min)" variant="outlined" value={blockSettings[currentBlockIndex][currentSessionIndex]["duration"]} onChange={(e)=>{ handleInputChange(e, "duration") }}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField disabled={blockSettings[currentBlockIndex]["blkdisable"] == 1 ? true : false} type='number' id="outlined-basic" fullWidth label="Radiation Setting (J/cm2)" variant="outlined" value={blockSettings[currentBlockIndex][currentSessionIndex]["sppar"]} onChange={(e)=>{ handleInputChange(e, "sppar") }}/>
                </Grid>
            </Grid>
        )}


        <Grid item xs={12}>
            <Button color='info' fullWidth variant="outlined" onClick={()=>{setShowAllSession(!showAllSession)}}>
                {showAllSession ? 'Hide ' : 'Show '} all session</Button>
        </Grid>
        
        <Grid item xs={12}>
            <Button color='warning' fullWidth variant="contained" onClick={()=>{
                if(!validateNan()) return alert("Invalid Input")
                uploadController()
            }}>Upload to Controller</Button>
        </Grid>
        </>
    )
}



const getCheckedBoolValue =(val)=>{
    if (val == 1) {
        return true
    } else {
        return false
    }
}
const getSessionNum = (letter) => {
    switch (letter) {
        case 'a':
            return 1
            break;
        case 'b':
            return 2
            break;
        case 'c':
            return 3
            break;
        case 'd':
            return 4
            break;
        case 'e':
            return 5
            break;
        case 'f':
            return 6
            break;
    }
}
const getSessionLetter = (num) => {
    switch (num) {
        case 1:
            return 'a'
            break;
        case 2:
            return 'b'
            break;
        case 3:
            return 'c'
            break;
        case 4:
            return 'd'
            break;
        case 5:
            return 'e'
            break;
        case 6:
            return 'f'
            break;
    }
}

export default BlockSetting