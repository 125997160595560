import { Component } from "react";
import axiosInstance from "../shared/api";

export class profile extends Component {
    static myInstance = null;

    static getInstance() {
        return new profile();
    }

    async saveProfileDetails(dataToSubmit) {
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL
                }/saveUserInfo/${localStorage.getItem("cloudatik")}/?format=json`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': localStorage.getItem('cloudatik-access')
                    },

                    body: JSON.stringify(dataToSubmit),
                }
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            console.error("/saveUserCategory", error);
        }
    }

    async updateProfileDetails(dataToSubmit) {
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL}/changePassword/`,
                {
                    method: "POST",
                    headers: {
                        'Authorization': localStorage.getItem('cloudatik-access'),
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(dataToSubmit),
                }
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            console.error("/changePassword", error);
        }
    }

    async getProfileDetails() {
        try {
            let response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/user/v2.0/info/`, { headers: {
                'Authorization': localStorage.getItem('cloudatik-access'),
            }})
            if (response.status === 200) {
                return response.data
            }
            return {}
        } catch (error) {
            console.error("/getUserInfo", error);
        }
    }
}
export default profile;
