import React, { useEffect, useState } from "react";
import "./card-category.css";
import CardSingleNode from "../single-node";
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import AddEditNodeModal from "../../Node/AddEditNodeModal";
import EditCategoryModal from "../../Node/EditCategoryModal";
import LoadingSpinner from "../../../shared/Spinner/LoadingSpinner";
import category from "../../../services/node-category/category";
import { errorToast, successToast } from "../../../shared/notification";
import CardSingleNodeV3 from "../single-node/indexV3";


function CardCategoryV3({ categoryName, node, ms }) {
  node = node === undefined ? [] : node;

  const [loading, setLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(node.length === 0 && true);

  const [addEditNodeModal, setAddEditNodeModal] = useState(false)
  const [modalEditCat, setModalEditCat] = useState(false)

  const onRedirect = (device, id) => {
    window.location.href = "#/v2/node?device=" + device + "&device_id=" + id;
  };

  useEffect(() => {
    setIsEmpty(node.length === 0 && true);
  }, [node]);

  const cardHeaderColors = [];
  // const cardHeaderColors = [
  //   "#FFADAD", "#FFD6A5", "#FDFFB6", "#CAFFBF", "#9BF6FF", "#A0C4FF", "#BDB2FF", "#FFC6FF"
  // ];

  const cardHeaderColorIndex = ms;
  const cardColorHeader = cardHeaderColors[cardHeaderColorIndex];

    function onDeleteCategory() {
        let dataToSubmit = {}

        setLoading(true);
        category.getInstance().getCategory().then((result) => {
            result.map(r => {
                if (categoryName == r.category) {
                    dataToSubmit = {
                        id: r.id,
                    }
                    category.getInstance().deleteCategory(dataToSubmit).then((result) => {
                        setLoading(false)
                        if (result["success"]) {
                            successToast(result["message"])
                            setTimeout(() => {
                                window.location.reload()
                            }, 1500)
                        } else {
                            errorToast('Something went wrong. Please contact administrator.')
                        }
                    })
                    return null
                }
            })

            
        })
    }
  return (
    <>
    {loading && <LoadingSpinner />}
    <div className="card card-container h-100 mx-auto">
        <div className="card-header" style={{ backgroundColor: cardColorHeader, display:'flex', justifyContent: 'space-between' }}>
            <h5>{categoryName}</h5>

            <div className="dropdown show">
                <a className="btn  btn-sm" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <MoreHoriz fontSize="small"/>
                </a>

                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a className="dropdown-item" href="javascript:void(0)" onClick={() => setAddEditNodeModal(true)}>Add/Edit Node</a>
                    <a className="dropdown-item" href="javascript:void(0)" onClick={() => setModalEditCat(true)}>Edit Category Name</a>
                    <a className="dropdown-item" href="javascript:void(0)" onClick={() => {
                        if (window.confirm('Are you sure you want to delete category?')) {
                            onDeleteCategory()
                        }
                    }}>Delete Category</a>
                </div>
            </div>
        </div>
      <div
        className="card-body h-100"
      >
        {isEmpty && <h6 className="p-2 text-center">Category is empty.</h6>}
        {node.map((node, index) => (
          <div key={index}>
            <CardSingleNodeV3
              node={node}
              lcdId={"category_led_" + index}
              ms={ms}
            />
          </div>
        ))}
      </div>
    </div>

    <AddEditNodeModal 
        modalAddEditNode={addEditNodeModal} 
        setAddEditNode={setAddEditNodeModal} 
        categoryName={categoryName}
        setLoading={setLoading}
    />

    <EditCategoryModal 
        modalEditCat={modalEditCat}
        setModalEditCat={setModalEditCat}
        categoryName={categoryName}
        setLoading={setLoading}
    />
    </>
  );
}

export default CardCategoryV3
