import { Component } from "react";
import { utcToMtyConvertionDisplay } from "../../shared/DateTimeConvertion";
import { refetchApi } from '../../shared/utils';

export class category extends Component {
	static myInstance = null;

	static getInstance() {
		return new category();
	}

	async getCategory() {
		try {
			let response = await fetch(`${process.env.REACT_APP_API_URL}/getUserCategoryV2/`, {
				headers: {
					'Authorization': localStorage.getItem('cloudatik-access')
				}
			});

			let responseJson = await response.json();
			if (responseJson.code && responseJson.code === 'token_not_valid') {
				refetchApi();
			}

			return responseJson;
		} catch (error) {
			console.error(error);
		}
	}

	async addCategory(dataToSubmit) {
		try {
			let response = await fetch(
				`${process.env.REACT_APP_API_URL}/saveUserCategoryV2/`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						'Authorization': localStorage.getItem('cloudatik-access')
					},

					body: JSON.stringify(dataToSubmit),
				}
			);
			let responseJson = await response.json();
			return responseJson;
		} catch (error) {
			console.error("/saveUserCategory", error);
		}
	}

	async deleteCategory(dataToSubmit) {
		try {
			let response = await fetch(
				`${process.env.REACT_APP_API_URL}/deleteUserCategoryV2/`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						'Authorization': localStorage.getItem('cloudatik-access')
					},
					body: JSON.stringify(dataToSubmit),
				}
			);
			let responseJson = await response.json();
			return responseJson;
		} catch (error) {
			console.error("/deleteUserCategory", error);
		}
	}

	async updateCategory(dataToSubmit) {
		try {
			let response = await fetch(
				`${process.env.REACT_APP_API_URL}/updateUserCategoryV2/`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						'Authorization': localStorage.getItem('cloudatik-access')
					},
					body: JSON.stringify(dataToSubmit),
				}
			);
			let responseJson = await response.json();
			return responseJson;
		} catch (error) {
			console.error("/updateUserCategory", error);
		}
	}

	async updateCategoryNode(dataToSubmit) {
		try {
			let response = await fetch(
				`${process.env.REACT_APP_API_URL}/saveUserNodeCategory/`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						'Authorization': localStorage.getItem('cloudatik-access')
					},
					body: JSON.stringify(dataToSubmit),
				}
			);
			let responseJson = await response.json();
			return responseJson;
		} catch (error) {
			console.error("/saveUserNodeCategory", error);
		}
	}
}
export default category;
