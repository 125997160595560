import { Card, CardContent, Grid } from '@material-ui/core'
import { Button, TextField, InputLabel, Modal, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, Select, MenuItem, FormControl } from '@mui/material/';
import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { errorToast, successToast } from "../../shared/notification";
import LoadingSpinner from '../../shared/Spinner/LoadingSpinner';
import { Link, useParams } from "react-router-dom";
import BlockSetting from "./component/BlockSetting";
import SessionSetting from "./component/SessionSetting";
import AR00XX1TBBlockSettingDb from './AR00XX1TB/component/AR00XX1TBBlockSettingDb';
import AR00XX3TBBlockSettingDb from './AR00XX3TB/component/AR00XX3TBBlockSettingDb';
import AR00XX1TBSessionSettingDb from "./AR00XX1TB/component/AR00XX1TBSessionSettingDb";
import AR00XX3TBSessionSettingDb from "./AR00XX3TB/component/AR00XX3TBSessionSettingDb";
import AR00XXPlusBlockSettingDb from './AR00XXPlus/component/AR00XXPlusBlockSettingDb';
import AR00XXPlusSessionSettingDb from "./AR00XXPlus/component/AR00XXPlusSessionSettingDb";
import axiosInstance from '../../shared/api';

var mqtt = require('mqtt');

const MQTT_USERNAME = process.env.REACT_APP_MQTT_USERNAME
const MQTT_PASSWORD = process.env.REACT_APP_MQTT_PASSWORD
const MQTT_SERVER = process.env.REACT_APP_MQTT_SERVER
const MQTT_PORT = process.env.REACT_APP_MQTT_PORT

const config = {
    headers: {
        'Authorization': localStorage.getItem('cloudatik-access'),
        "Content-Type": "application/json",
    }
}

const BlockSettingDb = () => {
    let { device, device_id } = useParams()
    // let device = 'ar00xx'
    // let device_id = 'LWZA0i'
    const [loading, setLoading] = useState(false)
    const [recipeList, setRecipeList] = useState([])
    const [recipe, setRecipe] = useState({
        id: 0,
        name: ''
    })

    // FROM CONTROLLER. USE TO READ ONLY.
    const [sessionSettings, setSessionSettings] = useState({})

    const [currentBlockIndex, setCurrentBlockIndex] = useState(1)
    const [currentSessionIndex, setCurrentSessionIndex] = useState("a")

    const [toggleAddModal, setToggleAddModal] = useState(false)

    const [newRecipeName, setNewRecipeName] = useState('')
    
    const [showBlockForm, setShowBlockForm] = useState(false)
    const [showSessionForm, setShowSessionForm] = useState(false)

    const [disabledButton, setDisabledButton] = useState(true)

    const [progressPercentage, setProgressPercentage] = useState(0)
    const [progressLabel, setProgressLabel] = useState('Loading...')
    const [toggleProgressModal, setToggleProgressModal] = useState(false)
    const [executed, setExecuted] = useState(false)

    useEffect(() => {
        getRecipeList()
    }, [])

    // need to put defaultBlockData inside function bcs when create new recipe, if outside block, the default Value will change.. Weird.
    const defaultBlockData = {
        1 : {
            a: { io_sc : '0', duration: '0', medium: '0', sppar: '0', cycle: "1", ec: "0"},
            b: { io_sc : '0', duration: '0', medium: '0', sppar: '0', cycle: "1", ec: "0"},
            c: { io_sc : '0', duration: '0', medium: '0', sppar: '0', cycle: "1", ec: "0"},
            d: { io_sc : '0', duration: '0', medium: '0', sppar: '0', ec: "0"},
            e: { io_sc : '0', duration: '0', medium: '0', sppar: '0', ec: "0"},
            f: { io_sc : '0', duration: '0', medium: '0', sppar: '0', ec: "0"},
            blkdisable: "0"
        },
        2 : {
            a: { io_sc : '0', duration: '0', medium: '0', sppar: '0', cycle: "1", ec: "0"},
            b: { io_sc : '0', duration: '0', medium: '0', sppar: '0', cycle: "1", ec: "0"},
            c: { io_sc : '0', duration: '0', medium: '0', sppar: '0', cycle: "1", ec: "0"},
            d: { io_sc : '0', duration: '0', medium: '0', sppar: '0', ec: "0"},
            e: { io_sc : '0', duration: '0', medium: '0', sppar: '0', ec: "0"},
            f: { io_sc : '0', duration: '0', medium: '0', sppar: '0', ec: "0"},
            blkdisable: "0"
        },
        3 : {
            a: { io_sc : '0', duration: '0', medium: '0', sppar: '0', cycle: "1", ec: "0"},
            b: { io_sc : '0', duration: '0', medium: '0', sppar: '0', cycle: "1", ec: "0"},
            c: { io_sc : '0', duration: '0', medium: '0', sppar: '0', cycle: "1", ec: "0"},
            d: { io_sc : '0', duration: '0', medium: '0', sppar: '0', ec: "0"},
            e: { io_sc : '0', duration: '0', medium: '0', sppar: '0', ec: "0"},
            f: { io_sc : '0', duration: '0', medium: '0', sppar: '0', ec: "0"},
            blkdisable: "0"
        },
        4 : {
            a: { io_sc : '0', duration: '0', medium: '0', sppar: '0', cycle: "1", ec: "0"},
            b: { io_sc : '0', duration: '0', medium: '0', sppar: '0', cycle: "1", ec: "0"},
            c: { io_sc : '0', duration: '0', medium: '0', sppar: '0', cycle: "1", ec: "0"},
            d: { io_sc : '0', duration: '0', medium: '0', sppar: '0', ec: "0"},
            e: { io_sc : '0', duration: '0', medium: '0', sppar: '0', ec: "0"},
            f: { io_sc : '0', duration: '0', medium: '0', sppar: '0', ec: "0"},
            blkdisable: "0"
        },
        5 : {
            a: { io_sc : '0', duration: '0', medium: '0', sppar: '0', cycle: "1", ec: "0"},
            b: { io_sc : '0', duration: '0', medium: '0', sppar: '0', cycle: "1", ec: "0"},
            c: { io_sc : '0', duration: '0', medium: '0', sppar: '0', cycle: "1", ec: "0"},
            d: { io_sc : '0', duration: '0', medium: '0', sppar: '0', ec: "0"},
            e: { io_sc : '0', duration: '0', medium: '0', sppar: '0', ec: "0"},
            f: { io_sc : '0', duration: '0', medium: '0', sppar: '0', ec: "0"},
            blkdisable: "0"
        },
    }
    const defaultSessionData = {
        a: { io_sc : 0, sc_h: '0', sc_m: '0', sc_d: "1"},
        b: { io_sc : 0, sc_h: '0', sc_m: '0', sc_d: "1"},
        c: { io_sc : 0, sc_h: '0', sc_m: '0', sc_d: "1"},
        d: { io_sc : 0, sc_h: '0', sc_m: '0'},
        e: { io_sc : 0, sc_h: '0', sc_m: '0'},
        f: { io_sc : 0, sc_h: '0', sc_m: '0'},
        end: {sc_h : '0', sc_m: '0'}
    }
    
    const defaultCreatePayload = {
        "session" : defaultSessionData,
        "block": defaultBlockData
    }
    
    const [blockSettings, setBlockSettings] = useState(defaultBlockData)

    function getRecipeList() {
        setLoading(true)
        axiosInstance.get(`https://api.cloudatik.com/recipe/read/${device}/?format=json`).then(res=> {
            setLoading(false)
            console.log(12, res.data)
            setRecipeList(res.data)
            // setRecipe(res.data[0])
            // if (typeof JSON.parse(res.data[0].payload.replace(';','')).block == 'undefined') {
                setBlockSettings(defaultBlockData)
                setSessionSettings(defaultSessionData)
            // } else {
            //     setBlockSettings(JSON.parse(res.data[0].payload.replace(';','')).block)
            //     setSessionSettings(JSON.parse(res.data[0].payload.replace(';','')).session)
            // }

        }).catch(err=> {
            setLoading(false)
            setBlockSettings(defaultBlockData)
            setSessionSettings(defaultSessionData)
            console.log(err)
        })
    }

    const onChangeRecipe = (e) => {
        setDisabledButton(false)
        recipeList.map( r => {
            if (r.name == e.target.value) {
                setRecipe(r)
                setBlockSettings(JSON.parse(r.payload.replace(';','')).block)
                setSessionSettings(JSON.parse(r.payload.replace(';','')).session)
            }
        })

    }

    

    function update() {
        // validation
        for (let blk in blockSettings) {
            if (blockSettings[blk]["a"]["duration"] > 60 || blockSettings[blk]["a"]["duration"] < 0) {
                return alert("Invalid Input of duration and radiation")
            }
            if (blockSettings[blk]["b"]["duration"] > 60 || blockSettings[blk]["b"]["duration"] < 0) {
                return alert("Invalid Input of duration and radiation")
            }
            if (blockSettings[blk]["c"]["duration"] > 60 || blockSettings[blk]["c"]["duration"] < 0) {
                return alert("Invalid Input of duration and radiation")
            }
            if (blockSettings[blk]["d"]["duration"] > 60 || blockSettings[blk]["d"]["duration"] < 0 || blockSettings[blk]["d"]["sppar"] > 1000 || blockSettings[blk]["d"]["sppar"] > 1000) {
                return alert("Invalid Input of duration and radiation")
            }
            if (blockSettings[blk]["e"]["duration"] > 60 || blockSettings[blk]["e"]["duration"] < 0 || blockSettings[blk]["e"]["sppar"] > 1000 || blockSettings[blk]["e"]["sppar"] > 1000) {
                return alert("Invalid Input of duration and radiation")
            }
            if (blockSettings[blk]["f"]["duration"] > 60 || blockSettings[blk]["f"]["duration"] < 0 || blockSettings[blk]["f"]["sppar"] > 1000 || blockSettings[blk]["f"]["sppar"] > 1000) {
                return alert("Invalid Input of duration and radiation")
            }
        }

        if (recipe.id == 0) return errorToast("Please select a Recipe")

        let data = {
            name: recipe.name,
            device: recipe.device,
            payload: JSON.stringify({
                session: sessionSettings,
                block: blockSettings
            })
        }

        setLoading(true)
        axiosInstance.post(`https://api.cloudatik.com/recipe/update/${recipe.id}/`, data).then(res=> {
            setLoading(false)
            successToast('Successfully Updated')
            window.location.reload()
        }).catch(err=>{
            setLoading(false)
            errorToast("Something went wrong. Please contact Administrator.")
        })
    }

    function handleDelete() {
        if (recipe.id == 0) return errorToast("Please select a Recipe")
        setLoading(true)
        axiosInstance.delete(`https://api.cloudatik.com/recipe/delete/${recipe.id}/`).then(res=> {
            setLoading(false)
            successToast('Successfully Deleted')
            window.location.reload()
        }).catch(err=>{
            setLoading(false)
            errorToast("Something went wrong. Please contact Administrator.")
        })
    }


    function create() {
        // validation
        for (let blk in blockSettings) {
            if (blockSettings[blk]["a"]["duration"] > 60 || blockSettings[blk]["a"]["duration"] < 0) {
                return alert("Invalid Input of duration and radiation")
            }
            if (blockSettings[blk]["b"]["duration"] > 60 || blockSettings[blk]["b"]["duration"] < 0) {
                return alert("Invalid Input of duration and radiation")
            }
            if (blockSettings[blk]["c"]["duration"] > 60 || blockSettings[blk]["c"]["duration"] < 0) {
                return alert("Invalid Input of duration and radiation")
            }
            if (blockSettings[blk]["d"]["duration"] > 60 || blockSettings[blk]["d"]["duration"] < 0 || blockSettings[blk]["d"]["sppar"] > 1000 || blockSettings[blk]["d"]["sppar"] > 1000) {
                return alert("Invalid Input of duration and radiation")
            }
            if (blockSettings[blk]["e"]["duration"] > 60 || blockSettings[blk]["e"]["duration"] < 0 || blockSettings[blk]["e"]["sppar"] > 1000 || blockSettings[blk]["e"]["sppar"] > 1000) {
                return alert("Invalid Input of duration and radiation")
            }
            if (blockSettings[blk]["f"]["duration"] > 60 || blockSettings[blk]["f"]["duration"] < 0 || blockSettings[blk]["f"]["sppar"] > 1000 || blockSettings[blk]["f"]["sppar"] > 1000) {
                return alert("Invalid Input of duration and radiation")
            }
        }



        setLoading(true)
        axiosInstance.post(`https://api.cloudatik.com/recipe/create/`, {
            "payload": JSON.stringify(
                {
                    "session" : sessionSettings,
                    "block": blockSettings
                }
            ),
            "name": newRecipeName,
            "device": device
        }).then(res=> {
            setLoading(false)
            successToast('Successfully Added')
            window.location.reload()
        }).catch(err=>{
            setLoading(false)
            errorToast("Something went wrong. Please contact Administrator.")
        })
    }


    const loadFromController=()=>{
        setProgressLabel("Loading...")
        setToggleProgressModal(true)
        setProgressPercentage(0)
        // wss://servertwo.cloudatik.com:9001
        // wss://imre.cloudatik.com:8192
        let srrdPayload1 = `{"messageId":"PAYLOAD1","devices":"block1","mode":1}`
        let srrdPayload2 = `{"messageId":"PAYLOAD2","devices":"block2","mode":1}`
        let srrdPayload3 = `{"messageId":"PAYLOAD3","devices":"block3","mode":1}`
        let srrdPayload4 = `{"messageId":"PAYLOAD4","devices":"block4","mode":1}`
        let srrdPayload5 = `{"messageId":"PAYLOAD5","devices":"block5","mode":1}`
        let srrdPayload_Session = `{"messageId":"SESSION1","devices":"session","mode":1}`

        // console.log(srrdPayload)
    
        let server = `wss://${MQTT_SERVER}:${MQTT_PORT}`
        // console.log(`*** connecting to ${server}`);
        let client = mqtt.connect(server, {
            username: MQTT_USERNAME,
            password: MQTT_PASSWORD
        })

        client.on('connect', function () {
            console.log("*** connected.");
            console.log(`*** subscribing to farm/srrp/${device_id}...`);
            client.subscribe('farm/srrp/' + device_id, function (err) {
                // console.log(123, err)
                if (!err) {
                    console.log(`*** subscribed to farm/srrp/${device_id}.`);
                    console.log(`*** publishing to farm/srrd/${device_id}...`);
                    client.publish('farm/srrd/' + device_id, srrdPayload1);
                    clearAndResetTimeoutWithRefresh()
                }
            });
        });

        client.on('message', function (topic, message) {
            const responseMessage = JSON.parse(message);
            console.log(`*** message received from topic: ${topic}.`);
            console.log(`*** responseMessage:`, responseMessage.devices);
            // client.end();

            let data = defaultBlockData

            const message_json = JSON.parse(message.toString())
            console.log(123, message_json)

            if (message_json.messageId == "PAYLOAD1") {
                setProgressPercentage(20)
                setExecuted(true)
                clearAndResetTimeoutWithRefresh()
                setTimeout(() => {
                    client.publish('farm/srrd/' + device_id, srrdPayload2);
                },1000)

                let block_array = [1]
                let session_array = ['a', 'b', 'c', 'd', 'e', 'f']
    
                for (let i in block_array) {
                    for (let x in session_array) {
                        data[block_array[i]][session_array[x]]["io_sc"] = responseMessage.devices[0].deviceData["io_sc_" + session_array[x]]
                        data[block_array[i]][session_array[x]]["medium"] = responseMessage.devices[0].deviceData["medium_" + session_array[x]]
                        data[block_array[i]][session_array[x]]["duration"] = responseMessage.devices[0].deviceData["duration_" + session_array[x]]
                        data[block_array[i]][session_array[x]]["sppar"] = responseMessage.devices[0].deviceData["sppar_" + session_array[x]]
                        data[block_array[i]][session_array[x]]["cycle"] = responseMessage.devices[0].deviceData["cycle_" + session_array[x]] || 1
                        data[block_array[i]][session_array[x]]["ec"] = responseMessage.devices[0].deviceData["ec_" + session_array[x]] || 0
                    }
                    data[block_array[i]]["blkdisable"] = responseMessage.devices[0].deviceData["blkdisable"]
                }
            } else if (message_json.messageId == "PAYLOAD2") {
                setProgressPercentage(40)
                setExecuted(true)
                clearAndResetTimeoutWithRefresh()
                setTimeout(() => {
                    client.publish('farm/srrd/' + device_id, srrdPayload3);
                },1000)

                let block_array = [2]
                let session_array = ['a', 'b', 'c', 'd', 'e', 'f']
    
                for (let i in block_array) {
                    for (let x in session_array) {
                        data[block_array[i]][session_array[x]]["io_sc"] = responseMessage.devices[0].deviceData["io_sc_" + session_array[x]]
                        data[block_array[i]][session_array[x]]["medium"] = responseMessage.devices[0].deviceData["medium_" + session_array[x]]
                        data[block_array[i]][session_array[x]]["duration"] = responseMessage.devices[0].deviceData["duration_" + session_array[x]]
                        data[block_array[i]][session_array[x]]["sppar"] = responseMessage.devices[0].deviceData["sppar_" + session_array[x]]
                        data[block_array[i]][session_array[x]]["cycle"] = responseMessage.devices[0].deviceData["cycle_" + session_array[x]] || 1
                        data[block_array[i]][session_array[x]]["ec"] = responseMessage.devices[0].deviceData["ec_" + session_array[x]] || 0
                    }
                    data[block_array[i]]["blkdisable"] = responseMessage.devices[0].deviceData["blkdisable"]
                }
            } else if (message_json.messageId == "PAYLOAD3") {
                setProgressPercentage(60)
                setExecuted(true)
                clearAndResetTimeoutWithRefresh()
                setTimeout(() => {
                    client.publish('farm/srrd/' + device_id, srrdPayload4);
                },1000)

                let block_array = [3]
                let session_array = ['a', 'b', 'c', 'd', 'e', 'f']
    
                for (let i in block_array) {
                    for (let x in session_array) {
                        data[block_array[i]][session_array[x]]["io_sc"] = responseMessage.devices[0].deviceData["io_sc_" + session_array[x]]
                        data[block_array[i]][session_array[x]]["medium"] = responseMessage.devices[0].deviceData["medium_" + session_array[x]]
                        data[block_array[i]][session_array[x]]["duration"] = responseMessage.devices[0].deviceData["duration_" + session_array[x]]
                        data[block_array[i]][session_array[x]]["sppar"] = responseMessage.devices[0].deviceData["sppar_" + session_array[x]]
                        data[block_array[i]][session_array[x]]["cycle"] = responseMessage.devices[0].deviceData["cycle_" + session_array[x]] || 1
                        data[block_array[i]][session_array[x]]["ec"] = responseMessage.devices[0].deviceData["ec_" + session_array[x]] || 0
                    }
                    data[block_array[i]]["blkdisable"] = responseMessage.devices[0].deviceData["blkdisable"]
                }
            } else if (message_json.messageId == "PAYLOAD4") {
                setProgressPercentage(80)
                setExecuted(true)
                clearAndResetTimeoutWithRefresh()
                setTimeout(() => {
                    client.publish('farm/srrd/' + device_id, srrdPayload5);
                },1000)

                let block_array = [4]
                let session_array = ['a', 'b', 'c', 'd', 'e', 'f']
    
                for (let i in block_array) {
                    for (let x in session_array) {
                        data[block_array[i]][session_array[x]]["io_sc"] = responseMessage.devices[0].deviceData["io_sc_" + session_array[x]]
                        data[block_array[i]][session_array[x]]["medium"] = responseMessage.devices[0].deviceData["medium_" + session_array[x]]
                        data[block_array[i]][session_array[x]]["duration"] = responseMessage.devices[0].deviceData["duration_" + session_array[x]]
                        data[block_array[i]][session_array[x]]["sppar"] = responseMessage.devices[0].deviceData["sppar_" + session_array[x]]
                        data[block_array[i]][session_array[x]]["cycle"] = responseMessage.devices[0].deviceData["cycle_" + session_array[x]] || 1
                        data[block_array[i]][session_array[x]]["ec"] = responseMessage.devices[0].deviceData["ec_" + session_array[x]] || 0
                    }
                    data[block_array[i]]["blkdisable"] = responseMessage.devices[0].deviceData["blkdisable"]
                }
            } else if (message_json.messageId == "PAYLOAD5") {
                setProgressPercentage(90)
                setExecuted(true)
                clearAndResetTimeoutWithRefresh()
                setTimeout(() => {
                    client.publish('farm/srrd/' + device_id, srrdPayload_Session);
                },1000)

                let block_array = [5]
                let session_array = ['a', 'b', 'c', 'd', 'e', 'f']
    
                for (let i in block_array) {
                    for (let x in session_array) {
                        data[block_array[i]][session_array[x]]["io_sc"] = responseMessage.devices[0].deviceData["io_sc_" + session_array[x]]
                        data[block_array[i]][session_array[x]]["medium"] = responseMessage.devices[0].deviceData["medium_" + session_array[x]]
                        data[block_array[i]][session_array[x]]["duration"] = responseMessage.devices[0].deviceData["duration_" + session_array[x]]
                        data[block_array[i]][session_array[x]]["sppar"] = responseMessage.devices[0].deviceData["sppar_" + session_array[x]]
                        data[block_array[i]][session_array[x]]["cycle"] = responseMessage.devices[0].deviceData["cycle_" + session_array[x]] || 1
                        data[block_array[i]][session_array[x]]["ec"] = responseMessage.devices[0].deviceData["ec_" + session_array[x]] || 0
                    }
                    data[block_array[i]]["blkdisable"] = responseMessage.devices[0].deviceData["blkdisable"]
                }
            } else if (message_json.messageId == "SESSION1") {
                setProgressPercentage(100)
                setExecuted(true)
                console.log('SESSION1', responseMessage.devices[0].deviceData)
                client.end();

                let session_array = ['a', 'b', 'c', 'd', 'e', 'f', 'end']

                let cloneSession = JSON.parse(JSON.stringify(defaultSessionData))
                let sessionData = cloneSession
                for (let x in session_array) {
                    if (session_array[x] == "end") {
                        sessionData[session_array[x]]["sc_h"] = responseMessage.devices[0].deviceData["sc_h_" + session_array[x]]
                        sessionData[session_array[x]]["sc_m"] = responseMessage.devices[0].deviceData["sc_m_" + session_array[x]]
                    } else {
                        sessionData[session_array[x]]["io_sc"] = parseInt(responseMessage.devices[0].deviceData["io_sc_" + session_array[x]])
                        sessionData[session_array[x]]["sc_h"] = responseMessage.devices[0].deviceData["sc_h_" + session_array[x]]
                        sessionData[session_array[x]]["sc_m"] = responseMessage.devices[0].deviceData["sc_m_" + session_array[x]]
                        sessionData[session_array[x]]["sc_d"] = responseMessage.devices[0].deviceData["sc_d_" + session_array[x]] || 1
                    }
                    
                }
                
                if (message_json.result && message_json.result === "error") {
                    errorToast(`Something went wrong. Please contact administrator.`)
                } else {
                    clearAllTimeout()
                    successToast("Successfully Loaded from controller.")
                    setToggleProgressModal(false)
                }

                setSessionSettings({...sessionData})
            }

            setBlockSettings({...data})

        })
    }



    
    function clearAndResetTimeoutWithRefresh() {
        console.log("🧪 clearAndResetTimeout")
        setExecuted(false)

        // Set a fake timeout to get the highest timeout id
        var highestTimeoutId = setTimeout(";");
        for (var i = 0 ; i < highestTimeoutId ; i++) {
            clearTimeout(i); 
        }

        setTimeout(() => {
            if (executed == false) {
                errorToast(`Timeout. Please refresh & resubmit.`)
                setToggleProgressModal(false)
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            }
        }, 12000)
    }

    function clearAllTimeout() {
        console.log("🧪 clearAllTimeout")
        // clear all timeout
        var id = window.setTimeout(function() {}, 0);
        while (id--) {
            window.clearTimeout(id); // will do nothing if no timeout with id is present
        }
    }


    const uploadController =()=> {
        console.log("🧪 before process", blockSettings)
        setToggleProgressModal(true)
        setProgressLabel("Uploading...")
        setProgressPercentage(0)
        let server = `wss://${MQTT_SERVER}:${MQTT_PORT}`
        // console.log(`*** connecting to ${server}`);
        let client = mqtt.connect(server, {
            username: MQTT_USERNAME,
            password: MQTT_PASSWORD
        })


        let data = []
        
        data.push({
            "deviceId": "manual",
            "deviceData": {
                "mode" :  1,
                "bypass": 1
            }
        })
        for (let i of Object.keys(blockSettings)) {
            let data_temp = {
                "deviceId": "block" + i.toString(),
                "deviceData": {}
            }
            for (let x of Object.keys(blockSettings[i])) {
                if (x == "blkdisable") continue

                data_temp["deviceData"]["io_sc_" + x] = blockSettings[i][x]["io_sc"]
                data_temp["deviceData"]["medium_" + x] = blockSettings[i][x]["medium"]
                data_temp["deviceData"]["duration_" + x] = blockSettings[i][x]["duration"]
                data_temp["deviceData"]["sppar_" + x] = blockSettings[i][x]["sppar"]
                data_temp["deviceData"]["ec_" + x] = blockSettings[i][x]["ec"]

                if (device == "ar00xx_plus" && (x == "a" || x == "b" || x == "c")) {
                    data_temp["deviceData"]["cycle_" + x] = blockSettings[i][x]["cycle"]
                }
            }
            data_temp["deviceData"]["blkdisable"] = blockSettings[i]["blkdisable"]
            data.push(data_temp)
        }

        let data_temp_session = {
            "deviceId": "session",
            "deviceData": {}
        }
        for (let x of Object.keys(sessionSettings)) {
            if (x == "end") {
                data_temp_session["deviceData"]["sc_h_" + x] = sessionSettings[x]["sc_h"]
                data_temp_session["deviceData"]["sc_m_" + x] = sessionSettings[x]["sc_m"]
            } else {
                data_temp_session["deviceData"]["io_sc_" + x] = sessionSettings[x]["io_sc"]
                data_temp_session["deviceData"]["sc_h_" + x] = sessionSettings[x]["sc_h"]
                data_temp_session["deviceData"]["sc_m_" + x] = sessionSettings[x]["sc_m"]
                
                if (device == "ar00xx_plus" && (x == "a" || x == "b" || x == "c")) {
                    data_temp_session["deviceData"]["sc_d_" + x] = sessionSettings[x]["sc_d"]
                }
            }
        }
        data.push(data_temp_session)

        // data.push(sessionSettings)
        // data.push(manualSettings)
        // data.push(monitoringSettings)
        data.push({
            "deviceId": "manual",
            "deviceData": {
                "mode" :  0,
                "bypass": 0
            }
        })

        console.log("🧪 after process", data)

        let aswrPayload_1 = `{"messageId": "uploadController1","devices": ${JSON.stringify(
                [data[0],data[1]]
            )}}`
        let aswrPayload_2 = `{ "messageId": "uploadController2", "devices": ${JSON.stringify([data[2]])} }`
        let aswrPayload_3 = `{ "messageId": "uploadController3", "devices": ${JSON.stringify([data[3]])} }`
        let aswrPayload_4 = `{ "messageId": "uploadController4", "devices": ${JSON.stringify([data[4]])} }`
        let aswrPayload_5 = `{ "messageId": "uploadController5", "devices": ${JSON.stringify([data[5]])} }`
        let aswrPayload_6 = `{ "messageId": "uploadController6", "devices": ${JSON.stringify([data[6], data[7]])} }`


        client.on('connect', function () {
            console.log(`*** connected..`);
            console.log(`*** subscribing to farm/aswa/${device_id}`);
            client.subscribe('farm/aswa/' + device_id, function (err) {
                if (!err) {
                    console.log(`*** subscribed to farm/aswa/${device_id}`);
                    console.log(`*** publishing to to farm/aswr/${device_id} :`, aswrPayload_1);
                    client.publish('farm/aswr/' + device_id, aswrPayload_1);
                    clearAndResetTimeoutWithRefresh()
                }
            });
        })

        client.on('message', function (topic, message) {
            console.log("*** received from topic: ", topic);
            console.log("*** received message", message.toString())

            const message_json = JSON.parse(message.toString());
            console.log(123123213, message_json)
            if (message_json.messageId == "uploadController1") {
                setProgressPercentage(20)
                setExecuted(true)
                clearAndResetTimeoutWithRefresh()
                setTimeout(()=>{
                    console.log(`*** publishing to to farm/aswr/${device_id} :`, aswrPayload_2);
                    client.publish('farm/aswr/' + device_id, aswrPayload_2);
                }, 1000)
            } else if (message_json.messageId == "uploadController2") {
                setProgressPercentage(40)
                setExecuted(true)
                clearAndResetTimeoutWithRefresh()
                setTimeout(()=>{
                    console.log(`*** publishing to to farm/aswr/${device_id} :`, aswrPayload_3);
                    client.publish('farm/aswr/' + device_id, aswrPayload_3);
                }, 1000)
            } else if (message_json.messageId == "uploadController3") {
                setProgressPercentage(60)
                setExecuted(true)
                clearAndResetTimeoutWithRefresh()
                setTimeout(()=>{
                    console.log(`*** publishing to to farm/aswr/${device_id} :`, aswrPayload_4);
                    client.publish('farm/aswr/' + device_id, aswrPayload_4);
                }, 1000)
            } else if (message_json.messageId == "uploadController4") {
                setProgressPercentage(80)
                setExecuted(true)
                clearAndResetTimeoutWithRefresh()
                setTimeout(()=>{
                    console.log(`*** publishing to to farm/aswr/${device_id} :`, aswrPayload_5);
                    client.publish('farm/aswr/' + device_id, aswrPayload_5);
                }, 1000)
            } else if (message_json.messageId == "uploadController5") {
                setProgressPercentage(90)
                setExecuted(true)
                clearAndResetTimeoutWithRefresh()
                setTimeout(()=>{
                    console.log(`*** publishing to to farm/aswr/${device_id} :`, aswrPayload_6);
                    client.publish('farm/aswr/' + device_id, aswrPayload_6);
                }, 1000)
            } else if (message_json.messageId == "uploadController6") {
                setToggleProgressModal(false)
                setProgressPercentage(100)
                client.end()

                setLoading(false)
                if (message_json.result && message_json.result === "error") {
                    errorToast(`Please resubmit.`)
                } else {
                    clearAllTimeout()
                    setExecuted(true)
                    successToast("Value updated.")
                    setTimeout(()=>{
                        window.location.reload()
                    }, 2500)
                }
            }
        })

        // setTimeout(()=> {
        //     client.on('connect', function () {
        //         console.log(`*** subscribing to farm/aswa/${device_id}`);
        //         client.subscribe('farm/aswa/' + device_id, function (err) {
        //             if (!err) {
        //                 console.log(`*** subscribed to farm/aswa/${device_id}`);
        //                 console.log(`*** publishing to to farm/aswr/${device_id} :`, aswrPayload_2);
        //                 client.publish('farm/aswr/' + device_id, aswrPayload_2);
        //             }
        //         });
        //     });
    
        //     client.on('message', function (topic, message) {
        //         console.log("*** received from topic: ", topic);
        //         console.log("*** received message", message.toString());
        //         // message is Buffer
        //         client.end();
    
        //         const message_json = JSON.parse(message.toString());
        //         console.log("payload_2", message_json)
        //     });
        // }, 1000)
        
        
        // setTimeout(()=> {
        //     client.on('connect', function () {
        //         console.log(`*** subscribing to farm/aswa/${device_id}`);
        //         client.subscribe('farm/aswa/' + device_id, function (err) {
        //             if (!err) {
        //                 console.log(`*** subscribed to farm/aswa/${device_id}`);
        //                 console.log(`*** publishing to to farm/aswr/${device_id} :`, aswrPayload_3);
        //                 client.publish('farm/aswr/' + device_id, aswrPayload_3);
        //             }
        //         });
        //     });
    
        //     client.on('message', function (topic, message) {
        //         console.log("*** received from topic: ", topic);
        //         console.log("*** received message", message.toString());
        //         // message is Buffer
        //         client.end();
    
        //         const message_json = JSON.parse(message.toString());
        //         console.log("payload_3", message_json)
        //     });
        // }, 2000)
        
        
        // setTimeout(()=> {
        //     client.on('connect', function () {
        //         console.log(`*** subscribing to farm/aswa/${device_id}`);
        //         client.subscribe('farm/aswa/' + device_id, function (err) {
        //             setLoading(true)
        //             if (!err) {
        //                 console.log(`*** subscribed to farm/aswa/${device_id}`);
        //                 console.log(`*** publishing to to farm/aswr/${device_id} :`, aswrPayload_4);
        //                 client.publish('farm/aswr/' + device_id, aswrPayload_4);
        //             }
        //         })
        //     })
    
        //     client.on('message', function (topic, message) {
        //         setLoading(false)
        //         console.log("*** received from topic: ", topic);
        //         console.log("*** received message", message.toString());
        //         // message is Buffer
        //         client.end();
    
        //         const message_json = JSON.parse(message.toString());
        //         console.log("payload_4", message_json)
    
        //         if (message_json.result && message_json.result === "error") {
        //             errorToast(`Please resubmit.`)
        //         } else {
        //             successToast("Value updated.")
        //             setTimeout(()=>{
        //                 window.location.reload()
        //             }, 1500)
        //         }
        //     })
        // }, 3000)
    }

    function showSessionFormControl() {
        
        if (device == "ar00xx_3tb") {
            for (let blk in blockSettings) {
                if (blockSettings[blk]["a"]["duration"] > 60 || blockSettings[blk]["a"]["duration"] < 0) {
                    return alert("Invalid Input of duration and radiation")
                }
                if (blockSettings[blk]["b"]["duration"] > 60 || blockSettings[blk]["b"]["duration"] < 0) {
                    return alert("Invalid Input of duration and radiation")
                }
                if (blockSettings[blk]["c"]["duration"] > 60 || blockSettings[blk]["c"]["duration"] < 0) {
                    return alert("Invalid Input of duration and radiation")
                }
                if (blockSettings[blk]["d"]["duration"] > 60 || blockSettings[blk]["d"]["duration"] < 0 || blockSettings[blk]["d"]["sppar"] > 1000 || blockSettings[blk]["d"]["sppar"] > 1000) {
                    return alert("Invalid Input of duration and radiation")
                }
                if (blockSettings[blk]["e"]["duration"] > 60 || blockSettings[blk]["e"]["duration"] < 0 || blockSettings[blk]["e"]["sppar"] > 1000 || blockSettings[blk]["e"]["sppar"] > 1000) {
                    return alert("Invalid Input of duration and radiation")
                }
                if (blockSettings[blk]["f"]["duration"] > 60 || blockSettings[blk]["f"]["duration"] < 0 || blockSettings[blk]["f"]["sppar"] > 1000 || blockSettings[blk]["f"]["sppar"] > 1000) {
                    return alert("Invalid Input of duration and radiation")
                }
            }

            let end_time_list = calculateAllEndTimeSwitch()
            // console.log(123123, end_time_list)

            for (let index in end_time_list) {
                let end_time = end_time_list[index].split(":")
                let current_session_value = parseInt(end_time[0].toString() + minutePadding(end_time[1].toString()))
                // validate if block values are larger than session end_value
                let sess = "a"
                if (index==0) {
                    sess = "a"
                } else if (index==1) {
                    sess = "b"
                } else {
                    sess = "c"
                }
                let end_session_value = parseInt(sessionSettings["end"]['sc_h'].toString() + minutePadding(sessionSettings["end"]['sc_m'].toString()))
                if (sessionSettings[sess]["io_sc"] == "1" && current_session_value >= end_session_value) {
                    // errorToast("Value larger than end session value")
                    setSessionSettings( prev => ({
                        ...prev,
                        [sess]: {
                            ...prev[sess],
                            "io_sc": 0
                        }
                    }))
                }

            }
        } else if (device == "ar00xx_1tb") {

            for (let blk in blockSettings) {
                if (blockSettings[blk]["a"]["duration"] > 60 || blockSettings[blk]["a"]["duration"] < 0) {
                    return alert("Invalid Input of duration and radiation")
                }
                if (blockSettings[blk]["b"]["duration"] > 60 || blockSettings[blk]["b"]["duration"] < 0) {
                    return alert("Invalid Input of duration and radiation")
                }
                if (blockSettings[blk]["c"]["duration"] > 60 || blockSettings[blk]["c"]["duration"] < 0) {
                    return alert("Invalid Input of duration and radiation")
                }
                if (blockSettings[blk]["d"]["duration"] > 60 || blockSettings[blk]["d"]["duration"] < 0 || blockSettings[blk]["d"]["sppar"] > 1000 || blockSettings[blk]["d"]["sppar"] > 1000) {
                    return alert("Invalid Input of duration and radiation")
                }
                if (blockSettings[blk]["e"]["duration"] > 60 || blockSettings[blk]["e"]["duration"] < 0 || blockSettings[blk]["e"]["sppar"] > 1000 || blockSettings[blk]["e"]["sppar"] > 1000) {
                    return alert("Invalid Input of duration and radiation")
                }
                if (blockSettings[blk]["f"]["duration"] > 60 || blockSettings[blk]["f"]["duration"] < 0 || blockSettings[blk]["f"]["sppar"] > 1000 || blockSettings[blk]["f"]["sppar"] > 1000) {
                    return alert("Invalid Input of duration and radiation")
                }
            }

            let end_time_list = calculateAllEndTimeSwitch()
            // console.log(123123, end_time_list)

            for (let index in end_time_list) {
                let end_time = end_time_list[index].split(":")
                let current_session_value = parseInt(end_time[0].toString() + minutePadding(end_time[1].toString()))
                // validate if block values are larger than session end_value
                let sess = "a"
                if (index==0) {
                    sess = "a"
                } else if (index==1) {
                    sess = "b"
                } else {
                    sess = "c"
                }
                let end_session_value = parseInt(sessionSettings["end"]['sc_h'].toString() + minutePadding(sessionSettings["end"]['sc_m'].toString()))
                if (sessionSettings[sess]["io_sc"] == "1" && current_session_value >= end_session_value) {
                    // errorToast("Value larger than end session value")
                    setSessionSettings( prev => ({
                        ...prev,
                        [sess]: {
                            ...prev[sess],
                            "io_sc": 0
                        }
                    }))
                }

            }
        } else if (device == "ar00xx_plus") {
            for (let blk in blockSettings) {
                if (blockSettings[blk]["a"]["duration"] > 60 || blockSettings[blk]["a"]["duration"] < 0) {
                    return alert("Invalid Input of duration and radiation")
                }
                if (blockSettings[blk]["b"]["duration"] > 60 || blockSettings[blk]["b"]["duration"] < 0) {
                    return alert("Invalid Input of duration and radiation")
                }
                if (blockSettings[blk]["c"]["duration"] > 60 || blockSettings[blk]["c"]["duration"] < 0) {
                    return alert("Invalid Input of duration and radiation")
                }
                if (blockSettings[blk]["d"]["duration"] > 60 || blockSettings[blk]["d"]["duration"] < 0 || blockSettings[blk]["d"]["sppar"] > 1000 || blockSettings[blk]["d"]["sppar"] > 1000) {
                    return alert("Invalid Input of duration and radiation")
                }
                if (blockSettings[blk]["e"]["duration"] > 60 || blockSettings[blk]["e"]["duration"] < 0 || blockSettings[blk]["d"]["sppar"] > 1000 || blockSettings[blk]["e"]["sppar"] > 1000) {
                    return alert("Invalid Input of duration and radiation")
                }
                if (blockSettings[blk]["f"]["duration"] > 60 || blockSettings[blk]["f"]["duration"] < 0 || blockSettings[blk]["d"]["sppar"] > 1000 || blockSettings[blk]["f"]["sppar"] > 1000) {
                    return alert("Invalid Input of duration and radiation")
                }
            }

            let end_time_list = calculateAllEndTimeSwitch()
            // console.log(123123, end_time_list)

            for (let index in end_time_list) {
                let end_time = end_time_list[index].split(":")
                let current_session_value = parseInt(end_time[0].toString() + minutePadding(end_time[1].toString()))
                // validate if block values are larger than session end_value
                let sess = "a"
                if (index==0) {
                    sess = "a"
                } else if (index==1) {
                    sess = "b"
                } else {
                    sess = "c"
                }
                let end_session_value = parseInt(sessionSettings["end"]['sc_h'].toString() + minutePadding(sessionSettings["end"]['sc_m'].toString()))
                if (sessionSettings[sess]["io_sc"] == "1" && current_session_value >= end_session_value) {
                    // errorToast("Value larger than end session value")
                    setSessionSettings( prev => ({
                        ...prev,
                        [sess]: {
                            ...prev[sess],
                            "io_sc": 0
                        }
                    }))
                }

            }
        }

        
        setShowSessionForm(true)
        setShowBlockForm(false)

    }
    
    function showBlockFormControl() {
        setShowSessionForm(false)
        setShowBlockForm(true)
    }

    
    function calculateAllEndTimeSwitch(){
        // console.log("1calculate",blockSettings)
        let all_end_time_list = []
        if (device == "ar00xx_3tb") {
            let sessions_list = ["a", "b", "c"] // time based session
            for (let index in sessions_list) {
                let duration = 0
                for (let i in blockSettings) {
                    // console.log(sessions_list[index] , blockSettings[i][sessions_list[index]]["io_sc"])
                    if (blockSettings[i]["blkdisable"] == 0 && blockSettings[i][sessions_list[index]]["io_sc"] == 1) {
                        duration += parseInt( blockSettings[i][sessions_list[index]]["duration"] )
                        console.log("HERE:" , blockSettings[i][sessions_list[index]]["duration"], blockSettings[i], sessions_list[index])
                    }
                    let hours = parseInt(sessionSettings[sessions_list[index]]["sc_h"]) * 60
                    duration += hours
                    duration += parseInt(sessionSettings[sessions_list[index]]["sc_m"])
        
                    // //get current duration of triggered switch
                    // duration += parseInt(blockSettings[currentBlockIndex][sessions_list[index]]["duration"])
        
                    all_end_time_list.push(convertMinsToHrsMins(duration))
                    break
                }
            }
            return all_end_time_list
        } else if (device == "ar00xx_1tb") {
            let sessions_list = ["a"] // time based session
            for (let index in sessions_list) {
                let duration = 0
                for (let i in blockSettings) {
                    // console.log(sessions_list[index] , blockSettings[i][sessions_list[index]]["io_sc"])
                    if (blockSettings[i]["blkdisable"] == 0 && blockSettings[i][sessions_list[index]]["io_sc"] == 1) {
                        duration += parseInt( blockSettings[i][sessions_list[index]]["duration"] )
                        console.log("HERE:" , blockSettings[i][sessions_list[index]]["duration"], blockSettings[i], sessions_list[index])
                    }
                    let hours = parseInt(sessionSettings[sessions_list[index]]["sc_h"]) * 60
                    duration += hours
                    duration += parseInt(sessionSettings[sessions_list[index]]["sc_m"])
        
                    // //get current duration of triggered switch
                    // duration += parseInt(blockSettings[currentBlockIndex][sessions_list[index]]["duration"])
        
                    all_end_time_list.push(convertMinsToHrsMins(duration))
                    break
                }
            }
            return all_end_time_list
        }
    }

   

    const [type, setType] = useState("")
    const [transplantInput, setTransplantInput] = useState([
        { ref: "B1", block: "Block 1", plant: "", date: "" },
        { ref: "B2", block: "Block 2", plant: "", date: "" },
        { ref: "B3", block: "Block 3", plant: "", date: "" },
        { ref: "B4", block: "Block 4", plant: "", date: "" },
        { ref: "B5", block: "Block 5", plant: "", date: "" },
    ])

    useEffect(() => {
        getTransplantData()
    }, [])

    
    function getTransplantData() {
        setLoading(true)
        axiosInstance.get("https://api.cloudatik.com/remote/V1.0/transplant/date/"+device_id).then(res => {
            setLoading(false)
            console.log("res", res)
            setTransplantInput(res.data)
        }).catch( err => {
            setLoading(false)
            console.log("err", err)
        })
    }
    
    function handleSubmitTransplant() {
        setLoading(true)
        console.log("handleSubmitTransplant", JSON.stringify(transplantInput))
        const payload = JSON.stringify(transplantInput)
        axiosInstance.post("https://api.cloudatik.com/remote/V1.0/transplant/date/"+device_id+"/", {data: payload}).then(res => {
            setLoading(false)
            console.log("res", res)
            getTransplantData()
            alert("Successfully saved")
        }).catch( err => {
            setLoading(false)
            console.log("err", err)
            alert("Server Error")
        })
    }
    
    function handleTransplantInputChange(e, index, type) {
        console.log("handleTransplantInputChange", e.target.value, index, type)
        let dataToUpdates = [...transplantInput]
        let dataToUpdate = {...dataToUpdates[index]}
        dataToUpdate[type] = e.target.value
        dataToUpdates[index] = dataToUpdate
        // console.log("dataToUpdates", dataToUpdates)
        setTransplantInput(dataToUpdates)
    }

    function clearDate(index) {
        console.log("clearDate", index)
        
        let dataToUpdates = [...transplantInput]
        let dataToUpdate = {...dataToUpdates[index]}
        dataToUpdate["date"] = ""
        dataToUpdate["plant"] = ""
        dataToUpdates[index] = dataToUpdate
        setTransplantInput(dataToUpdates)
    }


    return (
        <>
        {loading && <LoadingSpinner/>}
        
        <div className='row' style={{textAlign:"center", marginBottom: "30px"}}>
            <div className='col-12 col-sm-4'>
                <Button variant="contained" style={{width: "60%", marginBottom: "20px"}} onClick={()=>{setType("controller")}}>Controller</Button>
            </div>
            <div className='col-12 col-sm-4'>
                <Button variant="contained" color="secondary" style={{width: "60%", marginBottom: "20px"}} onClick={()=>{setType("transplant_date")}}>Transplant Date</Button>
            </div>
            <div className='col-12 col-sm-4'>
                <Button variant="contained" color="info" style={{width: "60%"}} onClick={()=>{ return window.location.href = `#/schedueler/${device}/${device_id}` }}>Schedueler</Button>
            </div>
        </div>

        {type == "transplant_date" && (
            <>
            <div style={{
                textAlign: 'center', maxWidth: '750px',
                margin: 'auto',
            }}>
                
                <div style={{display:'flex', marginBottom: '1.5rem', width: "100%", justifyContent: "space-between"}}>
                    <div style={{width: "60%", padding: "0 0.2rem"}}>
                    BLOCK
                    </div>
                    <div style={{width: "100%", padding: "0 0.2rem"}}>
                    PLANT
                    </div>
                    <div style={{width: "70%"}}>
                    TRANSPLANT DATE
                    </div>
                </div>

                <div style={{display:'flex', marginBottom: '1.5rem', width: "100%", justifyContent: "space-between"}}>
                    <div style={{width: "60%", padding: "0 0.2rem"}}>
                        <TextField style={{ width: '100%' }} variant="outlined" value={transplantInput[0].block} disabled/>
                    </div>
                    <div style={{width: "100%", padding: "0 0.2rem"}}>
                        <TextField style={{ width: '100%' }} variant="outlined" value={transplantInput[0].plant} onChange={(e)=>{handleTransplantInputChange(e, 0, "plant")}}/>
                    </div>
                    <div style={{width: "50%"}}>
                        <input type="date" style={{width: "100%"}} value={transplantInput[0].date} onChange={(e)=>{handleTransplantInputChange(e, 0, "date")}}/>
                        <Button variant="contained" color="primary" size="xs" style={{marginTop:"0.4rem"}} onClick={()=>{clearDate(0)}}>clear</Button>
                    </div>
                </div>

                
                <div style={{display:'flex', marginBottom: '1.5rem', width: "100%", justifyContent: "space-between"}}>
                    <div style={{width: "60%", padding: "0 0.2rem"}}>
                    <TextField style={{ width: '100%' }} variant="outlined" value={transplantInput[1].block} disabled/>
                    </div>
                    <div style={{width: "100%", padding: "0 0.2rem"}}>
                    <TextField style={{ width: '100%' }} variant="outlined" value={transplantInput[1].plant} onChange={(e)=>{handleTransplantInputChange(e, 1, "plant")}}/>
                    </div>
                    <div style={{width: "50%"}}>
                        <input type="date" style={{width: "100%"}} value={transplantInput[1].date} onChange={(e)=>{handleTransplantInputChange(e, 1, "date")}}/>
                        <Button variant="contained" color="primary" size="xs" style={{marginTop:"0.4rem"}} onClick={()=>{clearDate(1)}}>clear</Button>
                    </div>
                </div>
                <div style={{display:'flex', marginBottom: '1.5rem', width: "100%", justifyContent: "space-between"}}>
                    <div style={{width: "60%", padding: "0 0.2rem"}}>
                    <TextField style={{ width: '100%' }} variant="outlined" value={transplantInput[2].block} disabled/>
                    </div>
                    <div style={{width: "100%", padding: "0 0.2rem"}}>
                    <TextField style={{ width: '100%' }} variant="outlined" value={transplantInput[2].plant} onChange={(e)=>{handleTransplantInputChange(e, 2, "plant")}}/>
                    </div>
                    <div style={{width: "50%"}}>
                        <input type="date" style={{width: "100%"}} value={transplantInput[2].date} onChange={(e)=>{handleTransplantInputChange(e, 2, "date")}}/>
                        <Button variant="contained" color="primary" size="xs" style={{marginTop:"0.4rem"}} onClick={()=>{clearDate(2)}}>clear</Button>
                    </div>
                </div>
                <div style={{display:'flex', marginBottom: '1.5rem', width: "100%", justifyContent: "space-between"}}>
                    <div style={{width: "60%", padding: "0 0.2rem"}}>
                    <TextField style={{ width: '100%' }} variant="outlined" value={transplantInput[3].block} disabled/>
                    </div>
                    <div style={{width: "100%", padding: "0 0.2rem"}}>
                    <TextField style={{ width: '100%' }} variant="outlined" value={transplantInput[3].plant} onChange={(e)=>{handleTransplantInputChange(e, 3, "plant")}}/>
                    </div>
                    <div style={{width: "50%"}}>
                        <input type="date" style={{width: "100%"}} value={transplantInput[3].date} onChange={(e)=>{handleTransplantInputChange(e, 3, "date")}}/>
                        <Button variant="contained" color="primary" size="xs" style={{marginTop:"0.4rem"}} onClick={()=>{clearDate(3)}}>clear</Button>
                    </div>
                </div>
                <div style={{display:'flex', marginBottom: '1.5rem', width: "100%", justifyContent: "space-between"}}>
                    <div style={{width: "60%", padding: "0 0.2rem"}}>
                    <TextField style={{ width: '100%' }} variant="outlined" value={transplantInput[4].block} disabled/>
                    </div>
                    <div style={{width: "100%", padding: "0 0.2rem"}}>
                    <TextField style={{ width: '100%' }} variant="outlined" value={transplantInput[4].plant} onChange={(e)=>{handleTransplantInputChange(e, 4, "plant")}}/>
                    </div>
                    <div style={{width: "50%"}}>
                        <input type="date" style={{width: "100%"}} value={transplantInput[4].date} onChange={(e)=>{handleTransplantInputChange(e, 4, "date")}}/>
                        <Button variant="contained" color="primary" size="xs" style={{marginTop:"0.4rem"}} onClick={()=>{clearDate(4)}}>clear</Button>
                   </div>
                </div>
                <div>
                <Button variant="contained" color="primary" onClick={()=>{handleSubmitTransplant()}}>SAVE</Button>
                </div>
            </div>
            </>
        )}

        {type == "controller" && (
            <>
            <div style={{display:'flex', marginBottom: '1.5rem', width: "100%"}}>
                <Link to={`/remote-agrirrig-rad/block/${device}/${device_id}`} style={{width: "100%", textAlign: "center"}}>
                    <Button variant="contained" style={{width: "50%"}}>Block {'&'} Session Settings</Button>
                </Link>
                <Link to={`/remote-agrirrig-rad/manual/${device}/${device_id}`} style={{width: "100%", textAlign: "center"}}>
                    <Button variant="contained" color="secondary" style={{width: "50%"}}>Manual Setting</Button>
                </Link>
            </div>
            <Card style={{
                    maxWidth: '80%',
                    margin: 'auto'
                }}>
                <CardContent>
                    <Grid container spacing={2} style={{marginBottom: '1rem'}}>
                        {/* <Grid item xs={6}>
                            <Button color='primary' fullWidth variant="contained" size="large" >Load From Database</Button>
                        </Grid> */}
                        <Grid item xs={12}>
                            <Button color='primary' fullWidth variant="outlined" size="large" onClick={()=>{
                                setDisabledButton(false)
                                loadFromController()
                                showBlockFormControl()
                            }}>Load From Controller</Button>
                        </Grid>
                        
                        <Grid item xs={6}>
                            <Button color='success' fullWidth variant="outlined" onClick={()=>{setToggleAddModal(true)}} disabled={disabledButton}>Save As New Recipe</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button color='success' fullWidth variant="outlined" onClick={()=>{
                                console.log("create new", defaultBlockData)
                                setDisabledButton(false)
                                showSessionFormControl()
                                
                                setSessionSettings(defaultSessionData)
                                setBlockSettings(defaultBlockData)
                            }}>Create New Recipe</Button>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth >
                                <InputLabel id="recipe-label">Recipe</InputLabel>
                                <Select 
                                    labelId="recipe-label"
                                    id="recipe-select-helper"
                                    value={recipe.name ?? " "}
                                    label="Recipe"
                                    onChange={(e)=> {
                                        onChangeRecipe(e)
                                        showBlockFormControl()
                                    }}>
                                    {recipeList.map(r => {
                                        return (
                                            <MenuItem key={r.id} data-device-id={r.device} value={r.name}>{r.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <Button color='success' fullWidth variant="outlined" onClick={()=>{update()}} disabled={recipe.id == 0 ? true : false}>Save</Button>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <Button color='error' fullWidth variant="outlined" onClick={()=>{ 
                                if (window.confirm('Are you sure you want to delete?')) {
                                    handleDelete()
                                }
                            }} disabled={recipe.id == 0 ? true : false}>Delete</Button>
                        </Grid>
                        
                        <Grid item xs={6}>
                            <Button color='info' fullWidth variant="contained" onClick={()=>{
                                showBlockFormControl()
                            }} disabled={disabledButton}>Block Setting</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button color='warning' fullWidth variant="contained" onClick={()=>{
                                showSessionFormControl()
                            }} disabled={disabledButton}>Session Setting</Button>
                        </Grid>
                        
                        { device == "ar00xx_plus" && showBlockForm && (
                            <AR00XXPlusBlockSettingDb blockSettings={blockSettings}
                            currentBlockIndex={currentBlockIndex}
                            currentSessionIndex={currentSessionIndex}
                            setBlockSettings={setBlockSettings}
                            setCurrentBlockIndex={setCurrentBlockIndex}
                            setCurrentSessionIndex={setCurrentSessionIndex}
                            setSessionSettings={setSessionSettings}
                            sessionSettings={sessionSettings}
                            uploadController={uploadController}
                            showSessionForm={showSessionForm}
                            />
                        )}
                        { device == "ar00xx_3tb" && showBlockForm && (
                            <AR00XX3TBBlockSettingDb blockSettings={blockSettings}
                            currentBlockIndex={currentBlockIndex}
                            currentSessionIndex={currentSessionIndex}
                            setBlockSettings={setBlockSettings}
                            setCurrentBlockIndex={setCurrentBlockIndex}
                            setCurrentSessionIndex={setCurrentSessionIndex}
                            setSessionSettings={setSessionSettings}
                            sessionSettings={sessionSettings}
                            uploadController={uploadController}
                            showSessionForm={showSessionForm}
                            />
                        )}
                        
                        { device == "ar00xx_1tb" && showBlockForm && (
                            <AR00XX1TBBlockSettingDb blockSettings={blockSettings}
                            currentBlockIndex={currentBlockIndex}
                            currentSessionIndex={currentSessionIndex}
                            setBlockSettings={setBlockSettings}
                            setCurrentBlockIndex={setCurrentBlockIndex}
                            setCurrentSessionIndex={setCurrentSessionIndex}
                            setSessionSettings={setSessionSettings}
                            sessionSettings={sessionSettings}
                            uploadController={uploadController}
                            showSessionForm={showSessionForm}
                            />
                        )}
                        
                        { device == "ar00xx_rb" && showBlockForm && (
                            <BlockSetting blockSettings={blockSettings}
                            currentBlockIndex={currentBlockIndex}
                            currentSessionIndex={currentSessionIndex}
                            setBlockSettings={setBlockSettings}
                            setCurrentBlockIndex={setCurrentBlockIndex}
                            setCurrentSessionIndex={setCurrentSessionIndex}
                            uploadController={uploadController}
                            />
                        )}

                        
                        { device == "ar00xx_1tb" && showSessionForm && (
                            <AR00XX1TBSessionSettingDb 
                            blockSettings={blockSettings}
                            sessionSettings={sessionSettings}
                            setSessionSettings={setSessionSettings}
                            uploadToController={uploadController}
                            showSessionForm={showSessionForm}
                            />
                        )}
                        { device == "ar00xx_plus" && showSessionForm && (
                            <AR00XXPlusSessionSettingDb
                            blockSettings={blockSettings}
                            sessionSettings={sessionSettings}
                            setSessionSettings={setSessionSettings}
                            uploadToController={uploadController}
                            showSessionForm={showSessionForm}
                            />
                        )}
                        { device == "ar00xx_3tb" && showSessionForm && (
                            <AR00XX3TBSessionSettingDb 
                            blockSettings={blockSettings}
                            sessionSettings={sessionSettings}
                            setSessionSettings={setSessionSettings}
                            uploadToController={uploadController}
                            showSessionForm={showSessionForm}
                            />
                        )}
                        { device == "ar00xx_rb" && showSessionForm && (
                            <SessionSetting 
                            sessionSettings={sessionSettings}
                            setSessionSettings={setSessionSettings}
                            uploadToController={uploadController}
                            />
                        )}
                        
                    </Grid>
                </CardContent>
            </Card>

            <Dialog open={toggleAddModal} maxWidth="md" fullWidth={true}>
                <DialogTitle>Create New Recipe</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Enter new Recipe Name
                </DialogContentText>
                <TextField type='text' autoFocus id="outlined-basic" fullWidth label="Recipe Name" variant="standard" value={newRecipeName} onChange={(e)=>{ setNewRecipeName(e.target.value) }}/>
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>setToggleAddModal(false)}>Cancel</Button>
                <Button onClick={()=>create()}>Create</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={toggleProgressModal} maxWidth="md" fullWidth={true}>
                <DialogContent>
                <div className="progress">
                    <div className="progress-bar progress-bar-success" role="progressbar" aria-valuenow={progressPercentage} aria-valuemin={progressPercentage} aria-valuemax="100" style={{width: `${progressPercentage}%`, backgroundColor:'#4ab56d'}}>
                        {progressLabel}
                    </div>
                </div>
                </DialogContent>
            </Dialog>
            </>

        )}
        </>
    )
}

const minutePadding =(min)=>{
    if (min && min.toString().length < 2) {
        return '0' + min.toString()
    }
    return min.toString()
}
const convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? '0' + h : h; // (or alternatively) h = String(h).padStart(2, '0')
    m = m < 10 ? '0' + m : m; // (or alternatively) m = String(m).padStart(2, '0')
    return `${h}:${m}`;
}


export default BlockSettingDb