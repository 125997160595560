import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { useHistory } from "react-router-dom";

import LoadingSpinner from "../../shared/Spinner/LoadingSpinner";
import "./styles.css";
import auth from "../../services/auth";
import { errorToast } from "../../shared/notification";

function Login() {
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	useEffect(() => {
		const loggedInUser = localStorage.getItem("cloudatik");
		if (loggedInUser !== null) {
			auth.getInstance().refreshToken().then((response) => {
				localStorage.setItem("cloudatik-access", `JWT ${response['access']}`);
				history.push(`/v2/dashboard`);
				window.location.reload();
			});
		}
	}, []);

	const onLogin = async (event) => {
		event.preventDefault();

		setLoading(true);
		let data = new FormData();
		let uname = document.getElementById("txt_username").value;
		let pword = document.getElementById("txt_password").value;

		data.set("username", uname);
		data.set("password", pword);

		const res = await fetch(
			`${process.env.REACT_APP_API_URL}/token/?format=json`,
			{
				method: "POST",
				body: data,
			}
		);

		const d = await res.json();

		if (d["access"] == null) {
			errorToast("Invalid Credentials");
			setLoading(false);
		} else {
			if (uname.startsWith("demo_")) {
				localStorage.setItem("cloudatik_demo", true);
			}
			localStorage.setItem("cloudatik", uname);
			localStorage.setItem("cloudatik-access", `JWT ${d['access']}`);
			localStorage.setItem("cloudatik-refresh", `${d['refresh']}`);
			localStorage.setItem("password", pword);
			window.location.href = "#/v2/dashboard";
			window.location.reload();
			setLoading(false);
		}
	};

	return (
		<>
			{loading && <LoadingSpinner />}
			<div className="login-container">
				<div className="card">
					<div className="card-body">
						<center>
							<h3>Login</h3>
						</center>
						<form onSubmit={onLogin}>
							<div className="form-group">
								<label>Username</label>
								<input
									type="text"
									className="form-control"
									name="txt_username"
									id="txt_username"
									placeholder="Username"
									required
								/>
							</div>
							<div className="form-group">
								<label>Your password</label>
								<input
									type="password"
									className="form-control"
									name="txt_password"
									id="txt_password"
									placeholder="Password"
									required
								/>
							</div>

							<div className="form-group">
								<button type="submit" className="btn btn-primary btn-block">
									{" "}
									Login{" "}
								</button>
								{/* <button type="submit" className="btn btn-block" onClick={()=>{history.push('/register')}}>
									Sign Up
								</button> */}
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}

export default Login;
