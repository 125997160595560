import React, { useEffect, useState, } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import { Button } from "reactstrap";
import { Html5QrcodeScanner, Html5QrcodeSupportedFormats } from "html5-qrcode";
import './styles/scan-qr.css';
import LoadingSpinner from '../../shared/Spinner/LoadingSpinner';
import { errorToast, successToast } from '../../shared/notification';

const ScanQr = ({ isMobile, setIsMobile, addNodeModal, setAddNodeModal }) => {
	const [loading, setLoading] = useState(false);
    let html5QrcodeScanner;
	
    useEffect(() => {
        html5QrcodeScanner = new Html5QrcodeScanner("html5qr-code-full-region", {
            fps: 10,
            qrbox: 150,
            disableFlip: false,
			rememberLastUsedCamera:false,
            formatsToSupport: [ Html5QrcodeSupportedFormats.QR_CODE ]
        });
        html5QrcodeScanner.render(
            onNewScanResult, 
        );
    }, [])
    

	
    function onNewScanResult(decodedText, decodedResult) {
        console.log("App [result]", decodedText);
		html5QrcodeScanner.clear()
		setAddNodeModal(false);
		let device_id = decodedText.split("/")[4];
		setLoading(true)
		// REGISTER NODE
		axios.post(`${process.env.REACT_APP_API_URL}/register/node/webapp/v2/`, {
			'device_id': device_id
		}, {
			headers: {
				'Authorization': localStorage.getItem('cloudatik-access')
			}
		})
		.then(resp => {
			setLoading(false)
			successToast('Succesfully Registered Node');
			setTimeout(() => {
				return window.location.reload()
			}, 2000)
		})
		.catch(err => {
			setLoading(false)
			setAddNodeModal(true);
			errorToast(err.response.data.message);
			setTimeout(() => {
				return window.location.reload()
			}, 2000)
		});

    }



	return (
		<>
		{loading && <LoadingSpinner />}
		<div className="modal fade show" style={addNodeModal ? {display:'block'} : {display: 'none'}} >
			<div className="modal-dialog modal-sm">
				<div>Add Node</div>
				<div className="modal-content">
					<div className="modal-header">
						<p className='modal-title'>Add Node</p>
						<button type="button" className="close" onClick={() => { setAddNodeModal(false); }}>
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div className="row justify-content-center modal-body">
						<div id="html5qr-code-full-region" style={{width:'100%', height: '100%'}}/>
					</div>
					<div className="modal-footer justify-content-between">
						<Button color="secondary" onClick={() => { setAddNodeModal(false); }}>Close</Button>
					</div>
				</div>
			</div>
		</div>
		</>
	);
};

export default ScanQr;
